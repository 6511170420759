<template>
  <div>
    <b-button size="sm" :variant="buttonState.position ? 'success' : 'info'" block class="mb-2"
              @click="sendRequest('position')" :disabled="buttonState.position">
      {{ buttonState.position ? 'Requesting...' : 'Get position' }}
    </b-button>
    <b-button size="sm" :variant="buttonState.status ? 'success' : 'info'" block class="mb-2"
              @click="sendRequest('status')" :disabled="buttonState.status">
      {{ buttonState.status ? 'Requesting...' : 'Get status' }}
    </b-button>
    <b-button size="sm" :variant="buttonState.route ? 'success' : 'info'" block class="mb-2"
              @click="sendRequest('route')" :disabled="buttonState.route">
      {{ buttonState.route ? 'Requesting...' : 'Get route' }}
    </b-button>
    <b-button size="sm" :variant="buttonState.folio ? 'success' : 'info'" block class="mb-2"
              @click="sendRequest('folio')" :disabled="buttonState.folio">
      {{ buttonState.folio ? 'Requesting...' : 'Get folio' }}
    </b-button>
  </div>
</template>

<script>
import {mapStores} from "pinia";
import {useRoutesStore} from "@/stores/routes";
import {useLogbookStore} from "@/stores/logbook";
import {useDeviceStore} from "@/stores/device";
import {useUserStore} from "@/stores/user";
import {loadLogbook, sendECDISRequest} from "@/helpers/api";

export default {
  data() {
    return {
      buttonState: {
        position: false,
        status: false,
        route: false,
        folio: false
      }
    }
  },
  mounted() {
  },
  beforeDestroy() {
  },
  computed: {
    ...mapStores(useDeviceStore),
  },
  methods: {
    sendRequest(type) {
      console.log(type)

      sendECDISRequest(type, this.deviceStore.getDeviceID)

      this.buttonState[type] = true;

      // Set a timeout to revert the button state after 5 seconds
      setTimeout(() => {
        this.buttonState[type] = false;
      }, 5000);
    }
  }
}
</script>

<style scoped>
.unstyled-button {
  border: none;
  padding: 0;
  background: none;
}

.active-btn {
  background-color: #e9ecef;
  border-color: #ced4da;
  font-weight: 500;
}

.btn {
  border-radius: 0;
}

.btn-group .btn:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.btn-group .btn:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.custom-button-group .btn {
  background-color: #007bff;
  color: white;
  border: none;
  transition: background-color 0.3s ease;
}

.custom-button-group .btn:hover {
  background-color: #0056b3;
}
</style>