import { defineStore } from 'pinia'
import {useDeviceStore} from "@/stores/device";
import {saveLogbook} from "@/helpers/api";
import {logbooks_map} from "@/main";

export const useLogbookStore = defineStore('logbook', {
    state: () => ({
        logbook: [],
        ecdisMessages: [],
    }),
    getters: {
        getLogsCount: (state) => state.logbook.length,
        getLatestLog: (state) => state.logbook[state.logbook.length - 1],
        getLatestShipLog: (state) => {
            const deviceStore = useDeviceStore()
            const deviceID = deviceStore.getDeviceID

            // Filter records from both arrays that belong to the current device
            const logbookEntries = state.logbook?.filter(entry => entry?.deviceId === deviceID) || []
            const ecdisEntries = state.ecdisMessages?.filter(entry => entry?.deviceId === deviceID) || []

            // Combine both arrays
            const allEntries = [...logbookEntries, ...ecdisEntries]

            // Return null if no entries found
            if (allEntries.length === 0) return null

            // Sort by time in descending order and return the first (most recent) entry
            return allEntries.sort((a, b) => new Date(b.time) - new Date(a.time))[0]
        }
    },
    actions: {
        initReports(data) {
            if (data) {
                this.logbook = data
                logbooks_map.drawAllReportPoints(data[data.length - 1])
            }
        },
        initEcdisMessages(data) {
            if (data) {
                this.ecdisMessages = data
                logbooks_map.drawAllReportPoints(data[data.length - 1])
            }
        },
        addReport(payload) {
            let deviceStore = useDeviceStore()
            payload.deviceId = deviceStore.getDeviceID
            // console.log("aaaa", id, payload)

            this.logbook.push(payload)

            // let record = this.getLatestShipLog

            let records = this.logbook.filter((e) => e.deviceId === deviceStore.getDeviceID)

            // let record = {msg:"aaaa"}
            saveLogbook(records, deviceStore.getDeviceID).then(r => {
                console.log("save logbook", r)
            })


        },
        reset() {
        }
    },
    persist: false
})
