import L from "leaflet";
import {NavLeaf} from "../base/nav-leaf";
import {eventBus} from "@/main";
import store from '../../store'
import '../styles/route-styles.css'
import "../extensions/leaflet-search/leaflet-search.min.js"
import "../extensions/leaflet-search/leaflet-search.min.css"

import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/regular'
import iconImg from "@/assets/ship_1tx_green.png";
import dayjs from "dayjs";

export class ShipLeaf extends NavLeaf {

    logbookRouteGroup = null
    logbookTrackGroup = null
    logbookMarkersGroup = null

    constructor() {
        super();
    }

    async init(el) {
        super.init(el);

        this.logbookRouteGroup = L.layerGroup();
        this.map.addLayer(this.logbookRouteGroup);

        this.logbookTrackGroup = L.layerGroup();
        this.map.addLayer(this.logbookTrackGroup);

        this.logbookMarkersGroup = L.layerGroup();
        this.map.addLayer(this.logbookMarkersGroup);

        // this.logbookRouteGroup.clearLayers()

        eventBus.$off('draw-ecdis-route')
        eventBus.$on('draw-ecdis-route', (coordinates) => {
            this.drawECDISRoute(coordinates);
        });

        this.map.on('click', (e) => {
            let coordinates = e.latlng
            this.map.flyTo(coordinates)

            eventBus.$emit('set-logbook-coordinates', coordinates)
        })

    }

    panToReport(payload) {
        this.map.setView([payload.lat, payload.lon], 12);
    }

    drawReportPoint(report, index, isHistory = false) {
        let iconImg = isHistory ? require("../../assets/ship_1tx_yellow.png") : require("../../assets/ship_1tx_green.png")
        let icon = L.icon({
            iconUrl: iconImg,
            iconSize: [20, 40],
            iconAnchor: [10, 20],
        });
        let positionMarker = new L.marker([report.pos.lat, report.pos.lon], {id: index, icon: icon, rotationAngle: Number(report.hdg / 2)});

        let text = ''

        // if (isHistory) {
        //     text += dayjs(report.time).format("YYYY-MM-DD HH:mm:ss")
        // } else {
        //     if (report.time)
                text += dayjs(report.time).format("YYYY-MM-DD HH:mm:ss") + '<br>'
            // if (report.hdg !== undefined)
                text += 'HDG: ' + report.hdg + ', '
                text += 'COG: ' + report.cog + ', '
                text += 'SOG: ' + report.sog + ', '
                text += 'ROT: ' + report.rot
            // if (report.sog !== undefined)

        // }

        positionMarker.bindTooltip(text, {permanent: false, direction: 'top', offset: L.point(0, -17)})
        // positionMarker.bringToFront()
        positionMarker.addTo(this.logbookMarkersGroup);

        positionMarker.on("click", (e) => {
            eventBus.$emit('select-logtable-row', positionMarker.options.id)
            this.map.setView(positionMarker.getLatLng(), 12);

            // let iconImg = require("../../assets/ship_1tx_red.png")
            // let icon = L.icon({
            //     iconUrl: iconImg,
            //     iconSize: [20, 40],
            //     iconAnchor: [10, 20],
            // });
            // positionMarker.setIcon(icon)
        })


    }

    drawAllReportPoints(report) {
        this.logbookMarkersGroup.clearLayers()
        this.logbookTrackGroup.clearLayers()

        let pts = []
        let index = 0

        let items1 = this.logbookStore.logbook.filter((e) => e.deviceId === this.deviceStore.getDeviceID)
        let items2 = this.logbookStore.ecdisMessages.filter((e) => e.deviceId === this.deviceStore.getDeviceID)
        // let items = [...items1, ...items2]
        let items = [...items1, ...items2].sort((a, b) => new Date(b.time) - new Date(a.time))

        items.forEach((item, i) => {
            if (item.pos.lat !== '') {
                pts.push([Number(item.pos.lat), Number(item.pos.lon)])
                this.drawReportPoint(item, index++, i !== 0);
            }
        })
        // pts.push([Number(report.pos.lat), Number(report.pos.lon)]);

        let trackLine = L.polyline(pts, {
            color: 'grey',
            weight: 2,
            dashArray: '5,5',
            dashOffset: '0'
        }).addTo(this.logbookRouteGroup);

        trackLine.bringToBack();

        // this.drawReportPoint(report, index)
    }

    drawECDISRoute(points) {
        this.logbookRouteGroup.clearLayers()
        let route = []
        points.forEach((wp, i) => {
            route.push([wp.lat, wp.lon])

            let newMarker = new L.circleMarker([wp.lat, wp.lon], {
                bubblingMouseEvents: false,
                autoPan: true,
                radius: 4,
                weight: 15,
                opacity: 0,
                fillOpacity: 0.8,
                color: 'blue',
            }).addTo(this.logbookRouteGroup);

            if (i === 0) {
                newMarker.bindTooltip("Start").openTooltip();
            } else if (i === points.length - 1) {
                newMarker.bindTooltip("Finish").openTooltip();
            }
        })

        let routeLine = L.polyline(route, {
            color: 'blue',
            weight: 2,
            // dashArray: '5, 5',
            // dashOffset: '0'
        }).addTo(this.logbookRouteGroup);
        // routeLine.bringToBack()

        // let bounds = routeLine.getBounds()
        // this.map.fitBounds(bounds);

    }

    drawRoute() {
        this.logbookRouteGroup.clearLayers()
        let route = []
        this.routesStore.getShipWaypoints.forEach((wp, i) => {
            route.push([wp.lat, wp.lon])

            let newMarker = new L.circleMarker([wp.lat, wp.lon], {
                bubblingMouseEvents: false,
                autoPan: true,
                radius: 4,
                weight: 15,
                opacity: 0,
                fillOpacity: 0.8,
                color: 'blue',
            }).addTo(this.logbookRouteGroup);

            if (i === 0) {
                newMarker.bindTooltip("Start").openTooltip();
            } else if (i === this.routesStore.getShipWaypoints.length - 1) {
                newMarker.bindTooltip("Finish").openTooltip();
            }
        })

        let routeLine = L.polyline(route, {
            color: 'blue',
            weight: 2,
            // dashArray: '5, 5',
            // dashOffset: '0'
        }).addTo(this.logbookRouteGroup);
        // routeLine.bringToBack()

        let bounds = routeLine.getBounds()
        this.map.fitBounds(bounds);

    }

    restoreReports() {
        this.logbookMarkersGroup.clearLayers()
        this.logbookRouteGroup.clearLayers()
        this.logbookTrackGroup.clearLayers()

        if (this.routesStore.getShipWaypointsCount > 0)
            this.drawRoute()

        let pts = []
        let index = 0

        let items = this.logbookStore.logbook.filter((e) => e.deviceId === this.deviceStore.getDeviceID)

        items.forEach(item => {
            pts.push([Number(item.pos.lat), Number(item.pos.lon)])
            if (index === items.length - 1)
                this.drawReportPoint(item, index++);
            else
                this.drawReportPoint(item, index++, true);
        })

        let trackLine = L.polyline(pts, {
            color: 'grey',
            weight: 2,
            dashArray: '5,5',
            dashOffset: '0'
        }).addTo(this.logbookRouteGroup);

        if (items.length > 0)
            this.map.setView(items[index - 1].pos, 7);
    }


}
