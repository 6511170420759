<template>
  <div>
    <b-overlay
        :show="!userStore.isShipsLoaded || ($store.state.auth.user.role === 'distributor' && !deviceStore.isAgreementLoaded)"
        opacity=0.9 bg-color="white" class="mt-4">
      <template #overlay>
        <div class="text-center">
          <b-icon icon="hourglass" font-scale="2" animation="spin"></b-icon>
          <p v-if="!userStore.isShipsLoaded">Loading ships...</p>
          <p v-if="$store.state.auth.user.role === 'distributor' && userStore.isShipsLoaded && !deviceStore.isAgreementLoaded">
            Loading agreements...</p>
        </div>
      </template>

      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <b-card header="CONNECT PANEL">
              <b-row>
                <b-col order=4 order-lg=1 lg="4">

                  <b-form-select v-model="selected_ship" :options="ships" :title="getSelectedShipTooltip"
                                 v-b-tooltip.hover size="sm" class="flex-grow-1 mr-2 mb-2"></b-form-select>

                  <connect-controls></connect-controls>

                </b-col>
                <b-col order=4 order-lg=1 lg="8">
<!--                  <logbook-map :map=map :height=map_size></logbook-map>-->
                </b-col>
              </b-row>
            </b-card>

          </b-col>
        </b-row>
      </b-container>
    </b-overlay>
  </div>
</template>

<script>
import ConnectControls from "@/views/connect/ConnectControls.vue";
import {mapStores} from "pinia";
import {useRoutesStore} from "@/stores/routes";
import {useLogbookStore} from "@/stores/logbook";
import {loadAgreements, loadInstallations, loadShips} from "@/helpers/api";
import {useDeviceStore} from "@/stores/device";
import {useUserStore} from "@/stores/user";
import {useMomentaryStore} from "@/stores/momentary";
import {myAxios} from "@/services/myAxios";

export default {
  components: {ConnectControls},
  data() {
    return {
      displayContent: 'logbook',
      tabIndex: 0,

      activeMobileView: 'map',

      selected_ship: "",
      ships: [],

      windowWidth: window.innerWidth,
      // map: new ShipLeaf(),
      mapHeight: window.innerHeight - 200,
      mapHeightMobile: window.innerHeight - 130,
    }
  },
  watch: {
    'selected_ship'(newVal, oldVal) {
      this.deviceStore.selectDevice(newVal);
      // this.$refs['logbook-report'].changeShip()
      // this.$refs['logbook-table'].loadLogbook()
    }
  },
  mounted() {
    myAxios.get('/api/v1/manage/test')

    window.onresize = () => {
      this.windowWidth = window.innerWidth
      this.mapHeightMobile = window.innerHeight - 130
    }

    // if (this.routesStore.getShipWaypointsCount > 0)
    //   this.map.drawRoute()
    //
    // if (this.logbookStore.logbook.length > 0)
    //   this.map.restoreReports()

    loadShips().then((response) => {
      if (this.deviceStore.isDeviceIDExist)
        this.selected_ship = this.deviceStore.getDeviceID
      else
        this.selected_ship = this.userStore.getECDISShips[0]?.value ?? ''
      this.ships = this.userStore.getECDISShips

      if (this.$store.state.auth.user.role === 'distributor') {
        loadAgreements().then((response) => {
          if (this.deviceStore.isDeviceIDExist)
            this.selected_ship = this.deviceStore.getDeviceID
          else
            this.selected_ship = this.userStore.getECDISShips[0]?.value ?? ''
          this.ships = this.userStore.getECDISShips
        })
      }
    })

    // let qrcode2 = require("@/data/mss-ship5.json")
    // this.$store.commit("fickle/setQR1", qrcode2)
  },
  computed: {
    ...mapStores(useMomentaryStore, useRoutesStore, useLogbookStore, useUserStore, useDeviceStore),
    is_mobile() {
      return (this.windowWidth <= 991) ||
          ('ontouchstart' in window) ||
          (navigator.maxTouchPoints > 0) ||
          (navigator.msMaxTouchPoints > 0)
    },
    map_size() {
      if (this.is_mobile)
        return this.mapHeightMobile
      else
        return window.innerHeight - 200
    },
  },
  methods: {
    getSelectedShipTooltip() {
      let tooltip = '';
      if (this.$store.state.auth.user.role === 'distributor') {
        let deviceId = this.selected_ship;
        let ship = this.ships.find(e => e.value === deviceId);
        tooltip = ship?.email + ", " + deviceId;
      }
      return tooltip;
    },
  }
}
</script>

<style scoped>
/* Adjust container padding for mobile */
@media (max-width: 991px) {
  .container-fluid {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .row {
    margin-left: -5px !important;
    margin-right: -5px !important;
  }

  .col, [class*="col-"] {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
</style>