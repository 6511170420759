<template>
  <div>
    <b-modal title="Images" v-model="images_modal" ok-only>
      <div class="col-9 col-sm-10 col-md-10 col-lg-10" style="overflow-y: scroll">
        <image-viewer :images=image_src></image-viewer>
      </div>
    </b-modal>

    <b-modal title="Audio" v-model="audio_modal" ok-only>
      <audio controls :src='audio_src'></audio>
    </b-modal>

    <b-modal title="Message" v-model="messages_modal" ok-only>
      {{messages}}
    </b-modal>

    <div>

<!--      <b-row>-->
<!--        <b-col>-->
<!--          <b-form-select v-model="$parent.$parent.selected_ship" :options="$parent.$parent.ships" :title="getSelectedShipTooltip" v-b-tooltip.hover size="sm"></b-form-select>-->
<!--        </b-col>-->
<!--      </b-row>-->
<!--      <b-row>-->
<!--        <b-col>-->
<!--          <b-form-select v-model="selected_route" :options="user_routes" size="sm"></b-form-select>-->
<!--        </b-col>-->
<!--      </b-row>-->

      <!-- Mobile back button to return to map -->
      <b-row v-if="isMobileView" class="mt-2">
        <b-col>
<!--          <b-button size="sm" variant="outline-secondary" @click="goToMap">-->
<!--            <b-icon icon="map"></b-icon> Back to Map-->
<!--          </b-button>-->

<!--          <b-button-toolbar class="justify-content-center">-->
<!--            <b-button-group class="shadow-sm custom-button-group">-->
<!--              <b-button-->
<!--                  variant="light"-->
<!--                  @click="goToMap"-->
<!--                  size="sm">-->
<!--                <b-icon icon="map" class="mr-1"></b-icon> Back to Map-->
<!--              </b-button>-->
<!--            </b-button-group>-->
<!--          </b-button-toolbar>-->

        </b-col>
      </b-row>

      <b-table sticky-header style="max-height: calc(100vh - 250px); overflow-y: auto;" selectable thead-class="d-none" sort-by="time" sort-desc select-mode="single" ref="logtable" @row-clicked="onRowClicked" :items="visibleRows()" :fields="visible_fields">
        <template #cell(images)="row">
          <b-button v-if="row.item.images.length > 0" class="unstyled-button" size="sm" title="Images" @click="showImages(row.item.images)">
            <b-icon variant="dark" icon="card-image" aria-hidden="true"></b-icon>
          </b-button>
        </template>
        <template #cell(source)="row">
          <b-badge variant="primary" v-if="row.item.source === 'web'">
            link
          </b-badge>
          <b-badge variant="success" v-else>
            ecdis
          </b-badge>
        </template>
        <template #cell(position)="row">
          <div v-if="row.item.pos.lat !== ''">
            <b-icon icon="geo-alt" variant="dark"></b-icon>
          </div>
        </template>
        <template #cell(audio)="row">
          <b-button v-if="row.item.audio.src" class="unstyled-button" size="sm" title="Images" @click="playAudio(row.item.audio)">
            <b-icon variant="dark" icon="mic" aria-hidden="true"></b-icon>
          </b-button>
        </template>
        <template #cell(message)="row">
          <b-button v-if="row.item.message" class="unstyled-button" size="sm" title="Images" @click="showMessages(row.item.message)">
            <b-icon variant="dark" icon="file-earmark-text" aria-hidden="true"></b-icon>
          </b-button>
        </template>
        <template #cell(time)="data">
          {{getDate(data.value)}}
        </template>
      </b-table>

<!--      <b-button size="sm" class="mr-2" variant="warning" @click="QRscan()">Upload more...</b-button>-->
<!--      <b-button size="sm" variant="warning" @click="Report()">Add report</b-button>-->


    </div>
  </div>
</template>

<script>
import {eventBus, logbooks_map, routes_map} from "@/main";
import router from "@/router";
import ImageViewer from "@/components/ImageViewer";
import {mapStores} from "pinia";
import {useRoutesStore} from "@/stores/routes";
import LogbookMap from "@/views/logbook/LogbookMap.vue";
import {useLogbookStore} from "@/stores/logbook";
import {useDeviceStore} from "@/stores/device";
import {useUserStore} from "@/stores/user";
import {loadLogbook} from "@/helpers/api";

export default {
  props: ["map"],
  components: {ImageViewer},
  data() {
    return {
      selected_ship: '',
      selected_route: '',
      images_modal: false,
      audio_modal: false,
      messages_modal: false,
      messages: "",
      image_src: "",
      audio_src: "",
      isMobileView: window.innerWidth <= 760,
    }
  },
  // components: {QRWindow},
  mounted() {
    // if (this.routesStore.routeResponse.routePoints.length > 0)
    //   this.map.drawRoute()

    window.addEventListener('resize', this.checkMobileView);

    eventBus.$off('select-logtable-row')
    eventBus.$on('select-logtable-row', (id) => {
      this.$refs.logtable.selectRow(id);
      const tbody = this.$refs.logtable.$el.querySelector('tbody')
      const row = tbody.querySelectorAll('tr')[id]
      row.scrollIntoView({block: 'center'})
    })

    this.loadLogbook()
    this.logbookRefreshInterval = setInterval(() => {
      this.loadLogbook()
    }, 15000) // Refresh every 15 seconds

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobileView);
    clearInterval(this.logbookRefreshInterval);
  },
  computed: {
    ...mapStores(useLogbookStore, useDeviceStore, useUserStore),
    user_ships() {
      this.selected_ship = this.deviceStore.getDeviceID
      return [
        { value: this.deviceStore.getDeviceID, text: this.deviceStore.getShipName}
      ]
    },
    user_routes() {
      this.selected_route = 'route_new'
      return [
        { value: 'route_new', text: 'New Route' }
      ]
    },
    images() {
      return this.$store.state.fickle.qr.images.map((image) => ({ //TODO: get images
        src: image.src,
        thumbnail: image.thumbnail ? image.thumbnail : image.src, // Используйте src в качестве миниатюры, если thumbnail не указан
        originalSize: [image.width, image.height].join('x'), // Ширина и высота оригинального изображения
        alt: image.alt || '', // Альтернативный текст (пустая строка, если не указан)
        height: image.height,
        width:  image.width,
        name: image.name
      }));
    },
    visible_fields() {
      return [
        {key: 'time', label: 'Time', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'source', label: 'From', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'position', label: '', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'message', label: '', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'images', label: '', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'audio', label: '', tdClass: 'small-black', thClass: 'small-gray'},
      ]
    },
  },
  methods: {
    // Add new methods
    loadLogbook() {
      if (this.deviceStore.getDeviceID) {
        loadLogbook(this.deviceStore.getDeviceID).then(records => {
          this.logbookStore.initReports(records)
        })
      } else {
        // Wait for device ID to be available and retry
        const checkInterval = setInterval(() => {
          if (this.deviceStore.getDeviceID) {
            loadLogbook(this.deviceStore.getDeviceID).then(records => {
              this.logbookStore.initReports(records)
            })
            clearInterval(checkInterval)
          }
        }, 500) // Check every 500ms

        // Clear interval after 10 seconds to prevent infinite checking
        setTimeout(() => clearInterval(checkInterval), 10000)
      }
    },
    checkMobileView() {
      this.isMobileView = window.innerWidth <= 760;
    },
    goToMap() {
      this.$parent.$parent.activeMobileView = 'map';
    },
    getSelectedShipTooltip() {
      let tooltip = ''
      if (this.$store.state.auth.user.role === 'distributor') {
        let deviceId = this.$parent.$parent.selected_ship;
        let ship = this.$parent.$parent.ships.find(e => e.value === deviceId);
        tooltip = ship?.email + ", " + deviceId;
      }
      return tooltip
    },
    visibleRows() {
      let logbook = this.logbookStore.logbook.filter((e) => e.deviceId === this.deviceStore.getDeviceID)
      let ecdisMessages = this.logbookStore.ecdisMessages.filter((e) => e.deviceId === this.deviceStore.getDeviceID && e.message !== '')
      return [...logbook, ...ecdisMessages]
    },
    onRowSelected(items) {
      // logbooks_map.drawRoute()
      // return
      // if (items.length > 0) {
      //   // alert(items[0])
      //   // console.log(items[0])
      //   eventBus.$emit('show-log-on-map', items[0].time)
      // }
    },
    onRowClicked(row, index) {
      if (row.pos.lat !== '')
        logbooks_map.panToReport(row.pos)

      if (this.isMobileView) {
        this.$parent.$parent.activeMobileView = 'map';
      }
    },
    showImages(images) {

      // console.log(row)

      // this.image_src = this.$store.state.fickle.qr.shipLogs[0].logs[0].images[0].src
      // this.image_src = row.images[0].src
      this.image_src = images
      this.images_modal = true
    },
    playAudio(audio) {

      // console.log(row)

      this.audio_src = audio.src
      this.audio_modal = true

    },
    showMessages(message) {
      this.messages = message
      this.messages_modal = true
    },
    getDate(date) {

      let t = new Date(date)

      let hr = ("0" + t.getHours()).slice(-2);
      let min = ("0" + t.getMinutes()).slice(-2);
      let sec = ("0" + t.getSeconds()).slice(-2);

      return t.getFullYear()+"-"+parseInt(t.getMonth()+1)+"-"+t.getDate()+" "+hr+":"+min+":"+sec
    },
    QRscan() {
        router.push({name:'ships'})
        setTimeout(() => {
          eventBus.$emit('start-scan-qr-ecdis')
        }, 500)
    },
    Report() {
      // alert(1)
      this.$root.$emit('bv::toggle::collapse', 'logbook-sidebar');
      return
      
        // router.push({name:'ships'})
        // setTimeout(() => {
        //   eventBus.$emit('start-scan-qr-ecdis')
        // }, 500)
    },
  }
}
</script>

<style scoped>
.unstyled-button {
  border: none;
  padding: 0;
  background: none;
}

.active-btn {
  background-color: #e9ecef;
  border-color: #ced4da;
  font-weight: 500;
}

.btn {
  border-radius: 0;
}

.btn-group .btn:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.btn-group .btn:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.custom-button-group .btn {
  background-color: #007bff;
  color: white;
  border: none;
  transition: background-color 0.3s ease;
}

.custom-button-group .btn:hover {
  background-color: #0056b3;
}
</style>