import L from "leaflet";
import {loadDataByPoint} from "@/helpers/api";
import {useMomentaryStore} from "@/stores/momentary";
import {useCartStore} from "@/stores/cart";
import {useCatalogsStore} from "@/stores/catalogs";
import {eventBus} from "@/main";

export function createContextButtons() {
    this.map.on('contextmenu', (e) => {

        this.map.closePopup();

        let momentaryStore = useMomentaryStore()
        let cartStore = useCartStore()
        let coordinates = {lat: e.latlng.lat, lon:e.latlng.lng}

        e.originalEvent.stopPropagation()
        e.originalEvent.preventDefault()

        function getCellName(itemName) {
            return itemName.split(':')[0].trim();
        }

        function getBandName(cellName) {
            let bands = ['Overview', 'General', 'Coastal', 'Approach', 'Harbour', 'Berthing']
            let bandName = bands[parseInt(cellName[2], 10) - 1] || bands[0];
            return bandName
        }

        function inFolio(cellName) {
            let momentaryStore = useMomentaryStore()
            let cells = momentaryStore.getCellsByRoute
            return cells.find(cell => cell.id === cellName && cell.isInFolio)
        }

        function isInRoute(cellName) {
            let momentaryStore = useMomentaryStore()
            let cells = momentaryStore.getCellsByRoute
            return cells.find(cell => cell.id === cellName)
        }

        function isSelected(cellName) {
            let momentaryStore = useMomentaryStore()
            let cells = momentaryStore.getCellsByRoute
            return cells.find(cell => cell.id === cellName && cell.isSelected)
        }

        let drawCell = (cellName) => {
            let catalogStore = useCatalogsStore()
            const allCharts = catalogStore.getItems;
            const cell = catalogStore.getItems.find(chart => chart.id === cellName);

            this.drawCell(cell);
        }

        loadDataByPoint(coordinates).then(data => {
            const modalContent = document.createElement('div');
            modalContent.style.cssText = 'position:fixed;top:50%;left:50%;transform:translate(-50%,-50%);background:white;padding:12px;border-radius:6px;z-index:1000;box-shadow:0 4px 12px rgba(0,0,0,0.2);font-size:13px;min-width:200px;max-width:300px;';
            modalContent.innerHTML = `<div id="item-list" style="display:flex;flex-direction:column;gap:3px;margin:0;">
                        ${data.map(item => {
                            let bgColor = '';
                            if (isInRoute(item)) {
                                if (inFolio(item)) bgColor = '#eeffec';
                                else {
                                    if (isSelected(item)) bgColor = '#e5f4ff';
                                    else bgColor = '#fff7e7';
                                }
                            }
                            return `<div class="selectable-item" style="padding:6px;cursor:pointer;border:1px solid #eaeaea;border-radius:4px;transition:all 0.2s ease;background-color:${bgColor};">${item}: ${getBandName(item)}</div>`;
                        }).join('')}
                        </div>`;

            let popup = L.popup({autoClose: false}).setLatLng(e.latlng).setContent(modalContent).openOn(this.map);
            popup.on('remove', () => {
                this.oneCellLayer.clearLayers();
                eventBus.$off('re-contextmenu1')
            });

            eventBus.$on('re-contextmenu1', () => {
                // Get the current items in the modal
                console.log("aaaa12")
                const modalItems = document.querySelectorAll('#item-list .selectable-item');

                modalItems.forEach(item => {
                    // const cellId = item.getAttribute('data-cell-id');
                    const cellId = getCellName(item.innerText)
                    let newBgColor = '';

                    console.log("aaaa12", getCellName(item.innerText))
                    console.log("aaaa12", cellId, isInRoute(cellId))
                    console.log("aaaa12", cellId, inFolio(cellId))
                    console.log("aaaa12", cellId, isSelected(cellId))
                    // Apply the same color logic as initial render
                    if (isInRoute(cellId)) {
                        if (inFolio(cellId)) newBgColor = '#eeffec';
                        else {
                            if (isSelected(cellId)) newBgColor = '#e5f4ff';
                            else newBgColor = '#fff7e7';
                        }
                    }

                    // Update the background color
                    item.style.backgroundColor = newBgColor
                });
            });

            const items = modalContent.querySelectorAll('.selectable-item');
            items.forEach(item => {
                item.addEventListener('mouseover', () => {
                    // Get the cell name from the item text
                    console.log(item.textContent);
                    let cellName = getCellName(item.textContent);
                    drawCell(cellName)
                    eventBus.$emit('highlight-onroute-cell', cellName)

                    // Default hover colors
                    let hoverBgColor = '#ededed';
                    let hoverBorderColor = '#cfcfcf';

                    // Check the third character and apply appropriate hover colors

                    if (isInRoute(cellName)) {
                        if (inFolio(cellName)) {
                            hoverBgColor = '#d4fecf';
                            hoverBorderColor = '#d4fecf';
                        } else {
                            if (isSelected(cellName)) {
                                hoverBgColor = '#c9e8ff';
                                hoverBorderColor = '#c9e8ff';
                            } else {
                                hoverBgColor = '#ffecca';
                                hoverBorderColor = '#ffecca';
                            }
                        }
                    }

                    item.style.backgroundColor = hoverBgColor;
                    item.style.borderColor = hoverBorderColor;
                    console.log(item.textContent);
                });

                item.addEventListener('mouseout', () => {
                    // Get the cell name and apply the appropriate normal state color
                    let cellName = getCellName(item.textContent);
                    let normalBgColor = '';
                    let normalBorderColor = '#eaeaea';

                    if (isInRoute(cellName)) {
                        if (inFolio(cellName)) {
                            normalBgColor = '#eeffec';
                        } else {
                            if (isSelected(cellName)) {
                                normalBgColor = '#e5f4ff';
                            } else {
                                normalBgColor = '#fff7e7';
                            }
                        }
                    }

                    item.style.backgroundColor = normalBgColor;
                    item.style.borderColor = normalBorderColor;
                });

                item.addEventListener('click', () => {
                    console.log('Selected item:', item.textContent);
                    let itemName = getCellName(item.textContent)

                    if (momentaryStore.getChartsByRoute.includes(itemName))
                        return

                    momentaryStore.appendChartsByRoute(itemName);

                    const allCharts = this.catalogsStore.getItems;
                    let allFolioCharts = this.deviceStore.orderedData.ukho.avcs;

                    let currentDate = new Date();
                    let threeDaysAgo = new Date(currentDate.setDate(currentDate.getDate() - 3));
                    let fiveDaysLater = new Date(currentDate.setDate(currentDate.getDate() + 5));

                    let newArray = [itemName].map(chart => {
                        let folioChart = allFolioCharts.find(folio => folio.name === chart);
                        return {
                            name: chart,
                            title: folioChart ? folioChart.title : null,
                            expiryDate: folioChart ? folioChart.expiryDate : null,
                            isExpired: folioChart ? new Date(folioChart.expiryDate) < threeDaysAgo : false,
                            isInFolio: !!folioChart,
                            isSelected: !folioChart
                        };
                    });

                    // let newArrayFiltered = newArray.filter(chart => !chart.isInFolio || new Date(chart.expiryDate) < fiveDaysLater);

                    let purchasedCharts = newArray.map(chart => {
                        let routeChart = allCharts.find(folio => folio.id === chart.name);
                        return {
                            ...chart,
                            ...routeChart
                        };
                    });

                    this.momentaryStore.appendCellsByRoute(purchasedCharts[0])

                    cartStore.addCells([itemName], true)

                    this.drawAllCells(null, true)


                    setTimeout(() => {
                        drawCell(itemName)
                        eventBus.$emit('highlight-onroute-cell', itemName)
                        eventBus.$emit('re-contextmenu1')
                    }, 100)

                });
            });
        })
    })
}
