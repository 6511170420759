import {RouteLeafDraw} from "@/my-leaf/routes/route-leaf-draw";
import * as waypoints from "@/my-leaf/routes/components/route-waypoints";
import * as legs from "@/my-leaf/routes/components/route-legs"
import store from "@/store";
import L from "leaflet";
import {myAxios} from "@/services/myAxios";
import {eventBus} from "@/main";
import {prepareError} from "@/my-leaf/routes/components/route-errors";

export class RouteLeafRouting extends RouteLeafDraw {

    bypassID1
    bypassID2

    async initbaserouting(el) {
        super.initbasewp(el);
    }

    getAutoRoute() {
        let pts = []

        let bounds = L.latLngBounds(this.waypoints[0].getLatLng(), this.waypoints[1].getLatLng());
        this.map.fitBounds(bounds)

        this.waypoints.forEach(waypoint => {
            pts.push({
                lat: waypoint.getLatLng().lat,
                lon: waypoint.getLatLng().lng,
                radius: this.routesStore.getRadius,
                xteLeft: this.routesStore.getXtel,
                xteRight: this.routesStore.getXter
            })
        })

        this.startRouteCalculation(pts, [], true)
    }

    calcucaltePathRoute(data) {
        data.startPoint.radius = 0
        data.startPoint.xteLeft = 0
        data.startPoint.xteRight = 0

        data.endPoint.radius = 0
        data.endPoint.xteLeft = 0
        data.endPoint.xteRight = 0

        const draught = this.routesStore.getShipNoGo;
        const height = this.routesStore.getShipHeight
        const radius = this.routesStore.getShipRadius
        const xte_l = this.routesStore.getShipXtel
        const xte_r = this.routesStore.getShipXter
        const speed = this.routesStore.getShipSpeed
        const recommended = this.routesStore.getShipUserecommendedtracks
        const ratio = this.routesStore.getShipTrackratio
        const shallowratio = this.routesStore.getShipShallowratio
        const shallowstrength = this.routesStore.getShipAvoidshallowstrength
        const avoiddistance = this.routesStore.getShipAvoiddistance

        data.params = {}
        data.params.depth = draught
        data.params.height = height
        data.params.defaultXteLeft = xte_l
        data.params.defaultXteRight = xte_r
        data.params.defaultRadius = radius
        data.params.speedKnots = speed
        data.params.useRecomendedTracks = recommended
        data.params.recomendedTrackCost = ratio
        data.params.shallowWaterCoeff = shallowratio
        data.params.shallowWaterCost = shallowstrength
        data.params.avoidDistance = avoiddistance

        data.lines.forEach((line) => {
            line.points.forEach(point => {
                point.radius = 0
                point.xteLeft = 0
                point.xteRight = 0
            })
        })

        console.log("path", data)
        this.momentaryStore.setBusy1(true)

        let startTime = Date.now()
        let URL = 'api/v1/route/multi_leg_file'
        myAxios.post(URL, data)
            .then(resp => {
                if (resp.data.errorCode === 0 && resp.data.isSuccess) {

                    let time = Date.now() - startTime
                    eventBus.$emit('show-calculate-time', time / 1000)

                    this.clearRoute(false, true)
                    // this.routesStore.addRouteResponse(resp.data, false, false)
                    this.routesStore.addShipRouteResponse(resp.data, false, false)

                    resp.data.calculatedRouteLegs.forEach(waypoint => {
                        // waypoints.addWP.call(this, L.latLng(waypoint.lat, waypoint.lon))
                        waypoints.addWP.call(this, L.latLng(waypoint.lat, waypoint.lon), -2)
                    })
                    legs.drawLegs.call(this, true, false)

                    eventBus.$emit('is-need-check-safety', false)
                    eventBus.$emit('calculate-ukc')
                    eventBus.$emit('show-graphs')



                    this.momentaryStore.setBusy1(false)
                } else {
                    this.momentaryStore.setBusy1(false)
                    alert('Error: (' + resp.data.errorCode + ') Can\'t compute routes at the moment');
                }
            }).catch((error) => {
            this.momentaryStore.setBusy1(false)
            alert('Error: (' + error.status + ') \nCan\'t compute routes at the moment');

            this.momentaryStore.setBusy1(false)
            this.removeRouteWithDebug()
            this.restoreContextAfterAuto()
        })


        // let pts = []
        //
        // this.waypoints.forEach(waypoint => {
        //     pts.push({
        //         lat: waypoint.getLatLng().lat,
        //         lon: waypoint.getLatLng().lng,
        //         radius: this.routesStore.getRadius,
        //         xteLeft: this.routesStore.getXtel,
        //         xteRight: this.routesStore.getXter
        //     })
        // })
        //
        // this.startRouteCalculation(pts, [], false)
    }


    checkSafety() {
        let pts = []

        this.waypoints.forEach(waypoint => {
            pts.push({
                lat: waypoint.getLatLng().lat,
                lon: waypoint.getLatLng().lng,
                radius: this.routesStore.getRadius,
                xteLeft: this.routesStore.getXtel,
                xteRight: this.routesStore.getXter
            })
        })

        this.startRouteCalculation(pts, [], false)
    }

    async getRoute(routeId) {
        return new Promise(resolve => {
            myAxios.get('api/v1/route/' + routeId)
                .then(resp => {

                    store.commit("routes/setProgress", resp.data.progress)

                    if (resp.data.status === 'calculating' && this.isAutoroutingCancel) {
                        store.commit("routes/setCalculatingStatus", false)
                        //TODO
                        this.restoreContextAfterAuto()
                        this.isAutoroutingCancel = false
                        myAxios.delete("api/v1/route/" + routeId).then(resp => {
                        })
                        resolve(resp.data)
                    }

                    if (resp.data.status === 'calculating' && resp.data.restoreRoutePoints.length > 0) {
                        this.routesStore.addRouteGeneral(resp.data.restoreRoutePoints)
                        this.drawGeneralRoute()
                        // legs.drawGeneralRoute.call(this)
                    }

                    if (resp.data.status === 'calculating' && resp.data.routeLegs.length > 0) {
                        this.routesStore.addRouteLegs(resp.data.routeLegs)
                        // legs.drawLegsByProgress.call(this)
                    }

                    if (resp.data.status === 'done') {
                        store.commit("routes/setCalculatingStatus", false)

                        // console.log("ellapsed time1: ", "aaaa")
                        let time = resp.data.debugInfo.find(item => item.name === 'full')
                        // console.log("ellapsed time1: ", resp.data.debugInfo)
                        if (time) {
                            eventBus.$emit('show-calculate-time', time.ellapsed / 1000)
                        }


                        if (resp.data.isSuccess) {
                            if (this.routesStore.getShipResponseBackup.isBypass) {
                                let id1 = this.routesStore.getShipResponseBackup.startIndex
                                let id2 = this.routesStore.getShipResponseBackup.finishIndex
                                let prevResp = {...this.routesStore.getShipResponseBackup}

                                // prevResp = resp.data

                                console.log("aaaa", resp.data)

                                console.log("aaaa2", resp.data['routePoints'])
                                // console.log("aaaa2", resp.data['calculatedRouteLegs'])
                                // console.log("aaaa2", resp.data['routePointInfos'])
                                // console.log("aaaa2", resp.data['routeLegs'])

                                console.log("aaaa4", prevResp['routePoints'])
                                // console.log("aaaa4", prevResp['calculatedRouteLegs'])
                                // console.log("aaaa4", prevResp['routePointInfos'])
                                // console.log("aaaa4", prevResp['routeLegs'])

                                console.log("aaaa delete", id1, id2 - id1)

                                // let arrayBackup1 = prevResp['routePoints']
                                // let arrayBackup2 = prevResp['calculatedRouteLegs']
                                // let arrayBackup3 = prevResp['routePointInfos']
                                // let arrayBackup4 = prevResp['routeLegs']
                                //
                                // let arrayResponse1 = resp.data['routePoints']
                                // let arrayResponse2 = resp.data['calculatedRouteLegs']
                                // let arrayResponse3 = resp.data['routePointInfos']
                                // let arrayResponse4 = resp.data['routeLegs']
                                //
                                // arrayBackup1.splice(id1, id2-id1, ...arrayResponse1)
                                // arrayBackup2.splice(id1, id2-id1, ...arrayResponse2)
                                // arrayBackup3.splice(id1, id2-id1, ...arrayResponse3)
                                // arrayBackup4.splice(id1, id2-id1, ...arrayResponse4)
                                //
                                // console.log("aaaa routePoints", arrayBackup1)
                                // console.log("aaaa calculatedRouteLegs", arrayBackup2)
                                // console.log("aaaa routePointInfos", arrayBackup3)
                                // console.log("aaaa routeLegs", arrayBackup4)

                                // prevResp['routePoints'] = arrayBackup1
                                // prevResp['calculatedRouteLegs'] = arrayBackup2
                                // prevResp['routePointInfos'] = arrayBackup3
                                // prevResp['routeLegs'] = arrayBackup4

                                prevResp['routePoints'].splice(id1, id2 - id1, ...resp.data['routePoints'])
                                // prevResp['calculatedRouteLegs'].splice(id1, id2-id1, ...resp.data['calculatedRouteLegs'])
                                // prevResp['routePointInfos'].splice(id1, id2-id1, ...resp.data['routePointInfos'])
                                // prevResp['routeLegs'].splice(id1, id2-id1, ...resp.data['routeLegs'])

                                let idCount = 0;
                                prevResp['routePoints'].forEach(item => {
                                    item.id = idCount++
                                })
                                idCount = 0;
                                prevResp['calculatedRouteLegs'].forEach(item => {
                                    item.id = idCount++
                                })

                                resp.data = prevResp

                                console.log("aaaa12", resp.data['routePoints'])
                                // debugger
                            }

                            this.clearRoute(false, true)
                            // this.routesStore.addRouteResponse(resp.data, false, false)
                            this.routesStore.addShipRouteResponse(resp.data, false, false)

                            resp.data.calculatedRouteLegs.forEach(waypoint => {
                                // waypoints.addWP.call(this, L.latLng(waypoint.lat, waypoint.lon))
                                waypoints.addWP.call(this, L.latLng(waypoint.lat, waypoint.lon), -2)
                            })
                            legs.drawLegs.call(this, true, false)

                            eventBus.$emit('is-need-check-safety', false)
                            eventBus.$emit('calculate-ukc')
                            eventBus.$emit('show-graphs')

                            // this.routesStore.addRouteResponse(resp.data, false, false)


                            // console.log("aaa")
                            // this.drawRoute(resp.data)

                        } else
                            this.showError(resp.data)
                        // prepareError(resp.data.errorCode)

                        // this.restoreContextAfterAuto()
                        resolve(resp.data)
                    }

                    if (resp.data.status === 'calculating' && !this.isAutoroutingCancel) {
                        setTimeout(() => {
                            resolve(this.getRoute(routeId))
                        }, 1000);
                    }
                })
        })
    }

    showError(resp) {
        let points = []
        // if (store.state.fickle.routes.globalRoute.ports.length > 1) {
        //     points = store.state.fickle.routes.globalRoute.ports[0] + " : " + store.state.fickle.routes.globalRoute.ports[1]
        //     store.commit("fickle/clearGlobalRoute")
        // } else {
        //     points = this.routeRequestPoints[0].lat.toFixed(6) + ", " + this.routeRequestPoints[0].lon.toFixed(6) + ' : \n' + this.routeRequestPoints[1].lat.toFixed(6) + ", " + this.routeRequestPoints[1].lon.toFixed(6)
        // }
        //
        //
        // //  -----------------
        // this.routesStore.addRouteGeneral(resp.restoreRoutePoints)
        //
        // this.map.removeLayer(this.wpGlobalGroup)
        // this.wpGlobalMarkers.forEach(wp => {
        //     wp.remove()
        // })
        // this.wpGlobalMarkers = []
        // // this.map.addLayer(this.wpGlobalGroup);
        //
        // let globalRoute = []
        // this.routesStore.routeGeneral.forEach(wp => {
        //     this.addGlobalWP({lat: wp.lat, lng: wp.lon})
        //     globalRoute.push([wp.lat, wp.lon])
        // })
        //
        // let routeLine = L.polyline(globalRoute, {
        //     color: 'blue',
        //     weight: 1,
        //     dashArray: '5, 5',
        //     dashOffset: '0'
        // }).addTo(this.debugLegsGroup);
        // //  -----------------


        prepareError(resp.errorCode, points)
    }

    startRouteCalculation(pts, zones, isAutoRoute = true, isGlobalRoute = false, isBypass = false, type = 'none') {
        // this.routeRequestPoints = pts
        // store.commit("routes/setRoutePointsBackup")

        // this.addNoGoLayer();
        const draught = this.routesStore.getShipNoGo;
        const height = this.routesStore.getShipHeight
        const radius = this.routesStore.getShipRadius
        const xte_l = this.routesStore.getShipXtel
        const xte_r = this.routesStore.getShipXter
        const speed = this.routesStore.getShipSpeed
        const recommended = this.routesStore.getShipUserecommendedtracks
        // const generalroutesonly = isBypass ? false : this.routesStore.routeSettings.usegeneralroutes

        let generalroutesonly = this.routesStore.getShipUsegeneralroutes
        if (type === 'general') {
            generalroutesonly = true
        } else if (type === 'detailed')
            generalroutesonly = false

        const ratio = this.routesStore.getShipTrackratio
        const shallowratio = this.routesStore.getShipShallowratio
        const shallowstrength = this.routesStore.getShipAvoidshallowstrength
        const avoiddistance = this.routesStore.getShipAvoiddistance
        const usesuez = this.routesStore.getShipUsesuez
        const usepanama = this.routesStore.getShipUsepanama
        const usebering = this.routesStore.getShipUsebering
        const userivers = this.routesStore.getShipUserivers
        const refinecoeff = this.routesStore.getShipRefineCoeffecient

        // store.commit("routes/resetStorage")

        // this.removeRoute()

        // let nogoObjects = []
        // store.state.fickle.routes.nogoObjects.forEach(item => {
        //     nogoObjects.push(item)
        // })
        // let nogoCategories = []
        // store.state.fickle.routes.nogoCategories.forEach(item => {
        //     nogoCategories.push(item)
        // })

        let gRoute = []
        // let start = {}
        // let finish = {}
        // let points = pts
        let post_text = {}

        let URL = '/api/v1/route'

        let nogoZones = []
        this.routesStore.nogoZones.forEach(item => {
            nogoZones.push(item)
        })

        if (isGlobalRoute) {
            store.commit("routes/setCalculatingStatus", true)
            URL = '/api/v1/mss_route'
            let portStart = store.state.fickle.routes.globalRoute.ports[0]
            let portFinish = store.state.fickle.routes.globalRoute.ports[1]

            let portStartCoordinates = store.state.fickle.routes.globalRoute.portsCoordinates[0]
            let portFinishCoordinates = store.state.fickle.routes.globalRoute.portsCoordinates[1]

            let southWest = L.latLng(portStartCoordinates.lat, portStartCoordinates.lon),
                northEast = L.latLng(portFinishCoordinates.lat, portFinishCoordinates.lon),
                bounds = L.latLngBounds(southWest, northEast);

            this.map.fitBounds(bounds)

            // alert('General route (a): ' + generalroutesonly)

            post_text = {
                useAutoRoute: isAutoRoute,
                useRecomendedTracks: recommended,
                resctrictedZones: nogoZones,
                speedKnots: speed,
                points: [],
                from: portStart,
                to: portFinish,
                depth: draught,
                height: height,
                defaultXteLeft: xte_l,
                defaultXteRight: xte_r,
                defaultRadius: radius,
                waitSeconds: 0,
                recomendedTrackCost: ratio,
                shallowWaterCoeff: shallowratio,
                shallowWaterCost: shallowstrength,
                avoidDistance: avoiddistance,
                generalRouteRefineCoeff: refinecoeff,
                allowSuezCanal: usesuez,
                allowPanamaCanal: usepanama,
                allowBeringStrait: usebering,
                allowRivers: userivers,
                generalRouteOnly: generalroutesonly,
                resctrictedIds: [],
                resctrictedCategories: [],
                restoreRoutePoints: gRoute
            }
        } else {
            post_text = {
                useAutoRoute: isAutoRoute,
                useRecomendedTracks: recommended,
                resctrictedZones: nogoZones,
                speedKnots: speed,
                points: pts,
                depth: draught,
                height: height,
                defaultXteLeft: xte_l,
                defaultXteRight: xte_r,
                defaultRadius: radius,
                waitSeconds: 0,
                recomendedTrackCost: ratio,
                shallowWaterCoeff: shallowratio,
                shallowWaterCost: shallowstrength,
                avoidDistance: avoiddistance,
                generalRouteRefineCoeff: refinecoeff,
                allowSuezCanal: usesuez,
                allowPanamaCanal: usepanama,
                allowBeringStrait: usebering,
                allowRivers: userivers,
                generalRouteOnly: generalroutesonly,
                resctrictedIds: [],
                resctrictedCategories: [],
                restoreRoutePoints: []
            }
        }

        // alert('General route (b): ' + generalroutesonly)

        navigator.clipboard.writeText(JSON.stringify(post_text))

        myAxios.get("api/v1/route/state").then(resp => {
            myAxios.post(URL, post_text)
                .then(resp => {
                    if (resp.data.errorCode === 0 && resp.data.id) {
                        store.commit("routes/setCalculatingStatus", true)
                        this.getRoute(resp.data.id)
                    } else {
                        alert('Error: (' + resp.data.errorCode + ') Can\'t compute routes at the moment');
                    }
                }).catch((error) => {
                if (isGlobalRoute)
                    alert('Error: (' + error + ') \nCan\'t compute routes by ports at the moment');
                else
                    alert('Error: (' + error.status + ') \nCan\'t compute routes at the moment');

                this.removeRouteWithDebug()
                this.restoreContextAfterAuto()
            })
        }).catch(error => {
            if (error.response.status === 503) {
                alert("No free slots for route calculating, try later")
                this.removeRouteWithDebug()
                this.restoreContextAfterAuto()
            } else {
                alert("ERROR CHECK: 1024")
            }

        })
    }

    startBypassCalculation(type) {
        let pts = []

        this.routesStore.backupShipResponse(this.bypassID1, this.bypassID2)
        // store.commit("routes/useBypass", [this.bypassID1, this.bypassID2])

        pts.push({
            lat: this.waypoints[this.bypassID1].getLatLng().lat,
            lon: this.waypoints[this.bypassID1].getLatLng().lng,
            radius: this.routesStore.getRadius,
            xteLeft: this.routesStore.getXtel,
            xteRight: this.routesStore.getXter
        })

        pts.push({
            lat: this.waypoints[this.bypassID2].getLatLng().lat,
            lon: this.waypoints[this.bypassID2].getLatLng().lng,
            radius: this.routesStore.getRadius,
            xteLeft: this.routesStore.getXtel,
            xteRight: this.routesStore.getXter
        })

        this.startRouteCalculation(pts, [], true, false, true, type)
    }

    calculateByPass(wpID1, wpID2) {
        let bounds = L.latLngBounds(this.waypoints[wpID1].getLatLng(), this.waypoints[wpID2].getLatLng());
        this.map.fitBounds(bounds)

        if (wpID2 > wpID1) {
            this.bypassID1 = wpID1
            this.bypassID2 = wpID2
        } else {
            this.bypassID1 = wpID2
            this.bypassID2 = wpID1
        }

        eventBus.$emit('showCalculateByPassDialog')
    }
}
