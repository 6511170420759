<template>
  <div>

    <line-details-dialog
        :show="showLineDetailsDialog"
        :initial-values="lineDetailsInitialValues"
        @submit="handleLineDetailsSubmit"
        @cancel="handleLineDetailsCancel"
    />

    <b-container fluid>
      <b-row>
        <b-col cols="12">
          <b-card header="EDITOR">
            <b-row>
              <b-col lg="4">
                <editor-table ref="logbook-table" :map=map></editor-table>
              </b-col>
              <b-col>
                <editor-map :map=map :height=map_size></editor-map>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import EditorMap from "@/views/editor/EditorMap.vue";
import EditorTable from "@/views/editor/EditorTable.vue";
import LineDetailsDialog from "./EditorDetailsDialog.vue";
import {editor_map, eventBus} from "@/main";
import {myAxios} from "@/services/myAxios";

export default {
  components: {EditorMap, EditorTable, LineDetailsDialog},
  data() {
    return {
      windowWidth: window.innerWidth,
      map: editor_map,
      mapHeight: window.innerHeight - 200,
      mapHeightMobile: window.innerHeight - 130,
      showLineDetailsDialog: false,
      lineDetailsInitialValues: {}
    }
  },
  mounted() {
    myAxios.get('/api/v1/manage/test')

    window.onresize = () => {
      this.windowWidth = window.innerWidth
      this.mapHeightMobile = window.innerHeight - 130
    }

    eventBus.$on('show-line-details-dialog', this.openLineDetailsDialog);
 },
  beforeUnmount() {
    eventBus.$off('show-line-details-dialog', this.openLineDetailsDialog);
  },
  computed: {
    // ...mapStores(useMomentaryStore, useRoutesStore, useLogbookStore, useUserStore, useDeviceStore),
    is_mobile() {
      return (this.windowWidth <= 991) ||
          ('ontouchstart' in window) ||
          (navigator.maxTouchPoints > 0) ||
          (navigator.msMaxTouchPoints > 0)
    },
    map_size() {
      if (this.is_mobile)
        return this.mapHeightMobile
      else
        return window.innerHeight - 200
    },
  },
  methods: {

    openLineDetailsDialog(initialValues) {
      this.lineDetailsInitialValues = initialValues;
      this.showLineDetailsDialog = true;
    },

    handleLineDetailsSubmit(formData) {
      eventBus.$emit('line-details:submit', formData);
      this.showLineDetailsDialog = false;
    },

    handleLineDetailsCancel() {
      eventBus.$emit('line-details:cancel');
      this.showLineDetailsDialog = false;
    }


  }
}
</script>

<style scoped>
/* Adjust container padding for mobile */
@media (max-width: 991px) {
  .container-fluid {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .row {
    margin-left: -5px !important;
    margin-right: -5px !important;
  }

  .col, [class*="col-"] {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
</style>