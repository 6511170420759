<template>
  <div>
    <b-overlay
        :show="!userStore.isShipsLoaded || ($store.state.auth.user.role === 'distributor' && !deviceStore.isAgreementLoaded)"
        opacity=0.9 bg-color="white" class="mt-4">
      <template #overlay>
        <div class="text-center">
          <b-icon icon="hourglass" font-scale="2" animation="spin"></b-icon>
          <p v-if="!userStore.isShipsLoaded">Loading ships...</p>
          <p v-if="$store.state.auth.user.role === 'distributor' && userStore.isShipsLoaded && !deviceStore.isAgreementLoaded">
            Loading agreements...</p>
        </div>
      </template>

      <b-container v-if="!is_mobile" fluid>
        <b-row>
          <b-col cols="12">
            <b-card header="LOGBOOK">
              <b-row>
                <b-col order=4 order-lg=1 lg="4">

                  <b-form-select v-model="selected_ship" :options="ships" :title="getSelectedShipTooltip"
                                 v-b-tooltip.hover size="sm" class="flex-grow-1 mr-2 mb-2"></b-form-select>


                  <div v-show="displayContent === 'logbook'">
                    <logbook-table ref="logbook-table" :map=map></logbook-table>
                    <b-button variant="info" size="sm" @click="showAddReport()">Add Report
                    </b-button>
                  </div>
                  <div v-show="displayContent === 'report'">
                    <logbook-report ref="logbook-report"></logbook-report>
                  </div>
                </b-col>
                <b-col order=4 order-lg=1 lg="8">
                  <logbook-map :map=map :height=map_size></logbook-map>
                </b-col>
              </b-row>
            </b-card>

<!--            <div v-show="activeMobileView === 'table'">-->
<!--              <logbook-table ref="logbook-table" :map=map></logbook-table>-->
<!--            </div>-->

<!--            <div v-show="activeMobileView === 'report'">-->
<!--              22222<logbook-report ref="logbook-report"></logbook-report>-->
<!--            </div>-->

          </b-col>
        </b-row>
      </b-container>

      <b-container v-else fluid>
        <div v-show="activeMobileView === 'map'">
          <b-row class="mb-2">
            <b-col>
              <div class="d-flex justify-content-between align-items-center">
                <b-form-select v-model="selected_ship" :options="ships" :title="getSelectedShipTooltip"
                               v-b-tooltip.hover size="sm" class="flex-grow-1 mr-2"></b-form-select>
                <b-button-group>
                  <b-button
                      variant="light"
                      @click="activeMobileView = 'table'"
                      size="sm">
                    <b-icon icon="table" class="mr-1"></b-icon>
                  </b-button>
                  <b-button
                      variant="light"
                      @click="showAddReport()"
                      size="sm">
                    <b-icon icon="plus-circle" class="mr-1"></b-icon>
                  </b-button>
                </b-button-group>
              </div>
            </b-col>
          </b-row>

          <logbook-map :map=map :height=map_size></logbook-map>
        </div>

        <div v-show="activeMobileView === 'table'">
          <b-row class="mb-2">
            <b-col>
              <div class="d-flex justify-content-between align-items-center">
                <b-form-select v-model="selected_ship" :options="ships" :title="getSelectedShipTooltip"
                               v-b-tooltip.hover size="sm" class="flex-grow-1 mr-2"></b-form-select>
                <b-button-group>
                  <b-button
                      variant="light"
                      @click="activeMobileView = 'map'"
                      size="sm">
                    <b-icon icon="map" class="mr-1"></b-icon>
                  </b-button>
                  <b-button
                      variant="light"
                      @click="showAddReport()"
                      size="sm">
                    <b-icon icon="plus-circle" class="mr-1"></b-icon>
                  </b-button>
                </b-button-group>
              </div>
            </b-col>
          </b-row>

          <logbook-table ref="logbook-table" :map=map></logbook-table>
        </div>

        <div v-show="activeMobileView === 'report'">
          <b-row class="mb-2">
            <b-col>
              <div class="d-flex justify-content-between align-items-center">
                <b-form-select v-model="selected_ship" :options="ships" :title="getSelectedShipTooltip"
                               v-b-tooltip.hover size="sm" class="flex-grow-1 mr-2"></b-form-select>
                <b-button-group>
                  <b-button
                      variant="light"
                      @click="activeMobileView = 'map'"
                      size="sm">
                    <b-icon icon="map" class="mr-1"></b-icon>
                  </b-button>
                  <b-button
                      variant="light"
                      @click="activeMobileView = 'table'"
                      size="sm">
                    <b-icon icon="table" class="mr-1"></b-icon>
                  </b-button>
                </b-button-group>
              </div>
            </b-col>
          </b-row>

          <logbook-report ref="logbook-report"></logbook-report>

        </div>

        <!--        <div v-show="activeMobileView === 'report'">-->
        <!--          <logbook-report ref="logbook-report"></logbook-report>-->
        <!--        </div>        -->

      </b-container>

      <!-- Mobile layout -->
      <!--              <template v-else>-->
      <!--                &lt;!&ndash; Ship selector always visible on mobile &ndash;&gt;-->
      <!--                <div v-show="activeMobileView === 'map'">-->
      <!--                  <b-row class="mb-2">-->
      <!--                    <b-col>-->
      <!--                      <div class="d-flex justify-content-between align-items-center">-->
      <!--                        <b-form-select v-model="selected_ship" :options="ships" :title="getSelectedShipTooltip" v-b-tooltip.hover size="sm" class="flex-grow-1 mr-2"></b-form-select>-->
      <!--                        <b-button-group>-->
      <!--                          <b-button-->
      <!--                              variant="light"-->
      <!--                              @click="activeMobileView = 'table'"-->
      <!--                              size="sm">-->
      <!--                            <b-icon icon="table" class="mr-1"></b-icon>-->
      <!--                          </b-button>-->
      <!--                          <b-button-->
      <!--                              variant="light"-->
      <!--                              @click="showAddReport()"-->
      <!--                              size="sm">-->
      <!--                            <b-icon icon="plus-circle" class="mr-1"></b-icon>-->
      <!--                          </b-button>-->
      <!--                        </b-button-group>-->
      <!--                      </div>-->
      <!--                    </b-col>-->
      <!--                  </b-row>-->
      <!--                  <logbook-map :map=map :height=map_size></logbook-map>-->
      <!--                </div>-->
      <!--              </template>-->


      <!--            </b-card>-->


    </b-overlay>

    <!--    <b-container v-show="!momentaryStore.isBusy" fluid>-->
    <!--      <b-row>-->
    <!--        <b-col class="mt-2" v-show="displayContent === 'logbook'" order=4 order-lg=1 lg="4">-->
    <!--          <b-button block variant="primary" size="sm" @click="showLogbook()">logbook</b-button>-->
    <!--          <b-button class="mt-2" variant="outline-primary" size="sm" @click="showAddReport()">Add Report-->
    <!--          </b-button>-->
    <!--          <logbook-table class="mt-2" ref="logbook-table" :map=map></logbook-table>-->
    <!--        </b-col>-->
    <!--        <b-col class="mt-2" v-show="displayContent === 'report'" order=4 order-lg=1 lg="4">-->
    <!--          <logbook-report ref="logbook-report"></logbook-report>-->
    <!--        </b-col>-->

    <!--        <b-col order=1 order-lg=2 lg="8">-->
    <!--          <div>-->
    <!--            <logbook-map :map=map :height=map_size></logbook-map>-->
    <!--          </div>-->
    <!--        </b-col>-->

    <!--      </b-row>-->
    <!--    </b-container>-->

    <!--      <b-container fluid>-->
    <!--        <b-row>-->
    <!--          <b-col order=1 order-lg=2 lg="8">-->
    <!--            <logbook-map :map=map :height=map_size></logbook-map>-->
    <!--          </b-col>-->
    <!--          <b-col order=2 order-lg=1 lg="4">-->
    <!--            <logbook-table :map=map ></logbook-table>-->
    <!--          </b-col>-->
    <!--        </b-row>-->
    <!--      </b-container>-->
  </div>
</template>

<script>
import LogbookMap from "@/views/logbook/LogbookMap.vue";
import LogbookTable from "@/views/logbook/LogbookTable.vue";
import {logbooks_map} from "@/main";
import LogbookReport from "@/views/logbook/LogbookReport.vue";
import MyMap from "@/views/routes/RoutesMap.vue";
import OrdersMap from "@/views/routes/OrdersMap.vue";
import {mapStores} from "pinia";
import {useRoutesStore} from "@/stores/routes";
import {useLogbookStore} from "@/stores/logbook";
import {loadAgreements, loadInstallations, loadShips} from "@/helpers/api";
import {useDeviceStore} from "@/stores/device";
import {useUserStore} from "@/stores/user";
import {useMomentaryStore} from "@/stores/momentary";
import {myAxios} from "@/services/myAxios";

export default {
  components: {LogbookReport, LogbookMap: LogbookMap, LogbookTable: LogbookTable},
  data() {
    return {
      displayContent: 'logbook',
      tabIndex: 0,

      activeMobileView: 'map',

      selected_ship: "",
      ships: [],

      windowWidth: window.innerWidth,
      // map: new ShipLeaf(),
      map: logbooks_map,
      mapHeight: window.innerHeight - 200,
      mapHeightMobile: window.innerHeight - 130,
    }
  },
  watch: {
    'selected_ship'(newVal, oldVal) {
      this.deviceStore.selectDevice(newVal);
      this.$refs['logbook-report'].changeShip()
      this.$refs['logbook-table'].loadLogbook()
    }
  },
  mounted() {
    myAxios.get('/api/v1/manage/test')

    window.onresize = () => {
      this.windowWidth = window.innerWidth
      this.mapHeightMobile = window.innerHeight - 130
    }

    if (this.routesStore.getShipWaypointsCount > 0)
      this.map.drawRoute()

    if (this.logbookStore.logbook.length > 0)
      this.map.restoreReports()

    loadShips().then((response) => {
      if (this.deviceStore.isDeviceIDExist)
        this.selected_ship = this.deviceStore.getDeviceID
      else
        this.selected_ship = this.userStore.getECDISShips[0]?.value ?? ''
      this.ships = this.userStore.getECDISShips

      if (this.$store.state.auth.user.role === 'distributor') {
        loadAgreements().then((response) => {
          if (this.deviceStore.isDeviceIDExist)
            this.selected_ship = this.deviceStore.getDeviceID
          else
            this.selected_ship = this.userStore.getECDISShips[0]?.value ?? ''
          this.ships = this.userStore.getECDISShips
        })
      }
    })

    // let qrcode2 = require("@/data/mss-ship5.json")
    // this.$store.commit("fickle/setQR1", qrcode2)
  },
  computed: {
    ...mapStores(useMomentaryStore, useRoutesStore, useLogbookStore, useUserStore, useDeviceStore),
    is_mobile() {
      return (this.windowWidth <= 991) ||
          ('ontouchstart' in window) ||
          (navigator.maxTouchPoints > 0) ||
          (navigator.msMaxTouchPoints > 0)
    },
    map_size() {
      if (this.is_mobile)
        return this.mapHeightMobile
      else
        return window.innerHeight - 200
    },
  },
  methods: {
    showAddReport() {
      this.$refs['logbook-report'].form.lat = ''
      this.$refs['logbook-report'].form.lon = ''
      this.displayContent = 'report'

      if (this.is_mobile) {
        this.activeMobileView = 'report';
      } else {
        this.displayContent = 'report';
      }

    },

    getSelectedShipTooltip() {
      let tooltip = '';
      if (this.$store.state.auth.user.role === 'distributor') {
        let deviceId = this.selected_ship;
        let ship = this.ships.find(e => e.value === deviceId);
        tooltip = ship?.email + ", " + deviceId;
      }
      return tooltip;
    },

    showLogbook() {
      this.displayContent = 'logbook'
    },
    getLogbookButtonStatus(type) {
      if (this.displayContent === 'logbook' && type === 'logbook')
        return 'primary'
      else
        return 'outline-primary'
    },
  }
}
</script>

<style scoped>
/* Adjust container padding for mobile */
@media (max-width: 991px) {
  .container-fluid {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .row {
    margin-left: -5px !important;
    margin-right: -5px !important;
  }

  .col, [class*="col-"] {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
</style>