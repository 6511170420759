<template>
  <!--  <b-overlay :show="preparingCells" rounded="sm" spinner-variant="danger">-->
  <div>
    <b-modal title="Import list of cells" v-model="openCellsList" ok-only @ok="primarMapBoosterByList">
      <b-form-textarea v-model="cellList"
                       placeholder="Paste comma or new line separated cells names..."></b-form-textarea>
    </b-modal>

    <div v-if="!orderPlaced" class="mt-2">
      <b-row>
        <b-col cols="7">
          <b-card class="px-2" no-body bg-variant="light">
            <!--            <b-container>-->
            <b-form inline>
              <b-form-select style="width: auto" v-if="deviceStore.getAgreementProvider === 'UKHO'"
                             v-model="selectedType_UKHO" size="sm"
                             class="mr-1" :options="[
                { value: 'AVCS', text: 'AVCS' },
                { value: 'ADP', text: 'ADP' },
                { value: 'eNP', text: 'eNP' }]"></b-form-select>
              <b-form-select v-if="deviceStore.getAgreementProvider === 'Primar'" v-model="selectedType_Primar"
                             size="sm" :options="[
                { value: 'ENC', text: 'ENC', disabled: true }]"></b-form-select>
              <b-form-select :disabled="selectedType_UKHO === 'ADP' || selectedType_UKHO === 'eNP'" style="width: auto" v-model="selectedPeriod" size="sm" :options="[
                { value: '4', text: '3 m.' },
                { value: '1', text: '6 m.' },
                { value: '0', text: '12 m.' }]"></b-form-select>
            </b-form>
            <!--            </b-container>-->
          </b-card>
        </b-col>
        <b-col>
          <!--          <b-button-->
          <!--              class="mr-1" size="sm" variant="info" @click="importClipboard()">Import-->
          <!--          </b-button>-->

          <b-button v-if="!viewParent.editMode"
                    class="mr-1"
                    :disabled="cartStore.getCartLength === 0 || cartStore.getTotalPrice === undefined || cartStore.getTotalPrice === 0"
                    size="sm" variant="success" @click="placeOrder()">Submit
          </b-button>

          <b-button v-if="viewParent.editMode"
                    :disabled="cartStore.getCartLength === 0 || cartStore.getTotalPrice === undefined || cartStore.getTotalPrice === 0"
                    size="sm" variant="success" @click="updateOrder()">Update
          </b-button>


        </b-col>
      </b-row>
      <b-row style="height: 30px">
        <b-col>
            <span class="smt2s" v-if="cartStore.getTotalPrice">
              Total price: {{ getDisplayTotalPrice() }}<br>
            </span>
          <span class="smt2s" v-else-if="priceCalculating">
                Calculating price...
            </span>
        </b-col>

        <!--        <b-button v-b-tooltip.hover title="Select all" v-if="filterSelectAll && cartStore.getCartLength > 0"-->
        <!--                  class="btn shadow-none border-0 float-right" size="sm" variant="outline-secondary"-->
        <!--                  @click="toggleBands('on')">-->
        <!--          <b-icon icon="square" font-scale="1.2"></b-icon>-->
        <!--        </b-button>-->
        <!--        <b-button v-b-tooltip.hover title="Unselect all" v-if="!filterSelectAll && cartStore.getCartLength > 0"-->
        <!--                  class="btn shadow-none border-0 float-right" size="sm" variant="outline-secondary"-->
        <!--                  @click="toggleBands('off')">-->
        <!--          <b-icon icon="check-square" font-scale="1.2"></b-icon>-->
        <!--        </b-button>-->
      </b-row>
      <b-row>
        <b-col>

          <b-button v-b-tooltip.hover title="Overview" v-if="cartStore.getCartLength > 0"
                    class="btn shadow-none float-left" size="sm"
                    :variant="filterOVR ? 'outline-warning' : 'outline-primary'"
                    @click="switchBand(1)">
            OVR
          </b-button>

          <b-button v-b-tooltip.hover title="General" v-if="cartStore.getCartLength > 0"
                    class="btn shadow-none float-left" size="sm"
                    :variant="filterGNR ? 'outline-warning' : 'outline-primary'"
                    @click="switchBand(2)">
            GNR
          </b-button>

          <b-button v-b-tooltip.hover title="Coastal" v-if="cartStore.getCartLength > 0"
                    class="btn shadow-none float-left" size="sm"
                    :variant="filterCST ? 'outline-warning' : 'outline-primary'"
                    @click="switchBand(3)">
            CST
          </b-button>

          <b-button v-b-tooltip.hover title="Approach" v-if="cartStore.getCartLength > 0"
                    class="btn shadow-none float-left" size="sm"
                    :variant="filterAPR ? 'outline-warning' : 'outline-primary'"
                    @click="switchBand(4)">
            APR
          </b-button>

          <b-button v-b-tooltip.hover title="Harbour" v-if="cartStore.getCartLength > 0"
                    class="btn shadow-none float-left" size="sm"
                    :variant="filterHRB ? 'outline-warning' : 'outline-primary'"
                    @click="switchBand(5)">
            HRB
          </b-button>

          <b-button v-b-tooltip.hover title="Berthing" v-if="cartStore.getCartLength > 0"
                    class="btn shadow-none float-left" size="sm"
                    :variant="filterBRG ? 'outline-warning' : 'outline-primary'"
                    @click="switchBand(6)">
            BRG
          </b-button>

<!--          <b-button v-b-tooltip.hover title="All" v-if="cartStore.getCartLength > 0"-->
<!--                    class="btn shadow-none float-left" size="sm"-->
<!--                    :variant="filterSelectAll ? 'outline-warning' : 'outline-primary'"-->
<!--                    @click="switchBand(0)">-->
<!--            ALL-->
<!--          </b-button>-->


          <!--          <b-form-checkbox-group-->
          <!--              size="sm"-->
          <!--              v-model="selected"-->
          <!--              :options="options"-->
          <!--              class="mb-3"-->
          <!--              value-field="item"-->
          <!--              text-field="name"-->
          <!--              disabled-field="notEnabled"-->
          <!--          >-->
          <!--          </b-form-checkbox-group>-->
        </b-col>
      </b-row>
    </div>

    <b-table v-if="!orderPlaced && selectedType_UKHO === 'AVCS'" ref="foliotable1" selectable select-mode="single"
             :sticky-header="true"
             style="max-height: calc(100vh - 380px); overflow-y: auto;"
             :sort-by.sync="sortBy"
             :sort-desc.sync="sortDesc"
             :sort-compare="customSortBand"
             :tbody-tr-class="rowClass"
             @row-selected="onRowSelected" sort-icon-left
             :items="visibleCellsRows()"
             :fields="visible_cells_fields">

      <template #cell(expiryDate)="row">

        <div v-if="!row.item.isInFolio">
          <span class="small-black-card"></span>
        </div>
        <div v-else>
          <span v-if="isValid(row.item.expiryDate)" class="small-black-card">{{ row.item.expiryDate }}</span>
          <span v-else class="small-black-card">{{ row.item.expiryDate }}<br>less than 30 days</span>

        </div>
      </template>

      <template #cell(name)="row">
        <span v-if="row.item.isInFolio" class="small-black-card">{{ row.item.name }} <br>in folio</span>
        <span v-if="!row.item.isInFolio" class="small-black-card">{{ row.item.name }}</span>
        <span v-if="!row.item.isInFolio && row.item.isSelected" class="small-blue-card">
          <br><a href='#' @click='switchSelected(row.item.id)'>selected</a>
        </span>
        <span v-if="!row.item.isInFolio && !row.item.isSelected" class="small-blue-card">
          <br><a href='#' @click='switchSelected(row.item.id)'>unselected</a>
        </span>
      </template>

      <template #cell(ai)="row">
<!--        {{row.item}}-->
<!--        {{getcoordinates(row.item)}}}-->
        <span class="small-black-card">
          <a target="_blank" :href="'https://win1hetz.bgeo.fi/web_demo/test_qns.html?lat=' + getcoordinates(row.item).lat + '&lon=' + getcoordinates(row.item).lon + '&z=' + (row.item.usageBand + 4)
">AI</a>
          </span>
      </template>


      <template #cell(band)="row">
        <span class="small-black-card">{{ bandName(row.item.usageBand) }}</span>
      </template>

      <template #cell(price)="row">
        <div v-if="!row.item.isInFolio">
          {{ getCellDisplayPrice(row.item.id) }}
        </div>
      </template>

      <!--      <template #cell(bandid)="row">-->
      <!--        <span class="small-gray-card">{{ cellById(row.item.id).usageBand }}</span>-->
      <!--      </template>-->

      <!--      <template #cell(price)="row">-->
      <!--        {{ getCellDisplayPrice(row.item.id) }}-->
      <!--      </template>-->

      <!--      <template #cell(selected)="row">-->
      <!--        <b-checkbox switch :checked="!row.item.disabled" @change=toggleCellInclusion(row.item.id)></b-checkbox>-->
      <!--      </template>-->
    </b-table>

    <b-table v-if="!orderPlaced && selectedType_UKHO === 'ADP'" ref="foliotableadp1" selectable select-mode="single"
             sticky-header=true
             sort-icon-left
             :items="visibleADPRows()"
             :fields="visible_publications_fields">

      <template #cell(name)="row">
        {{ row.item.name }}

        <span v-if="!row.item.disabled" class="small-blue-card">
          <br><a href='#' @click='switchSelectedADP(row.item.id)'>selected</a>
        </span>
        <span v-if="row.item.disabled" class="small-blue-card">
          <br><a href='#' @click='switchSelectedADP(row.item.id)'>unselected</a>
        </span>

      </template>

      <template #cell(price)="row">
        {{ getADPDisplayPrice(row.item.name) }}
      </template>

      <template #cell(selected)="row">
        <b-checkbox switch :checked="!row.item.disabled" @change=toggleADPInclusion(row.item.name)></b-checkbox>
      </template>
    </b-table>

    <b-table v-if="!orderPlaced && selectedType_UKHO === 'eNP'" ref="foliotableenp1" selectable select-mode="single"
             sticky-header="56vh"
             sort-icon-left
             :items="visibleENPRows()"
             :fields="visible_publications_fields">

      <template #cell(name)="row">
        {{ row.item.name }}

        <span v-if="!row.item.disabled" class="small-blue-card">
          <br><a href='#' @click='switchSelectedENP(row.item.id)'>selected</a>
        </span>
        <span v-if="row.item.disabled" class="small-blue-card">
          <br><a href='#' @click='switchSelectedENP(row.item.id)'>unselected</a>
        </span>

      </template>

      <template #cell(price)="row">
        {{ getENPDisplayPrice(row.item.id) }}
      </template>

      <template #cell(selected)="row">
        <b-checkbox switch :checked="!row.item.disabled" @change=toggleENPInclusion(row.item.id)></b-checkbox>
      </template>
    </b-table>

    <b-container v-if="orderPlaced" class="mt-2">
      <b-row>
        <b-col cols="8">
          <b-card no-body bg-variant="light" class="px-2 py-2">
            <span class="smt2s">
              Agreement: {{ deviceStore.getAgreementName }}<br>
              Ship: {{ userStore.getShipName }}
              <hr>
              <div v-if="deviceStore.getAgreementProvider === 'UKHO'">
                AVCS: {{ currentCartEnabledItemsLen }} items<br>
                AVCS period: {{ this.getDisplayPeriod() }} months<br>
                AVCS price: {{ getDisplayENCPrice() }}<br><br>

                ADP: {{ currentCartEnabledADPItemsLen }} items<br>
                ADP price: {{ getDisplayADPPrice() }}<br><br>

                eNP: {{ currentCartEnabledENPItemsLen }} items<br>
                eNP price: {{ getDisplayENPPrice() }}<br><br>
              </div>

              <div v-if="deviceStore.getAgreementProvider === 'Primar'">
                ENC: {{ currentCartEnabledItemsLen }} items<br>
                ENC period: {{ this.getDisplayPeriod() }} months<br>
                ENC price: {{ getDisplayTotalPrice() }} <br><br>
              </div>
                <b>Total price: {{ getDisplayTotalPrice() }}</b><br>
            </span>
          </b-card>
        </b-col>
        <b-col align-self="center">
          <b-button block size="sm" variant="success" @click="approveOrder()">Approve</b-button>
          <b-button block size="sm" variant="danger" @click="cancelOrder()">Cancel</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
  <!--  </b-overlay>-->
</template>

<script>
import {eventBus} from "@/main";
import * as utils from "@/utils/utils";
import {debounce} from "lodash"
import {myAxios, myAxiosRetry} from "@/services/myAxios";
import router from "@/router";
import {mapStores} from "pinia";
import {useRoutesStore} from "@/stores/routes";
import {useMomentaryStore} from "@/stores/momentary";
import {useDeviceStore} from "@/stores/device";
import {useUserStore} from "@/stores/user";
import {loadMSSChartsByRoute, sendPurchaseRequest, sendUpdateRequest} from "@/helpers/api";
import logbook from "@/views/logbook/index.vue";
import {useCartStore} from "@/stores/cart";
import {useCatalogsStore} from "@/stores/catalogs";
import dayjs from "dayjs";

export default {
  name: "OrdersByRouteTable",
  // props: ['map'],
  data() {
    return {
      sortBy: 'band',
      sortDesc: false,
      // sortBy: 'bandid',
      filterSelectAll: false,
      filterOVR: false,
      filterGNR: false,
      filterCST: false,
      filterAPR: false,
      filterHRB: false,
      filterBRG: false,

      preparingCells: false,
      orderPlaced: false,
      viewParent: this.$parent.$parent.$parent.$parent,
      selectedType_UKHO: 'AVCS',
      selectedType_Primar: 'ENC',
      selectedPeriod: '4',
      priceCalculating: false,
      openCellsList: false,
      cellList: ""
    }
  },
  mounted() {
    // this.prepareCells()
    eventBus.$off('make-row-active')
    eventBus.$off('recalculate-total-price')
    eventBus.$off('recalculate-enp-price')
    eventBus.$off('recalculate-adp-price')

    eventBus.$off('highlight-onroute-cell')

    eventBus.$on('highlight-onroute-cell', (cellName) => {
      // alert(cellName)
      // this.switchSelected(cellName)
      this.onCellClicked(cellName)
      // this.highlightCell(cellName);
    })

    eventBus.$on('make-row-active', (id) => {
      this.onCellClicked(id)
    })

    eventBus.$on('recalculate-total-price', () => {
      this.recalculateCountTotalPriceByError()
    })

    eventBus.$on('recalculate-enp-price', () => {
      this.recalculateCountENPPriceByError()
    })

    eventBus.$on('recalculate-adp-price', () => {
      this.recalculateCountADPPriceByError()
    })

    // Listen for cell selection from map
    eventBus.$on('map-cell-selected', (cellId) => {
      // Find and select the row in table
      const rowIndex = this.visibleCellsRows().findIndex(row => row.id === cellId);
      if (rowIndex >= 0) {
        this.$refs.foliotable1.selectRow(rowIndex);
      }
    });

    // Listen for cell deselection from map
    eventBus.$on('map-cell-deselected', (cellId) => {
      // Find and deselect the row in table
      const rowIndex = this.visibleCellsRows().findIndex(row => row.id === cellId);
      if (rowIndex >= 0) {
        this.$refs.foliotable1.unselectRow(rowIndex);
      }
    });

    // this.getHistory().then(resp => {
    //   this.deviceStore.addOrdersHistoryAVCS(resp.avcsCells)
    // })7
  },
  watch: {
    'cartStore.getCartLength'(newVal) {
      this.debounceCountTotalPrice()
      this.debounceCheckOrdersHistory()
    },
    'cartStore.getENPCartLength'(newVal) {
      this.debounceCountENPPrice()
      // TODO this.debounceCheckOrdersHistory()
    },
    'cartStore.getADPCartLength'(newVal) {
      this.debounceCountADPPrice()
      // TODO this.debounceCheckOrdersHistory()
    },
    selectedPeriod() {
      this.debounceCountTotalPrice()
      // this.debounceCountENPPrice()
      // this.debounceCountADPPrice()
    },
    selectedType_UKHO(newVal) {
      console.log(newVal)
    }

  },
  computed: {
    ...mapStores(useRoutesStore, useMomentaryStore, useUserStore, useDeviceStore, useCartStore, useCatalogsStore),
    // currentCartLen() {
    //   return this.$store.state.current.cart.s63cells.length
    // },
    currentCartEnabledItemsLen() {
      // const enabledItems = this.$store.state.current.cart.s63cells.filter(function (e) {
      const enabledItems = this.cartStore.getCart.filter(function (e) {
        return !e.disabled;
      })
      return enabledItems.length
    },
    currentCartEnabledENPItemsLen() {
      const enabledItems = this.cartStore.getENPCart.filter(function (e) {
        return !e.disabled;
      })
      return enabledItems.length
    },
    currentCartEnabledADPItemsLen() {
      const enabledItems = this.cartStore.getADPCart.filter(function (e) {
        return !e.disabled;
      })
      return enabledItems.length
    },
    debounceCountTotalPrice() {
      return debounce(this.countTotalPrice, 500);
    },
    debounceCountENPPrice() {
      return debounce(this.countENPPrice, 500);
    },
    debounceCountADPPrice() {
      return debounce(this.countADPPrice, 500);
    },
    recalculateCountTotalPriceByError() {
      return debounce(this.countTotalPrice, 2000);
    },
    recalculateCountENPPriceByError() {
      return debounce(this.countENPPrice, 2000);
    },
    recalculateCountADPPriceByError() {
      return debounce(this.countADPPrice, 2000);
    },
    debounceCheckOrdersHistory() {
      return debounce(this.checkOrdersHistory, 500);
    },
    visible_cells_fields() {
      return [
        // {key: 'selected', label: 'Selected', tdClass: 'small-black', thClass: 'small-gray'},
        // {key: 'id', label: 'ID', tdClass: 'small-black', sortable: true, thClass: 'small-gray'},
        // {key: 'bandid', label: 'Band ID', tdClass: 'small-black', thClass: 'small-gray', sortable: true},
        {key: 'name', label: 'Name', sortable: true, tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'band', label: 'Band', sortable: true, tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'expiryDate', label: 'Valid till', sortable: false, tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'price', label: 'Price', sortable: false, tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'ai', label: 'AI', sortable: false, tdClass: 'small-black', thClass: 'small-gray'},
      ]
    },
    visible_publications_fields() {
      return [
        {key: 'name', label: 'Name', sortable: true, tdClass: 'small-black', thClass: 'small-gray'},
        // {key: 'expiryDate', label: 'Till', sortable: true, tdClass: 'small-black', thClass: 'small-gray'},

        // {key: 'selected', label: 'Selected', tdClass: 'small-black', thClass: 'small-gray'},
        // {key: 'name', label: 'ID', tdClass: 'small-black', sortable: true, thClass: 'small-gray'},
        {key: 'price', label: 'Price', tdClass: 'small-black', thClass: 'small-gray'},
      ]
    },
  },
  methods: {
    customSortBand(a, b, isDesc) {
      // console.log("sort ", a, b)

      const bandA = a.usageBand;
      const bandB = b.usageBand;
      if (bandA < bandB) return isDesc ? 1 : -1;
      if (bandA > bandB) return isDesc ? -1 : 1;
      return 0;
    },
    getcoordinates(data) {
      const lat = (data.south + data.north) / 2;
      const lon = (data.west + data.east) / 2;
      return { lat, lon };
    },
    clearFlags() {
      this.filterOVR = false
      this.filterGNR = false
      this.filterCST = false
      this.filterAPR = false
      this.filterHRB = false
      this.filterBRG = false
    },
    importClipboard() {
      this.openCellsList = true;
    },

    isValid(date) {
      // const inputDate = new Date(date);
      // const currentDate = new Date();
      // const threeDaysAgo = new Date(currentDate.setDate(currentDate.getDate() - 30));
      // console.log("aaa", inputDate, currentDate, threeDaysAgo)

      let d1 = dayjs(date)
      let d2 = dayjs()
      let dd = d1.diff(d2, "day")

      console.log("aaa", dd)

      return dd > 30;
    },

    rowClass(item, type) {
      if (item.isSelected && !item.isInFolio)
        return 'table-selected'
      else if (!item.isSelected && !item.isInFolio)
        return 'table-deselected'

      return 'table-infolio'

      // let allWarnings = this.routeDangers.filter(item => item.isDanger === true)
      // let legWarnings = allWarnings.filter(item => item.legIndex === item.id)
      // if (legWarnings.length > 0)
      //   return 'table-warning'


      // else if (item.isDanger)
      //   return 'table-warning'

      // if (!item.isDanger || type !== 'row') return
      // if (item.isDanger) return 'table-danger'

      // let rowClass = ''
      // let routeLegs = this.routesStore.routeLegs
      // if (routeLegs[item.legId].safety === 2)
      //   rowClass = 'table-danger'
      //
      // return rowClass


    },

    primarMapBoosterByList() {
      this.openCellsList = false;
      let cells = this.cellList.split(/[\n,]/);

      cells.forEach((item) => {
        eventBus.$emit('a1', item.trim())
        // this.map.onCellClicked(item.trim());
      });
      eventBus.$emit('a4');
      eventBus.$emit('a41');
      // this.map.dispatchSelectedCells();
    },

    // getHistory() {
    //   return new Promise(resolve => {
    //         if (!this.deviceStore.getDeviceID) {
    //           resolve()
    //           return
    //         }
    //
    //         if (this.deviceStore.getAgreementProvider === 'UKHO') {
    //           let URL = "/api/v1/mss/summary/deviceid/" + this.deviceStore.getDeviceID;
    //           myAxios.get(URL).then(resp => {
    //             resolve(resp.data);
    //           }).catch((error) => {
    //             console.log(error)
    //           }).finally(() => {
    //           })
    //         }
    //
    //         if (this.deviceStore.getAgreementProvider === 'Primar') {
    //           let URL = "/api/v1/orders/deviceid/" + this.deviceStore.getDeviceID;
    //           myAxios.get(URL).then(resp => {
    //             console.log("aaaa", resp);
    //             resolve(resp.data);
    //           }).catch((error) => {
    //             console.log(error)
    //           }).finally(() => {
    //           })
    //         }
    //       }
    //   )
    // },

    purchasedEarly(id) {
      const purchased = this.deviceStore.orderedData.ukho.avcs.filter(function (e) {
        return e.name === id;
      })

      return purchased.length > 0
    },

    getCellValidDate(id) {
      const purchased = this.deviceStore.orderedData.ukho.avcs.filter(function (e) {
        return e.name === id;
      })
      return purchased[0]['expiryDate']
    },

    getCurrency() {
      return this.deviceStore.getAgreementCurrency
    },

    checkOrdersHistory() {
      let isNeedRecalculate = false
      this.cartStore.getCart.forEach(item => {
        if (!item.disabled && this.purchasedEarly(item.id)) {
          this.toggleCellInclusion(item.id, 'off')
          isNeedRecalculate = true
        }
      })

      if (isNeedRecalculate) {
        this.debounceCountTotalPrice()
      }
    },

    countTotalPrice() {
      // alert(1)

      if (this.cartStore.getCartLength === 0) {
        this.priceCalculating = false;
        return
      }

      this.priceCalculating = true;

      // hide price while change period
      // this.$store.commit("current/setTotalPriceByPrimar",
      //     {usd: '?', rub: '?', rubineuro: '?'}
      // );

      // let agreementID = this.hasValidAgreement ? this.$store.state.proto.agreement.id : "-1"
      // let deviceID = this.$store.state.proto.order.device_id
      // let appType = this.$store.state.proto.order.application_id
      let deviceID = this.deviceStore.getDeviceID
      let agreement = this.deviceStore.getAgreementID
      let appType = this.deviceStore.getAgreementApplicationType
      let period = this.selectedPeriod

      console.log("aaaa", deviceID, agreement, appType)


      // if (this.selectedPeriod === '6') {
      //   period = '1'
      // } else if (this.selectedPeriod === '12') {
      //   period = '0'
      // }

      // let cart = this.cartStore.getCart.filter(item => !item.disabled)

      this.cartStore.calculatePrice({
        products: this.cartStore.getCart,
        periodBand: period,
        agreementID: agreement,
        deviceID: deviceID,
        appType: appType
      }).then(() => {
        this.priceCalculating = false;
      })

      //   this.$store.dispatch("current/getTotalPrice", {
      //     products: this.cartStore.getCart,
      //     periodBand: period,
      //     agreementID: agreement,
      //     deviceID: deviceID,
      //     appType: appType
      //   }).then(() => {
      //     this.priceCalculating = false;
      //   })
    },

    countENPPrice() {

      // alert(1)
      // alert(this.cartStore.getENPCartLength)

      if (this.cartStore.getENPCartLength === 0)
        return

      this.priceCalculating = true;
      let deviceID = this.deviceStore.getDeviceID
      let agreement = this.deviceStore.getAgreementID
      let appType = this.deviceStore.getAgreementApplicationType
      let period = this.selectedPeriod

      this.cartStore.calculateENPPrice({
        products: this.cartStore.getENPCart,
        periodBand: period,
        agreementID: agreement,
        deviceID: deviceID,
        appType: appType
      }).then(() => {
        this.priceCalculating = false;
      })
    },

    countADPPrice() {
      if (this.cartStore.getADPCartLength === 0)
        return

      this.priceCalculating = true;
      let deviceID = this.deviceStore.getDeviceID
      let agreement = this.deviceStore.getAgreementID
      let appType = this.deviceStore.getAgreementApplicationType
      let period = this.selectedPeriod

      this.cartStore.calculateADPPrice({
        products: this.cartStore.getADPCart,
        periodBand: period,
        agreementID: agreement,
        deviceID: deviceID,
        appType: appType
      }).then(() => {
        this.priceCalculating = false;
      })
    },

    toggleBands(type) {
      if (type === 'on' || type === 'off') {
        this.filterSelectAll = !this.filterSelectAll

        this.momentaryStore.getCellsByRoute.forEach(item => {
          if (!item.isInFolio) {
            item.isSelected = !this.filterSelectAll
          }
        })

        this.cartStore.getCart.forEach(item => {
          this.toggleCellInclusion(item.id, type)
        })
        this.cartStore.getENPCart.forEach(item => {
          this.toggleENPInclusion(item.id, type)
        })
      } else {
        this.cartStore.getADPCart.forEach(item => {
          this.toggleADPInclusion(item.id, type)
        })
      }
      this.debounceCountTotalPrice()
      this.debounceCountENPPrice()
      this.debounceCountADPPrice()
    },

    placeOrder() {
      this.orderPlaced = true
    },

    updateOrder() {
      this.orderPlaced = true
    },

    cancelOrder() {
      this.orderPlaced = false
    },

    onCellClicked(id) {
      if (this.$refs.foliotable1) {
        let x = this.$refs.foliotable1.computedItems;
        let row = this.$refs.foliotable1.computedItems.findIndex(x => x.id === id);
        console.log(id, row, this.items);

        if (row >= 0) {
          this.$refs.foliotable1.selectRow(row);

          this.$nextTick(() => {
            const rowElement = this.$refs.foliotable1.$el.querySelector(`tbody tr:nth-child(${row + 1})`);
            if (rowElement) {
              rowElement.scrollIntoView({behavior: 'smooth', block: 'center'});
            }
          });
        }
      }

      // let cell = this.cartStore.getCart.find(o => o.id === id)
      // if (cell) {
      //   // this.toggleCellInclusion(id, 'switch')
      //   cell.disabled = false
      //   this.debounceCountTotalPrice()
      // }
    },

    approveOrder(payment_method = 'agreement') {
      // TODO make overlay
      this.preparingCells = true

      // alert(this.viewParent.editMode)

      let enabledItems = []
      this.enabledCartItems().forEach(item => {
        let enabledItem = {
          id: item.id,
          primarOrderTypeId: this.selectedPeriod,
          price: String(this.cartStore.getCellPrice(item.id)),
          monthCount: this.getDisplayPeriod(),
          type: this.deviceStore.getAgreementProvider === 'UKHO' ? 'AVCS maps' : 's63 maps'
        }
        enabledItems.push(enabledItem)
      })

      this.enabledADPCartItems().forEach(item => {
        let enabledItem = {
          id: item.name,
          price: String(this.cartStore.getADPPrice(item.name)),
          type: 'ADP'
        }
        enabledItems.push(enabledItem)
      })

      this.enabledENPCartItems().forEach(item => {
        let enabledItem = {
          id: item.id,
          price: String(this.cartStore.getENPPrice(item.id)),
          type: 'ENP'
        }
        enabledItems.push(enabledItem)
      })


      // TODO make comment
      let comment = this.deviceStore.getAgreementProvider === 'UKHO' ? 'AVCS purchase' : 'Primar purchase'
      let data = {
        deviceId: this.deviceStore.getDeviceID,
        price: String(this.cartStore.getTotalPrice),
        items: enabledItems,
        comment: comment,
        paymentMethod: "agreement",
        currency: this.deviceStore.getAgreementCurrency,
        agreementId: this.deviceStore.getAgreementID,
        applicationTypeId: this.deviceStore.getAgreementApplicationType,
        var: this.deviceStore.getAgreementProvider === 'UKHO' ? 'mss' : 'primar',
        domain: "ecdis-link.com"
      }

      if (this.viewParent.editMode) {
        sendUpdateRequest(data.items, this.viewParent.orderId).then(resp => {
          router.push({name: "transactions", params: {qrevent: 'reload-transactions'}})
        }).catch(err => {
          // TODO remove after network errors fix
          router.push({name: "transactions", params: {qrevent: 'reload-transactions'}})
        })
      } else {
        sendPurchaseRequest(data).then(resp => {
          router.push({name: "transactions", params: {qrevent: 'reload-transactions'}})
        }).catch(err => {
          // TODO remove after network errors fix
          router.push({name: "transactions", params: {qrevent: 'reload-transactions'}})
        })
      }

    },

    getENPDisplayPrice(id) {
      let price = this.cartStore.getENPPrice(id)
      if (price !== '?') {
        price = price + '£'
      }
      return price
    },

    getADPDisplayPrice(id) {
      let price = this.cartStore.getADPPrice(id)
      if (price !== '?') {
        price = price + '£'
      }
      return price
    },

    getCellDisplayPrice(id) {
      let price = this.cartStore.getCellPrice(id)
      if (price !== '?') {
        if (this.getCurrency() === 'USD')
          price = '$' + price
        if (this.getCurrency() === 'RUB')
          price = price + '₽'
      }
      return price
    },

    getDisplayENCPrice() {
      let price = this.cartStore.getTotalPrice
      if (price !== 0) {
        if (this.getCurrency() === 'USD')
          price = '$' + price
        if (this.getCurrency() === 'RUB')
          price = price + '₽'
      }
      return price
    },

    getDisplayENPPrice() {
      let enpPrice = this.cartStore.getENPTotalPrice
      return enpPrice.toFixed(2) + '£'
    },

    getDisplayADPPrice() {
      let adpPrice = this.cartStore.getADPTotalPrice
      return adpPrice.toFixed(2) + '£'
    },


    getDisplayTotalPrice() {
      let price = this.cartStore.getTotalPrice
      let enpPrice = this.cartStore.getENPTotalPrice
      let adpPrice = this.cartStore.getADPTotalPrice
      if (price !== 0) {
        if (this.getCurrency() === 'USD')
          price = '$' + price
        if (this.getCurrency() === 'RUB')
          price = price + '₽'
      }

      if (adpPrice > 0 || enpPrice > 0)
        price = price + ' + ' + Number(adpPrice + enpPrice).toFixed(2) + '£'

      return price
    },

    getDisplayPeriod() {
      if (this.selectedPeriod === '4')
        return '3'
      if (this.selectedPeriod === '1')
        return '6'
      if (this.selectedPeriod === '0')
        return '12'
    },

    toggleCellInclusion(id, type = 'switch') {
      // alert('a1')
      let status = this.cartStore.updateCell({id: id, type: type})

      if (status) {
        eventBus.$emit('a1', id)
        eventBus.$emit('a11', id)
      } else {
        eventBus.$emit('a2', id)
        eventBus.$emit('a22', id)
      }

      if (type === 'switch')
        this.countTotalPrice()
    },


    toggleENPInclusion(id, type = 'switch') {
      let status = this.cartStore.updateENP({id: id, type: type})

      // if (status) {
      //   eventBus.$emit('a1', id)
      // } else {
      //   eventBus.$emit('a2', id)
      // }

      if (type === 'switch')
        this.countENPPrice()
    },

    toggleADPInclusion(id, type = 'switch') {
      let status = this.cartStore.updateADP({id: id, type: type})

      // if (status) {
      //   eventBus.$emit('a1', id)
      // } else {
      //   eventBus.$emit('a2', id)
      // }

      if (type === 'switch')
        this.countADPPrice()
    },

    bandName(code) {
      return utils.getBandName(code);
    },

    cellById(id) {
      return this.catalogsStore.getItemsById(id)
      // return this.$store.getters.cellById(id)
    },

    onRowSelected(items) {
      if (items.length > 0) {
        const selectedId = items[0].name;
        this.$parent.$parent.$parent.$parent.showOrdersByRouteCell(selectedId);
        // this.$parent.$parent.$parent.$parent.selectGraph(selectedId);
        this.$parent.$parent.$parent.$parent.updateGraph(selectedId, false, true);
        // this.$refs['folio-map'].aaa1(selectedId);

        //eventBus.$emit('folio-row-selected', selectedId);
      }
    },

    onRowUnselected(items) {
      if (items.length > 0) {
        const unselectedId = items[0].id;
        eventBus.$emit('table-row-deselected', unselectedId);
      }
    },

    sortBands(a, b) {
      return this.cellById(a.id).usageBand - this.cellById(b.id).usageBand
    },

    visibleCellsRows() {
      return this.momentaryStore.getCellsByRoute


      // let allChartsByRoute = this.momentaryStore.getChartsByRoute;
      // let allFolioCharts = this.deviceStore.orderedData.ukho.avcs;
      //
      // let currentDate = new Date();
      // let threeDaysAgo = new Date(currentDate.setDate(currentDate.getDate() - 3));
      // let fiveDaysLater = new Date(currentDate.setDate(currentDate.getDate() + 5));
      //
      // let newArray = allChartsByRoute.map(chart => {
      //   let folioChart = allFolioCharts.find(folio => folio.name === chart);
      //   return {
      //     name: chart,
      //     title: folioChart ? folioChart.title : null,
      //     expiryDate: folioChart ? folioChart.expiryDate : null,
      //     isExpired: folioChart ? new Date(folioChart.expiryDate) < threeDaysAgo : false,
      //     isInFolio: !!folioChart
      //   };
      // });
      //
      // let newArrayFiltered = newArray.filter(chart => chart.expiryDate === null || new Date(chart.expiryDate) > fiveDaysLater);
      //
      // return newArrayFiltered

    },

    visibleADPRows() {
      return this.cartStore.adpCart
    },

    visibleENPRows() {
      return this.cartStore.enpCart
    },

    enabledADPCartItems() {
      const enabledItems = this.cartStore.getADPCart.filter(function (e) {
        return !e.disabled
      })
      return enabledItems.slice().reverse()
    },

    enabledENPCartItems() {
      const enabledItems = this.cartStore.getENPCart.filter(function (e) {
        return !e.disabled
      })
      return enabledItems.slice().reverse()
    },

    enabledCartItems() {
      const enabledItems = this.cartStore.getCart.filter(function (e) {
        return !e.disabled
      })
      return enabledItems.slice().reverse()
    },

    switchBand(band) {
      let newFlag
      if (band === 0) {
        this.filterSelectAll = !this.filterSelectAll
        newFlag = this.filterSelectAll ? 'off' : 'on'

        if (newFlag === 'off') {
          this.filterOVR = true
          this.filterGNR = true
          this.filterCST = true
          this.filterAPR = true
          this.filterHRB = true
          this.filterBRG = true
        } else {
          this.filterOVR = false
          this.filterGNR = false
          this.filterCST = false
          this.filterAPR = false
          this.filterHRB = false
          this.filterBRG = false
        }

        this.momentaryStore.getCellsByRoute.forEach(cell => {
          if (!cell.isInFolio) {
            this.switchSelected(cell.id, newFlag, false)
          }
        });
      } else {

        if (band === 1) {
          this.filterOVR = !this.filterOVR
          newFlag = this.filterOVR ? 'off' : 'on'
        }

        if (band === 2) {
          this.filterGNR = !this.filterGNR
          newFlag = this.filterGNR ? 'off' : 'on'
        }

        if (band === 3) {
          this.filterCST = !this.filterCST
          newFlag = this.filterCST ? 'off' : 'on'
        }

        if (band === 4) {
          this.filterAPR = !this.filterAPR
          newFlag = this.filterAPR ? 'off' : 'on'
        }

        if (band === 5) {
          this.filterHRB = !this.filterHRB
          newFlag = this.filterHRB ? 'off' : 'on'
        }

        if (band === 6) {
          this.filterBRG = !this.filterBRG
          newFlag = this.filterBRG ? 'off' : 'on'
        }

        // this.$parent.$parent.$parent.$parent.showCategoryOnMap(band, newFlag === 'off');

        this.filterSelectAll = newFlag === 'off' ? true : false

          this.momentaryStore.getCellsByRoute.forEach(cell => {
            if (!cell.isInFolio && cell.usageBand === band) {
              this.switchSelected(cell.id, newFlag, false)
            }
          });
      }


      this.countTotalPrice()
    },

    switchSelectedENP(id, type = 'switch', isCalculate = true) {
      let objIndex = this.cartStore.enpCart.findIndex(item => item.id === id);

      if (objIndex !== -1) {
        if (type === 'switch')
          this.cartStore.updateENP({id: id, type: 'switch'});
        else if (type === 'on')
          this.cartStore.updateENP({id: id, type: 'on'});
        else if (type === 'off')
          this.cartStore.updateENP({id: id, type: 'off'});

        if (isCalculate)
          this.countENPPrice();
      }
    },

    switchSelectedADP(id, type = 'switch', isCalculate = true) {
      let objIndex = this.cartStore.adpCart.findIndex(item => item.id === id);

      console.log(id)
      console.log(objIndex)

      if (objIndex !== -1) {
        if (type === 'switch')
          this.cartStore.updateADP({id: id, type: 'switch'});
        else if (type === 'on')
          this.cartStore.updateADP({id: id, type: 'on'});
        else if (type === 'off')
          this.cartStore.updateADP({id: id, type: 'off'});

        if (isCalculate)
          this.countADPPrice();
      }
    },

    switchSelected(id, type = 'switch', isCalculate = true) {
      // alert("n1")

      // alert('a3')
      console.log("cccc 1", id)
      let cell = this.momentaryStore.getCellsByRoute.find(cell => cell.id === id);
      if (cell) {
        if (type === 'switch')
          cell.isSelected = !cell.isSelected;

        if (type === 'on')
          cell.isSelected = true;

        if (type === 'off')
          cell.isSelected = false;


        this.$parent.$parent.$parent.$parent.switchOrdersByRouteCell(id, cell.isSelected);
        eventBus.$emit('re-contextmenu1')
      }

      this.cartStore.getCart.forEach(cartItem => {
        const matchingItem = this.momentaryStore.getCellsByRoute.find(routeItem => routeItem.id === cartItem.id);
        if (matchingItem) {
          cartItem.disabled = !matchingItem.isSelected;
        }
      });

      //let items = list.filter(item => item.isSelected)

      if (isCalculate)
        this.countTotalPrice()

      this.$parent.$parent.$parent.$parent.redraw();

      // let request, response, lat, lon
      //
      // request = {
      //   provider: 'UKHO',           // 'ALL', 'Primar', 'UKHO'
      //   route: [
      //     {lat, lon},
      //   ]
      // }
      //
      // response = {
      //   enc: {
      //     overview: [
      //       {
      //         name: 'NOA13000',       // имя карты или публикации
      //         wp_id: 2,               // номер точки
      //         enter_distance: 300,    // дистация от старта маршрута до точки входа в геометрию
      //         exit_distance: 800,     // дистанция от старта маршрута до точки выхода из геометрии
      //         enter_coordinates: {},  // опционально
      //         exit_coordinates: {}    // опционально
      //       },
      //       {
      //         name: 'NOA13001',
      //         wp_id: 3,
      //         enter_distance: 200,
      //         exit_distance: 300,
      //         enter_coordinates: {},  // опционально
      //         exit_coordinates: {}    // опционально
      //       }
      //     ],
      //     general: []
      //     // ...
      //   },
      //   avcs: {},
      //   adp: {},
      //   enp: {}
      // }


      this.$parent.$parent.$parent.$parent.updateGraph(id, cell.isSelected);
    },

    highlightCell(id) {
      eventBus.$emit('make-cell-active', id)
    },

    showCellOnMap(id, items) {
      eventBus.$emit('pane2cell', {id: id, items: items, color: 'green'})
      const url = 'https://win1hetz.bgeo.fi:5005/' + id + '/{z}/{x}/{y}'
      eventBus.$emit('show-cell-on-map', url)
    },
  }
}
</script>
<style>
button:hover {
  color: black;
  background-color: white;
  outline: none !important;
}

.table-selected,
.table-selected > th,
.table-selected > td {
  background-color: rgba(143, 207, 255, 0.12);
}

.table-deselected,
.table-deselected > th,
.table-deselected > td {
  background-color: rgba(255, 219, 148, 0.12);
}

.table-infolio,
.table-infolio > th,
.table-infolio > td {
  background-color: rgba(178, 255, 171, 0.12);
}
</style>