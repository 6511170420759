<template>
  <div>
        <div id="mymap" ref="mm" :style="{ height: height + 'px'}"></div>
  </div>
</template>

<script>
export default {
  props: ["map", "height"],

  mounted() {
    this.map.init(this.$refs.mm);
  },
  methods: {
  }
}
</script>
<style scoped>
  #mymap{
    z-index: 1;
  }
</style>
