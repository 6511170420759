import {defineStore} from 'pinia'

export const useEditorStore = defineStore('editor', {
    state: () => ({
        patches: [],
        links: [],
        selectedForExport: []
    }),
    getters: {
        getPatches: (state) => state.patches,
        getLinks: (state) => state.links,
        getSelectedForExport: (state) => state.selectedForExport
    },
    actions: {
        addPatch(patch) {
            this.patches.push(patch)
        },
        addLink(link) {
            this.links.push(link)
        },
        removePatch(patchId) {
            this.patches = this.patches.filter(patch => patch.id !== patchId)
        },
        removeLink(linkId) {
            this.links = this.links.filter(link => link.id !== linkId)
        },
        addToExportSelection(patchId) {
            if (!this.selectedForExport.includes(patchId)) {
                this.selectedForExport.push(patchId);
            }
        },
        removeFromExportSelection(patchId) {
            this.selectedForExport = this.selectedForExport.filter(id => id !== patchId);
        },
        clearExportSelection() {
            this.selectedForExport = [];
        },
        reset() {
            this.patches = []
        }
    },
    persist: true
})
