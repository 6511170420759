import Login from "@/views/Login.vue";
import Deliveries from "@/views/deliveries/index.vue";
import Routes from "@/views/routes";
import Ecdis from "@/views/ecdis";
import Logbook from "@/views/logbook"
import Connect from "@/views/connect"
import Test from "@/views/test/Test.vue";
import Test1 from "@/views/test1/Test1.vue";
import Editor from "@/views/editor";
import Registration from "@/views/Registration.vue";

import PaymentStatus from "@/views/PaymentStatus";
import {
    ActiveTab
} from "@/utils/config";
import Loader from "@/views/paymentBuffers/Loader";
import Mobile from "@/views/Mobile";
import {IS_GETENC, IS_NP} from "@/utils/build-config";
import Ships from "@/views/ships"

import Transactions from "@/views/transactions";
import Agreement from "@/views/agreement";

export default [{
        id: 0,
        path: '/',
        name: 'home',
        redirect: IS_GETENC? 'routes' : 'devices',
        meta: {
            guest: false
        }
    },
    {
        id: 1,
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            guest: true
        },
        props: (route) => ({ mconnect: route.query.mconnect, xxx: route.name })
    },
    {
        id: 3,
        path: '/deliveries',
        name: 'deliveries',
        component: Deliveries,
        meta: {
            guest: false,
        },
    },
    // {
    //     id: 4,
    //     path: '/logbook',
    //     name: 'logbook',
    //     component: logbook,
    //     meta: {
    //         guest: false,
    //     },
    // },
    {
        id: 5,
        path: '/payment-status/:status',
        name: 'payment-status',
        component: PaymentStatus
    },
    {
        id: 6,
        component: Loader,
        path: "/loader",
        name: 'loader',
        // props: true
    },
    {
        id: 7,
        component: Mobile,
        path: "/mobile",
        name: 'mobile',
        meta: {
            guest: true
        },
        props: (route) => ({ username: route.query.u, password: route.query.p })
    },
    {
        id: 8,
        path: '/routes',
        name: 'routes',
        components: {
            default: Routes
        },
        meta: {
            guest: false,
            id: ActiveTab.ROUTES
        },
    },
    {
        id: 9,
        path: '/ecdis',
        name: 'ecdis',
        components: {
            default: Ecdis
        },
        meta: {
            guest: false,
        },
    },
    // {
    //     id: 10,
    //     path: '/viewer',
    //     name: 'viewer',
    //     components: {
    //         default: Viewer
    //     },
    //     meta: {
    //         guest: true,
    //     },
    // },
    // {
    //     id: 11,
    //     path: '/distributor',
    //     name: 'distributor',
    //     component: Distributor,
    //     meta: {
    //         guest: false,
    //         id: ActiveTab.DISTRIBUTOR
    //     },
    // },
    {
        id: 12,
        path: '/transactions',
        name: 'transactions',
        component: Transactions,
        meta: {
            guest: false,
            id: ActiveTab.TRANSACTIONS
        },
    },
    // {
    //     id: 13,
    //     component: Pdf,
    //     path: "/pdf",
    //     name: 'pdf',
    //     meta: {
    //         guest: true
    //     },
    // },
    {
        id: 14,
        component: Ships,
        path: "/ships",
        name: 'ships',
        meta: {
            guest: false
        },
    },
    {
        id: 16,
        component: Agreement,
        path: "/agreement",
        name: 'agreement',
        meta: {
            guest: false
        },
    },
    {
        id: 17,
        component: Logbook,
        path: "/logbook",
        name: 'logbook',
        meta: {
            guest: false
        },
    },
    {
        id: 18,
        component: Editor,
        path: "/editor",
        name: 'editor',
        meta: {
            guest: false
        },
    },
    {
        id: 22,
        component: Registration,
        path: "/reg",
        name: 'reg',
        meta: {
            guest: false
        },
    },
    {
        id: 20,
        component: Connect,
        path: "/connect",
        name: 'connect',
        meta: {
            guest: false
        },
    },
    {
        id: 100,
        component: Test,
        path: "/test",
        name: 'test',
        meta: {
            guest: true
        },
    },
    {
        id: 110,
        component: Test1,
        path: "/test1",
        name: 'test1',
        meta: {
            guest: true
        },
    },
]