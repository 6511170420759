<!-- src/components/LineDetailsDialog.vue -->
<template>
  <b-modal id="line-details-modal" title="Path Details" @hidden="onCancel" :visible="show" hide-footer>
    <b-form @submit.prevent="onSubmit">
      <b-form-group label="Name:" label-for="name-input">
        <b-form-input
            id="name-input"
            v-model="form.name"
            placeholder="Enter line name"
            required
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Depth (meters):" label-for="depth-input">
        <b-form-input
            id="depth-input"
            v-model.number="form.depth"
            type="number"
            min="0"
            step="0.1"
            required
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Status:" label-for="status-select">
        <b-form-select
            id="status-select"
            v-model="form.status"
            :options="statusOptions"
            required
        ></b-form-select>
      </b-form-group>

      <b-form-group label="Type:" label-for="type-select">
        <b-form-select
            id="type-select"
            v-model="form.type"
            :options="typeOptions"
            required
        ></b-form-select>
      </b-form-group>

      <b-form-group label="Source:" label-for="source-select">
        <b-form-select
            id="source-select"
            v-model="form.source"
            :options="sourceOptions"
            required
        ></b-form-select>
      </b-form-group>

      <div class="d-flex justify-content-end mt-3">
        <b-button variant="secondary" class="mr-2" @click="onCancel">Cancel</b-button>
        <b-button variant="primary" type="submit">Save</b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
export default {
  props: {
    show: Boolean,
    initialValues: {
      type: Object,
      default: () => ({
        name: '',
        depth: 10,
        status: 'medium',
        type: 'coastal',
        source: 'map'
      })
    }
  },
  data() {
    return {
      form: { ...this.initialValues },
      statusOptions: [
        { value: 'important', text: 'Important' },
        { value: 'medium', text: 'Medium' },
        { value: 'unimportant', text: 'Unimportant' }
      ],
      typeOptions: [
        { value: 'approach', text: 'Approach' },
        { value: 'coastal', text: 'Coastal' },
        { value: 'marine', text: 'Marine' },
        { value: 'ocean', text: 'Ocean' },
        { value: 'river', text: 'River' },
        { value: 'channel', text: 'Channel' },
        { value: 'gateway', text: 'Gateway' }
      ],
      sourceOptions: [
        { value: 'map', text: 'Map' },
        { value: 'expert', text: 'Expert' }
      ]
    };
  },
  watch: {
    initialValues: {
      handler(newVal) {
        this.form = { ...newVal };
      },
      deep: true
    }
  },
  methods: {
    onSubmit() {
      this.$emit('submit', { ...this.form });
    },
    onCancel() {
      this.$emit('cancel');
    }
  }
}
</script>