<template>
  <div>
    <!--    {{routesStore.getShipWaypointsCount }}-->
    <!--    BBB-->
    <!--&lt;!&ndash;    {{$refs['passage-plan-table'] ? 'a' : 'b'}}&ndash;&gt;-->
    <!--&lt;!&ndash;    {{isUKCReady() }}&ndash;&gt;-->
    <!--    {{momentaryStore.isUKCLoaded }} AAAA-->
    <!--    {{$refs['passage-plan-table']}}-->
    <!--    {{$refs['passage-plan-table']?.isUKCReady ?? 'no'}}-->
    <b-modal v-model="show2D" title="UKC Calculation" @ok="okPressed" @cancel="cancelPressed">
      <span class='listsmall'>Route name:</span> {{ routesStore.getRouteName }} (<a href="#" @click="changeSettings()">change</a>)<br>
      <span class='listsmall'>NoGo:</span> {{ routesStore.getShipNoGo }} m (<a href="#"
                                                                               @click="changeSettings()">change</a>)<br>
      <span class='listsmall'>Draught:</span> {{ routesStore.getShipDraught }} m (<a href="#" @click="changeSettings()">change</a>)<br>
      <span class='listsmall'>Length:</span> {{ routesStore.getShipLength }} m (<a href="#" @click="changeSettings()">change</a>)<br>
      <span class='listsmall'>Width:</span> {{ routesStore.getShipWidth }} m (<a href="#" @click="changeSettings()">change</a>)<br>
      <span class='listsmall'>Height:</span> {{ routesStore.getShipHeight }} m (<a href="#" @click="changeSettings()">change</a>)<br>
      <span class='listsmall'>Displacement:</span> {{ routesStore.getShipDisplacement }} m (<a href="#"
                                                                                               @click="changeSettings()">change</a>)<br>
    </b-modal>

    <b-modal v-model="showRouteNameModal" title="Save route" @ok="handleRouteNameOk" ok-only>
      <b-form-group label="Route name:" label-for="route-name-input">
        <b-form-input
            id="route-name-input"
            v-model="routeNameInput"
            placeholder="Enter route name"
        ></b-form-input>
      </b-form-group>
    </b-modal>

    <b-modal title="Agreement warning" v-model="isNeedCheckSafetyWarning" ok-only>
      <div class="text-sm-left" id="up">
        You need to check the route safety first.
      </div>
    </b-modal>

    <b-modal title="Agreement warning" v-model="isAgreementInvalid" ok-only>
      <div class="text-sm-left" id="up">
        The service period under your contract has expired for the vessel. Please enter into a new contract.
      </div>
    </b-modal>

    <b-modal title="Agreement warning" v-model="isAgreementMissing" ok-only>
      <div class="text-sm-left" id="up">
        This vessel is not serviced under contract. You can enter into a contract for servicing this vessel.
      </div>
    </b-modal>

    <b-modal title="Server ERROR" v-model="momentaryStore.isAPIError" @ok="reloadPage()" ok-only>
      <div class="text-sm-left" id="up">
        An error with connection to server{{ momentaryStore.APIErrorText }}. Please try again later.
      </div>
    </b-modal>

    <!--    <b-modal title="Route name" v-model="routesStore.getShipSettings.name" @ok="saveRTZWithName()" ok-only>-->
    <!--      <div class="text-sm-left" id="up">-->
    <!--        An error with connection to server{{ momentaryStore.APIErrorText }}. Please try again later.-->
    <!--      </div>-->
    <!--    </b-modal>    -->

    <b-overlay
        :show="!userStore.isShipsLoaded ||
        !deviceStore.isAgreementLoaded ||
        momentaryStore.isBusy1 ||
        (momentaryStore.isBusy && tabIndex === tabIndexFolio) ||
        (momentaryStore.isBusy && tabIndex === tabIndexOrders) ||
        $store.state.routes.progress.isCalculating ||
        is_catalog_preparing || is_items_by_route_preparing"
        bg-color="white" class="mt-4"
        :opacity=getOverlayOpacity>

      <template #overlay>
        <div v-if="is_catalog_preparing" class="text-center">
          <b-icon icon="hourglass" font-scale="2" animation="spin"></b-icon>
          <p>Loading catalog...</p>
        </div>

        <div v-if="is_items_by_route_preparing" class="text-center">
          <b-icon icon="hourglass" font-scale="2" animation="spin"></b-icon>
          <p>Loading products by route...</p>
        </div>

        <div v-if="momentaryStore.isBusy && (tabIndex === tabIndexFolio || tabIndex === tabIndexOrders)"
             class="text-center">
          <b-icon icon="hourglass" font-scale="2" animation="spin"></b-icon>
          <p>Loading...</p>
        </div>

        <div v-if="momentaryStore.isBusy1" class="text-center">
          <b-icon icon="hourglass" font-scale="2" animation="spin"></b-icon>
          <p>Loading...</p>
        </div>

        <div v-if="!userStore.isShipsLoaded || !deviceStore.isAgreementLoaded" class="text-center">
          <b-icon icon="hourglass" font-scale="2" animation="spin"></b-icon>
          <p v-if="!userStore.isShipsLoaded">Loading ships...</p>
          <p v-if="userStore.isShipsLoaded && !deviceStore.isAgreementLoaded">Loading agreements...</p>
        </div>

        <div v-if="$store.state.routes.progress.isCalculating" class="text-center">
          <b-card>
            <p id="cancel-label">Route/Safety calculating...</p>
            <b-progress class="mt-2" :max=100 show-value show-progress animated>
              <b-progress-bar :value="routingProgress()"
                              :label="`${(routingProgress())}%`"
                              variant="primary"></b-progress-bar>
            </b-progress>
            <b-button class="mt-2" size="sm" @click="cancelRoute()" variant="danger">Cancel</b-button>
          </b-card>
        </div>
      </template>

      <b-container fluid>

        <b-row class="mt-2">
          <b-col v-show="displayContent === 'showRoute'" order=4 order-lg=1 :lg="getTabSize()">
            <div>
              <b-button-group block class="mb-2 btn-block" compact-button-group>
                <b-button :variant="getRouteButtonStatus('ports')" size="sm" @click="showPorts()">Ports</b-button>
                <b-button :variant="getRouteButtonStatus('routes')" size="sm" @click="showRoutes()">Routes</b-button>
                <b-button :variant="getRouteButtonStatus('settings')" size="sm" :disabled="momentaryStore.isUKCLoaded"
                          @click="showSettings()">Settings
                </b-button>
              </b-button-group>
            </div>
            <b-row>
              <b-col>
                <div class="d-flex align-items-center">
                  <b-form-select :disabled="select_ship_disabled" v-model="selected_ship" :options="ships"
                                 :title="getSelectedShipTooltip" v-b-tooltip.hover
                                 size="sm"></b-form-select>
                  <span class="ml-2 small-gray">{{ deviceStore.getAgreementProvider }}</span>
                </div>

              </b-col>
            </b-row>

            <!--            <b-card no-body>-->
            <b-tabs card v-model="tabIndex" small class="compact-tabs">
              <b-tab title="Files">
                <div class="mt-2">
                  <b-form-file v-model="pathFile" id="upload-path" accept=".json" class="d-none"></b-form-file>
                  <b-form-file v-model="routeFile" id="upload-route" accept=".rtz" class="d-none"></b-form-file>
                  <b-form-file v-model="zonesFile" id="upload-zones" class="d-none"></b-form-file>

                  <b-button size="sm" variant="info" class="mr-1" @click="loadZonesFile()">Load Zones</b-button>
<!--                  <b-button size="sm" variant="info" class="mr-1" @click="loadPathFile()">Load Paths</b-button>-->
                  <b-button size="sm" variant="info" class="mr-1" @click="loadRouteFile()">Load RTZ</b-button>
                  <b-button size="sm" variant="info" :disabled="routesStore.getShipWaypointsCount < 2"
                            @click="saveRTZ()">Save RTZ
                  </b-button>&nbsp;
                  <!--                    <b-button size="sm" variant="info" @click="saveRouteFile()">Save Route</b-button>&nbsp;-->
                </div>
              </b-tab>
              <b-tab title="WP" active>
                <RouteTable ref="route-tab" v-if="routesStore.getShipWaypointsCount > 0" :fields="fields"></RouteTable>
              </b-tab>
              <b-tab @click="tabPassagePlanClicked()" title="UKC"
                     :title-item-class="getTabStatus()">
                <passage-plan-table ref="passage-plan-table"></passage-plan-table>
              </b-tab>
              <b-tab @click="tabPassagePlanClicked()" title="P.Plan"
                     :title-item-class="getTabStatus()">
                <passage-plan ref="passage-plan"></passage-plan>
              </b-tab>
              <b-tab title="Weather" @click="returnToWeather()">
                <weather ref="weather"></weather>
              </b-tab>
              <b-tab title="Folio" :disabled="isAgreementInvalid || isAgreementMissing"
              >
                <folio-table ref="folio-tab"></folio-table>
              </b-tab>
              <b-tab title="Order" :disabled="isAgreementInvalid || isAgreementMissing"
              >
                <orders-table v-show="routesStore.getShipWaypointsCount < 2" ref="order-tab"></orders-table>
                <orders-by-route-table v-show="routesStore.getShipWaypointsCount >= 2"
                                       ref="order-by-route-tab"></orders-by-route-table>
              </b-tab>
            </b-tabs>
            <!--            </b-card>-->
          </b-col>

          <b-col v-show="displayContent === 'showSearch'" order=4 order-lg=1 :lg="getTabSize()">
            <h6 align="center" class="mt-2">SEARCH</h6>
            <b-button class="border-0 float-right" size="sm" variant="outline-danger"
                      @click="displayContent = 'showRoute'">
              <b-icon icon="x" font-scale="1.5"></b-icon>
            </b-button>
            <br><br>
            <search ref="search-panel"></search>
          </b-col>

          <b-col v-show="displayContent === 'showSettings1'" order=4 order-lg=1 :lg="getTabSize()">
            <h6 align="center" class="mt-2">SETTINGS</h6>
            <b-button class="border-0 float-right" size="sm" variant="outline-danger"
                      @click="displayContent = 'showRoute';okPressed()">
              <b-icon icon="x" font-scale="1.5"></b-icon>
            </b-button>
            <br><br>
            <route-settings></route-settings>
          </b-col>

          <b-col v-show="displayContent === 'showSettings'" order=4 order-lg=1 :lg="getTabSize()">
            <h6 align="center" class="mt-2">SETTINGS</h6>
            <b-button class="border-0 float-right" size="sm" variant="outline-danger"
                      @click="displayContent = 'showRoute';restoreSearch()">
              <b-icon icon="x" font-scale="1.5"></b-icon>
            </b-button>
            <br><br>
            <route-settings></route-settings>
          </b-col>

          <b-col v-if="displayContent === 'showInfo'" order=4 order-lg=1 :lg="getTabSize()">
            <h6 align="center" class="mt-2">MAP INFO</h6>
            <b-button class="border-0 float-right" size="sm" variant="outline-danger" @click="closeMapInfo()">
              <b-icon icon="x" font-scale="1.5"></b-icon>
            </b-button>

            <div v-if="!$store.state.fickle.routes.objectsInfoLoading">
              <b-button v-if="map_info_text.length > 0" class="border-0 float-right" size="sm"
                        variant="outline-secondary" @click="closeMapInfoItem()">
                <b-icon icon="arrow-left-short" font-scale="1.5"></b-icon>
              </b-button>
              <div v-for="(item, i) in $store.state.fickle.routes.objectsInfo.info">
                <span class='listsmall'>
                  <a href='#'
                     @click='showObject(item.id, item.name, item.locationType, i+1, true)'>{{ i + 1 }}: {{
                      item.name
                    }} </a><br></span>
              </div>
              <hr>
              <div v-html="map_info_text"/>
            </div>
            <div class="text-center text-danger my-2" v-else>
              <b-spinner small class="align-middle"></b-spinner>
              <strong>&nbsp;Loading...</strong>
            </div>
          </b-col>

          <b-col order=1 order-lg=2 :lg="getMapSize()">
            <div v-if="tabIndex === tabIndexOrders && routesStore.getShipWaypointsCount < 2">
              <orders-map ref="orders-map" :height=map_size></orders-map>
            </div>
            <div v-if="tabIndex === tabIndexOrders && routesStore.getShipWaypointsCount >= 2">
              <orders-by-route-map ref="orders-by-route-map" :height=map_size></orders-by-route-map>
            </div>
            <div v-if="tabIndex === tabIndexFolio">
              <folio-map ref="folio-map" :height=map_size></folio-map>
            </div>
            <div v-if="tabIndex != tabIndexOrders && tabIndex !== tabIndexFolio">
              <b-overlay :show="!deviceStore.isDeviceIDExist">
                <template #overlay>
                  <p>Add a ship to the account, before routing</p>
                </template>
                <my-map :map=map :height=map_size></my-map>
              </b-overlay>
            </div>
          </b-col>
        </b-row>
        <!--        <b-row class="mt-2" style="max-height: 100px">-->
        <!--          <b-col>-->
        <!--            <b-card>-->
        <!--              <b-row>-->
        <!--                <b-col cols="2">-->
        <!--                  <ship></ship>-->
        <!--                </b-col>-->
        <!--                <b-col>-->
        <!--                  <graphs></graphs>-->
        <!--                </b-col>-->
        <!--              </b-row>-->
        <!--            </b-card>-->
        <!--          </b-col>-->
        <!--        </b-row>-->
      </b-container>
    </b-overlay>
  </div>
</template>

<script>
import RoutesMap from "@/views/routes/RoutesMap";
import PassagePlanTable from "@/views/routes/PassagePlanTable.vue";
import RouteSettings from "@/views/routes/RouteSettings.vue";
import RouteTable from "@/views/routes/RouteTable.vue";
import Weather from "@/views/routes/Weather.vue";
import Search from "@/views/routes/Search.vue";
import {eventBus} from "@/main";
import {routes_map} from "@/main";
import router from "@/router";
import store from "@/store";
import {useMomentaryStore} from "@/stores/momentary";
import {loadENCData, loadUserData} from "@/helpers/offline";
import OrdersMap from "@/views/routes/OrdersMap.vue";
import OrdersByRouteMap from "@/views/routes/OrdersByRouteMap.vue";
import FolioMap from "@/views/routes/FolioMap.vue";
import Graphs from "@/views/routes/Graps.vue"
import Ship from "@/views/routes/Ship.vue"
import {useRoutesStore} from "@/stores/routes";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {mapStores} from "pinia";
import PassagePlan from "@/views/routes/PassagePlan.vue";
import OrdersTable from "@/views/routes/OrdersTable.vue";
import OrdersByRouteTable from "@/views/routes/OrdersByRouteTable.vue";
import FolioTable from "@/views/routes/FolioTable.vue";
import {myAxios, myAxiosRetry} from "@/services/myAxios";
import dayjs from "dayjs";
import {useLogbookStore} from "@/stores/logbook";
import {useUserStore} from "@/stores/user";
import {useDeviceStore} from "@/stores/device";
import {loadAgreements, loadShips, loadUKHO_Publications, prepareCatalog, prepareUKHOCatalog} from "@/helpers/api";
import JSZip from "jszip";
import * as fxparser from "fast-xml-parser";
import {updatePlotlyData} from "@/my-leaf/routes/components/route-graphs";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  components: {
    OrdersByRouteMap,
    RouteTable,
    MyMap: RoutesMap,
    OrdersMap: OrdersMap,
    FolioMap: FolioMap,
    OrdersTable: OrdersTable,
    OrdersByRouteTable: OrdersByRouteTable,
    FolioTable: FolioTable,
    // Graphs: Graphs,
    // Ship: Ship,
    RouteSettings,
    Search,
    Weather: Weather,
    PassagePlan,
    PassagePlanTable,
  },
  data() {
    return {
      show2D: false,
      isNeedCheckSafety: false,
      isNeedCheckSafetyWarning: false,

      showRouteNameModal: false,
      routeNameInput: '',

      pathFile: null,
      routeFile: null,
      zonesFile: null,

      selected_ship: "",
      select_ship_disabled: false,
      ships: [],

      needToRestoreSearch: false,
      preparingPassagePlan: true,

      displayContent: 'showRoute',
      map_info_text: '',
      map_info_object: '',

      windowWidth: window.innerWidth,
      mapHeight: window.innerHeight / 1.2,
      mapHeightMobile: window.innerHeight / 1.8,

      map: routes_map,

      is_catalog_preparing: false,
      is_items_by_route_preparing: false,

      editMode: false,
      editItems: [],
      orderId: '',

      tabIndexFiles: 0,
      tabIndexWaypoints: 1,
      tabIndexPassagePlanTable: 2,
      tabIndexPassagePlan: 3,
      tabIndexWeather: 4,
      tabIndexFolio: 5,
      tabIndexOrders: 6,

      tabIndex: this.tabIndexWaypoints,

      fields: [
        {key: 'name', label: 'name', type: 'string', tdClass: 'small-black', thClass: 'small-gray',},
        {key: 'lat', label: 'Lat', type: 'number', tdClass: 'small-black', thClass: 'small-gray',},
        {key: 'lon', label: 'Lon', type: 'number', tdClass: 'small-black', thClass: 'small-gray',},
      ],

      show_spinner: false,
      // priceCalculating: false,
      openCellsList: false,
      selectedPeriod: {period_code: "4", period: "3"},

      cellList: "",

      agreement: null,

      selected_ecdis: null,
      checkOrder: false,
    }
  },
  mounted() {
    myAxios.get('/api/v1/manage/test')

    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }

    eventBus.$off('switch-wp-tab')
    eventBus.$off('search-on-map')
    eventBus.$off('send-order-route')
    eventBus.$off('show-map-info')
    eventBus.$off('show-waypoints')
    eventBus.$off('save-nogo-zones')
    eventBus.$off('route-cleared')
    eventBus.$off('calculate-ukc')
    eventBus.$off('is-need-check-safety')
    eventBus.$off('show-calculate-time')

    // eventBus.$on('route-cleared1', () => {
    //   alert(1)
    //   //TODO
    //   this.$refs["passage-plan-table"].isUKCReady = false
    //   this.momentaryStore.setUKCLoadedFlag(false)
    //   routes_map.isUKCmode = false
    // })

    eventBus.$on('show-calculate-time', (time) => {
      this.$bvModal.msgBoxOk(`Route calculated in: ${time} seconds`, {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'primary',
        headerClass: 'd-none',
        bodyClass: 'py-3 text-center rounded-top',
        footerClass: 'p-2 border-top-0 d-flex justify-content-center',
        centered: true,
        contentClass: 'shadow-lg rounded-lg border-0 animate__animated animate__fadeIn overflow-hidden',
        okTitle: 'Close',
        bodyBgVariant: 'white'
      })
    })

    eventBus.$on('route-cleared', () => {
      //TODO

      if (this.routesStore.getShipWaypointsCount === 0) {
        this.$refs['order-by-route-tab'].clearFlags();
        this.$refs["passage-plan-table"].isUKCReady = false
        this.momentaryStore.setUKCLoadedFlag(false)
        routes_map.isUKCmode = false
      }
    })

    eventBus.$on('calculate-ukc', () => {
      // this.$refs['passage-plan-table'].calculateUKC()
    })

    eventBus.$on('is-need-check-safety', (flag) => {
      this.isNeedCheckSafety = flag;
    })

    eventBus.$on('save-nogo-zones', (zones) => {
      this.saveZoneFile(zones)
    })

    eventBus.$on('switch-wp-tab', () => {
      // TODO
      //this.tabIndex = this.tabIndexWaypoints
    })

    eventBus.$on('search-on-map', () => {
      this.displayContent = 'showSearch'
    })

    eventBus.$on('show-waypoints', () => {
      this.displayContent = 'showRoute'
    })

    eventBus.$on('send-order-route', () => {
      router.push({name: 'charts'}).catch(() => {
      });
    })

    eventBus.$on('show-map-info', (payload) => {
      this.map_info_text = ""
      this.map_info_object = ""
      store.commit("fickle/setObjectInfo", payload)
      this.displayContent = 'showInfo'
    })

    if (this.$route.params.orderevent === 'change-order') {

      this.$nextTick(function () {

        console.log("aaaa change-order")
        console.log("aaaa", this.$route.params.order)

        loadShips().then(() => {
          loadAgreements().then(() => {
          })
        })

        this.ships = [{value: this.$route.params.order.deviceId, text: this.$route.params.order.shipName}]
        this.selected_ship = this.$route.params.order.deviceId

        this.editMode = true
        this.editItems = this.$route.params.order.items
        this.orderId = this.$route.params.order.orderId
        this.tabIndex = this.tabIndexOrders

        console.log("aaaa first tab index", this.tabIndex)
      })
    } else {
      if (this.routesStore.getShipWaypointsCount > 0)
        routes_map.redrawRoute()

      loadShips().then(() => {
        loadAgreements().then(() => {
          if (this.deviceStore.isDeviceIDExist)
            this.selected_ship = this.deviceStore.getDeviceID
          else
            this.selected_ship = this.userStore.getECDISShips[0]?.value ?? ''
          this.ships = this.userStore.getECDISShips
        })
      })
    }
  },
  watch: {
    'pathFile'(newVal, oldVal) {
      if (newVal) {
        this.readPath(newVal)
        this.pathFile = ''
      }
    },
    'routeFile'(newVal, oldVal) {
      if (newVal) {
        // this.readRouteFile(newVal)
        this.readRTZ(newVal)
        this.routeFile = ''
      }
    },
    'zonesFile'(newVal, oldVal) {
      if (newVal) {
        this.readZonesFile(newVal)
      }
    },
    'selected_ship'(newVal, oldVal) {
      routes_map.clearRoute(true, true, false)
      this.deviceStore.selectDevice(newVal);
      routes_map.redrawRoute()
      routes_map.boundRoute()
      routes_map.drawLogbookState()

      this.$refs['order-tab'].getHistory().then(resp => {
        if (this.deviceStore.getAgreementProvider === 'UKHO')
          this.deviceStore.addOrdersHistoryAVCS(resp.avcsCells)
        else if (this.deviceStore.getAgreementProvider === 'Primar') {
          let history = []
          console.log("aaaa1", resp.orders)
          resp.orders.forEach(order => {
            let date = order.createdUtc
            order.items.forEach(item => {
              let cell = {}
              let name = item.id
              let period = dayjs(date).add(item.monthCount).format('YYYY-MM-DD')

              cell.name = name
              cell.expiryDate = period

              history.push(cell)
            })
          })
          this.deviceStore.addOrdersHistoryPrimar(history)
        }
      })
    },
    'routesStore.getShipWaypointsCount'(newVal, oldVal) {
      if (newVal === 0) {
        this.tabIndex = this.tabIndexWaypoints
      }
    },
    'tabIndex'(newVal, oldVal) {
      console.log("aaaa WATCH", oldVal, newVal)

      if (newVal === this.tabIndexOrders || newVal === this.tabIndexFolio) {
        // let mapType = newVal === this.tabIndexFolio ? 'folio-map' : 'orders-map'
        let mapType = ''
        if (newVal === this.tabIndexOrders && this.routesStore.getShipWaypointsCount < 2)
          mapType = 'orders-map'
        if (newVal === this.tabIndexOrders && this.routesStore.getShipWaypointsCount >= 2)
          mapType = 'orders-by-route-map'
        if (newVal === this.tabIndexFolio)
          mapType = 'folio-map'


        this.select_ship_disabled = true
        this.is_catalog_preparing = true

        loadUKHO_Publications().then(resp => {
          console.log("adp full")
        })

        if (this.editMode) {
          prepareCatalog().then(resp => {
            this.is_catalog_preparing = false
            this.is_items_by_route_preparing = true
            this.$refs[mapType].init_map(this.editItems).then(() => {
              this.is_items_by_route_preparing = false
            })
          })
        } else {
          prepareCatalog().then(resp => {
            this.is_catalog_preparing = false
            // this.is_items_by_route_preparing = true
            this.$refs[mapType].init_map().then(() => {
              this.is_items_by_route_preparing = false
            })
          })
        }
      } else {
        this.select_ship_disabled = false
        if (newVal === this.tabIndexWaypoints) {
          routes_map.addNoGoLayer()
          routes_map.showControls()
          routes_map.isUKCmode = false
          routes_map.map.closePopup()
          // setTimeout(() => {
          //     this.$refs["route-tab"].onCellClicked(1)
          // }, 1000)

        } else if (newVal === this.tabIndexWeather) {
          this.prepareWeather(newVal)
          routes_map.hideControls()
        } else if (newVal === this.tabIndexPassagePlanTable) {
          // routes_map.hideControls()
          if (this.momentaryStore.isUKCLoaded)
            routes_map.isUKCmode = true
          // this.show2D = true
          this.$refs['passage-plan-table'].calculateUKC()
        }
      }
    }
  },
  computed: {
    dayjs() {
      return dayjs
    },
    getOverlayOpacity() {
      return this.$store.state.routes.progress.isCalculating ? 0.3 : 0.9
    },
    isAgreementInvalid() {
      if (!this.deviceStore.isAgreementLoaded || this.deviceStore.getDeviceID === '')
        return false
      else if (this.deviceStore.isAgreementLoaded && this.deviceStore.isDeviceInAgreement(this.deviceStore.getDeviceID) && !this.deviceStore.isAgreementValid(this.deviceStore.getDeviceID))
        return true
      else
        return false
    },
    isAgreementMissing() {
      if (!this.deviceStore.isAgreementLoaded || this.deviceStore.getDeviceID === '')
        return false
      else if (this.deviceStore.isAgreementLoaded && !this.deviceStore.isDeviceInAgreement(this.deviceStore.getDeviceID))
        return true
      else
        return false
    },

    ...mapStores(useMomentaryStore, useRoutesStore, useLogbookStore, useUserStore, useDeviceStore),

    is_mobile() {
      return this.windowWidth <= 760
    },
    map_size() {
      if (this.is_mobile)
        return window.innerHeight / 1.8
      else
        return window.innerHeight / 1.2
    },
  },
  methods: {
    showFolioCell(cellName) {
      this.$refs['folio-map'].showFolioCell(cellName);
    },
    showCategoryOnMap(category, flag) {
      this.$refs['orders-by-route-map'].showCategory(category, flag);
    },

    redraw() {
      this.$refs['orders-by-route-map'].redraw();
    },


    showOrdersByRouteCell(cellName) {
      this.$refs['orders-by-route-map'].showFolioCell(cellName);
    },
    switchOrdersByRouteCell(cellName, isSelected) {
      this.$refs['orders-by-route-map'].switchCellSelected(cellName, isSelected);
    },
    selectGraph(cellName) {
      this.$refs['orders-by-route-map'].map.chartGantt?.selectCell(cellName)
    },
    updateGraph(cellName, isSelected, isClicked = false) {
      updatePlotlyData(cellName, isSelected, isClicked)
      // if (this.$refs['orders-by-route-map'].map.chartGantt)
      //    this.$refs['orders-by-route-map'].map.chartGantt.updateSeriesColor(cellName, isSelected)

    },

    aaa2424(cellName) {
      alert('aaaa8888')
      // this.$refs['folio-map'].showFolioCell(cellName);
    },

    linkClass(idx) {
      if (idx === this.tabIndex)
        return ['bg-primary', 'text-white']
      else
        return ['bg-transparent', 'text-primary']
    },
    getMapSize() {
      if (this.tabIndex === this.tabIndexPassagePlanTable)
        return 6
      else
        return 8
    },
    getTabSize() {
      if (this.tabIndex === this.tabIndexPassagePlanTable)
        return 6
      else
        return 4
    },
    getSelectedShipTooltip() {
      let tooltip = ''
      if (this.$store.state.auth.user.role === 'distributor') {
        let deviceId = this.selected_ship;
        let ship = this.ships.find(e => e.value === deviceId);
        tooltip = ship?.email + ", " + deviceId;
      }
      return tooltip
    },
    isUKCReady() {
      return this.momentaryStore.isUKCLoaded
      // return this.$refs['passage-plan-table']?.isUKCReady ?? false
    },
    // readRTZ1(file) {
    //   // let reader = new FileReader();
    //   var JSZip = require("jszip");
    //   console.log("zip", file)
    //   console.log("zip", file.name)
    //
    //
    //   // var zip = new JSZip();
    //
    //   var new_zip = new JSZip();
    //   let x = new_zip.loadAsync("doc.zip");
    //   console.log("zip1", x)
    //   let x1 = new_zip.files["doc.xml"]
    //   console.log("zip2", x1)
    //
    //
    //   // zip.file("hello.txt", "Hello[p my)6cxsw2q");
    //   // zip.file("hello.txt", "Hello World\n");
    //   // zip.file("nested/hello.txt", "Hello World\n");
    //   // zip.folder("nested").file("hello.txt", "Hello World\n");
    //
    //   // var new_zip = new JSZip();
    //   // new_zip.loadAsync(content)
    //   //     .then(function(zip) {
    //   //       zip.file("hello.txt").async("string"); // a promise of "Hello World\n"
    //   //     });
    //
    //   // const OriginalFs = require("original-fs");
    //   // let AdmZip = require("adm-zip");
    //   // const zip = new AdmZip("./my_file.zip", { fs: OriginalFs });
    //
    //
    //
    //   // reader.onload = (e) => {
    //   //   console.log("zip", e)
    //   //   // let contents = e.target.result;
    //   // };
    // },

    // readRTZ2(file) {
    //   // let JSZip = require("jszip");
    //
    //   var fs = require("fs");
    //   var JSZip = require("jszip");
    //
    //   // let reader = new FileReader();
    //   // reader.onload = (e) => {
    //   //   let contents = e.target.result;
    //   //
    //   //   let new_zip = new JSZip();
    //   //   let x = new_zip.loadAsync(contents);
    //   //   console.log("zip1", x)
    //   //   let x1 = new_zip.files["doc.xml"]
    //   //   console.log("zip2", x1)
    //   // };
    //   // reader.readAsDataURL(file);
    // },


    async getRouteName() {
      this.showRouteNameModal = true;
      return new Promise((resolve) => {
        this.$once('route-name-entered', (routeName) => {
          resolve(routeName);
        });
      });
    },
    handleRouteNameOk() {
      this.showRouteNameModal = false;
      // this.$emit('route-name-entered', this.routeNameInput);
      this.saveRTZ1(this.routeNameInput)
    },

    saveRTZ() {
      this.routeNameInput = this.routesStore.getRouteName.toLowerCase()
      this.showRouteNameModal = true;
    },

    saveRTZ1(fileName) {
      // let jsonData = JSON.stringify({
      //   routeResponse: this.routesStore.getShipResponse,
      //   routeSettings: this.routesStore.getShipSettings
      // })

      // let a1 = this.ships.find((a) => a.value === this.selected_ship)
      // console.log("ship", a1)

      let shipName = this.ships.find((a) => a.value === this.selected_ship).text

      let points = this.routesStore.getShipResponse
      console.log("aaa rtz", points)

      let xml = {}
      let waypoints = []
      let lat, lon
      let routeName = this.routesStore.getRouteName
      let portsideXTD = this.routesStore.getShipSettings.xtel
      let starboardXTD = this.routesStore.getShipSettings.xter
      points.routePoints.forEach((point, index) => {
        lat = point.lat
        lon = point.lon

        let waypoint = {
          "$name": 'WP' + index,
          "position": {
            "$lat": lat,
            "$lon": lon,
          },
          "leg": {
            "$portsideXTD": portsideXTD,
            "$starboardXTD": starboardXTD,
          },
        }
        waypoints.push({waypoint: waypoint})
      })
      xml['?xml'] = {"$version": "1.0", "$encoding": "utf-8"}
      xml.route = {"$version": "1.0"}
      xml.route.routeInfo = {
        "$routeName": routeName,
        "$vesselName": shipName,
        "$vesselIMO": "",
        "$vesselDraught": this.routesStore.getShipDraught,
        "$vesselHeight": this.routesStore.getShipHeight,
        "$vesselWidth": this.routesStore.getShipWidth,
        "$vesselLength": this.routesStore.getShipLength
      }
      xml.route.waypoints = waypoints


      console.log("aaa rtz", waypoints)


      // const waypoints = [{
      //     "waypoint": {
      //       "$name": "WP #1",
      //       "$speed": 10,
      //       "position": {
      //         "$lat": 10.2,
      //         "$lon": 12.4,
      //       }
      //     }
      //   },
      //   {
      //     "waypoint": {
      //       "$name": "WP #2",
      //       "$speed": 12,
      //       "position": {
      //         "$lat": 20.2,
      //         "$lon": 22.4,
      //       }
      //     }
      //   },
      // ]

      // const waypoints = [
      //   {
      //     "color": "purple",
      //     "type": "minivan",
      //     "registration": "2020-02-03",
      //     "capacity": 7
      //   },
      // ];

      const options = {
        ignoreAttributes: false,
        attributeNamePrefix: "$",
        format: true,
        suppressEmptyNode: true,
        oneListGroup: true,
        arrayNodeName: "waypoints"
      };

      const builder = new fxparser.XMLBuilder(options)
      let output = builder.build(xml);

      let a = document.createElement("a");
      let file = new Blob([output], {type: 'text/plain'});
      a.href = URL.createObjectURL(file);

      a.download = fileName + ".rtz";
      a.click();

      // fileName = this.routesStore.getRouteName.toLowerCase()
      // if (!fileName) {
      //   this.$bvModal.msgBoxOk('Enter file name', {
      //     title: 'File name',
      //     size: 'sm',
      //     buttonSize: 'sm',
      //     okVariant: 'primary',
      //     headerClass: 'p-2 border-bottom-0',
      //     footerClass: 'p-2 border-top-0',
      //     centered: true,
      //     inputAttrs: {
      //       type: 'text',
      //       placeholder: 'Enter file name',
      //     },
      //   }).then(value => {
      //     if (value) {
      //       fileName = value.toLowerCase();
      //       a.download = fileName + ".rtz";
      //       a.click();
      //     }
      //   });
      // } else {
      //   a.download = fileName + ".rtz";
      //   a.click();
      // }
    },

    readPath(file) {
      console.log(file)
      let reader = new FileReader();
      reader.onload = (e) => {
        let contents = e.target.result;
        try {
          const jsonObject = JSON.parse(contents);
          console.log('Parsed JSON:', jsonObject);

          routes_map.calcucaltePathRoute(jsonObject)


          // Process your JSON object here
          // routes_map.loadPathFromJson(jsonObject);
        } catch (error) {
          console.error('Error parsing JSON file:', error);
          this.$bvModal.msgBoxOk('Invalid JSON file format', {
            title: 'File Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          });
        }
      };
      reader.readAsText(file);
    },


    readRTZ(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        let contents = e.target.result;
        console.log(contents)

        const options = {
          ignoreAttributes: false,
          compact: true,
        };
        const parser = new fxparser.XMLParser(options)
        let xml = parser.parse(contents)
        let points = xml.route.waypoints.waypoint

        let coordinates = []

        console.log("zip xml", points)

        let info = xml.route.routeInfo
        let name = info['@_routeName']
        let draught = info['@_vesselDraught']
        let length = info['@_vesselLength']
        let height = info['@_vesselHeight']
        let width = info['@_vesselWidth']

        console.log("AAA name", name)
        console.log("AAA draught", draught)
        console.log("AAA draught", Number(draught))

        if (name !== undefined)
          this.routesStore.getShipSettings.name = name
        if (draught !== undefined)
          this.routesStore.getShipSettings.draught = Number(draught)
        if (length !== undefined)
          this.routesStore.getShipSettings.length = Number(length)
        if (height !== undefined)
          this.routesStore.getShipSettings.height = Number(height)
        if (width !== undefined)
          this.routesStore.getShipSettings.width = Number(width)

        console.log("info1", info)

        points.forEach((waypoint) => {
          let lat = waypoint.position['@_lat']
          let lon = waypoint.position['@_lon']
          let speed = waypoint?.['@_speed'] ?? "0"

          if (speed.length > 10) {
            speed = "3"
            waypoint['@_speed'] = speed
          }

          coordinates.push({lat: lat, lon: lon, speed: speed})
        })

        console.log("info2", coordinates)

        this.routesStore.addImportedRoute(points)

        console.log("zip xml", coordinates)

        this.$refs["passage-plan-table"].isUKCReady = false
        this.momentaryStore.setUKCLoadedFlag(false)
        routes_map.clearRoute()
        routes_map.createImportedRoute(coordinates)
        // routes_map.redrawRoute()
        routes_map.boundRoute()
        this.tabIndex = this.tabIndexWaypoints

        routes_map.checkSafety()

        // let route = {}

        // let jsonContents = JSON.parse(contents)
        // this.routesStore.restoreShipRouteSettings(jsonContents.routeSettings);
        // this.routesStore.restoreShipRouteResponse(jsonContents.routeResponse);
        // routes_map.redrawRoute()
        // routes_map.boundRoute()
        // this.tabIndex = this.tabIndexWaypoints
        // this.routeFile = null
      };
      reader.readAsText(file);
    },

    readRouteFile(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        let contents = e.target.result;
        let jsonContents = JSON.parse(contents)
        this.routesStore.restoreShipRouteSettings(jsonContents.routeSettings);
        this.routesStore.restoreShipRouteResponse(jsonContents.routeResponse);
        routes_map.redrawRoute()
        routes_map.boundRoute()
        this.tabIndex = this.tabIndexWaypoints
        this.routeFile = null
      };
      reader.readAsText(file);
    },

    readZonesFile(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        let contents = e.target.result;
        let jsonContents = JSON.parse(contents)
        console.log(jsonContents)
        this.routesStore.addNoGoZone(jsonContents)
        routes_map.restoreNoGoZones()
        this.zonesFile = null
      };
      reader.readAsText(file);
    },
    loadRouteFile: () => {
      document.getElementById('upload-route').click()
    },
    loadPathFile: () => {
      document.getElementById('upload-path').click()
    },
    loadZonesFile: () => {
      document.getElementById('upload-zones').click()
    },
    saveRouteFile() {
      let jsonData = JSON.stringify({
        routeResponse: this.routesStore.getShipResponse,
        routeSettings: this.routesStore.getShipSettings
      })
      let a = document.createElement("a");
      let file = new Blob([jsonData], {type: 'text/plain'});
      a.href = URL.createObjectURL(file);
      a.download = "route.txt";
      a.click();
    },
    saveZoneFile(zones) {
      let jsonData = JSON.stringify(zones)
      let a = document.createElement("a");
      let file = new Blob([jsonData], {type: 'text/plain'});
      a.href = URL.createObjectURL(file);
      a.download = "zones.txt";
      a.click();
    },
    reloadPage() {
      window.location.reload()
    },
    restoreSearch() {
      if (this.needToRestoreSearch)
        this.$refs["search-panel"].showCalculateModal = true
    },
    routePointInfos(index) {
      return this.routesStore.getShipWaypoints[index].info
    },
    dateu(seconds) {
      let date = this.routesStore.getShipStartDate
      let time = this.routesStore.getShipStartTime
      let datetime = dayjs(date + " " + time, "YYYY-MM-DD HH:mm")
      let datetimenew = datetime.add(seconds, "seconds")
      let shortdate = datetimenew.unix()

      return shortdate
    },
    prepareWeather() {
      if (this.routesStore.getShipWaypointsCount === 0)
        return

      let weather = []

      store.commit("fickle/setWeatherWP", weather)
      let pts = this.routesStore.getShipWaypoints
      this.routesStore.initWeather(pts.length)
      pts.forEach((coordinates, i) => {
        let lat = coordinates.lat
        let lon = coordinates.lon

        let datetime = this.dateu(this.routePointInfos(i).fromStartSeconds)
        let URL = 'https://nogotiles.bgeo.fi:6011/weather_info?lat=' + lat + '&lon=' + lon + '&t=' + datetime

        myAxiosRetry.get(URL).then(resp => {
          this.routesStore.addWeather(resp.data, i)
        }).catch(error => {
          this.routesStore.addWeather({temperature: 'N/A', wind: 'N/A', windAngle: 'N/A'}, i)
        })
      })
    },
    routingProgress() {
      let progress = this.$store.state.routes.progress.calculatingProgress
      if (progress > 100)
        progress = 100

      return progress
    },

    getTabStatus() {
      if (this.routesStore.getShipWaypointsCount > 1)
        return 'aaa12'
      else
        return 'd-none'
    },
    cancelRoute() {
      routes_map.cancelAutorouting()
    },
    tabPassagePlanClicked() {
      this.$refs["passage-plan"].createPDF()
    },
    showPorts() {
      this.displayContent = 'showSearch'
    },
    showRoutes() {
      this.displayContent = 'showRoute'
    },
    showSettings() {
      this.displayContent = 'showSettings'
    },
    showSettings1() {
      this.displayContent = 'showSettings1'
    },
    getRouteButtonStatus(type) {
      if (this.displayContent === 'showRoute' && type === 'routes')
        return 'primary'
      else
        return 'outline-primary'
    },
    returnToWeather() {
      eventBus.$emit('show-weather')
      eventBus.$emit('init-weather')
    },
    closeMapInfo() {
      this.displayContent = 'showRoute'
      routes_map.clearInfoObjects()
    },
    closeMapInfoItem() {
      this.map_info_text = ''
      this.map_info_object = ''
      routes_map.clearInfoObjects()
    },
    showObject(id, name, type, i, needZoom) {
      this.map_info_text = "<span class='small-cyan'>" + i + ": " + name + " (" + type + ")</span><hr>"
      this.map_info_object = id

      this.$store.state.fickle.routes.objectsInfo.info.forEach((item, index) => {
        if (item.name === name && i - 1 === index) {

          this.map_info_text += ""
          item.attributes.forEach((attribute, i) => {
            this.map_info_text += "<span class='listsmall'>" + attribute.name + ":</span> <span class='small-black'>" + attribute.value + "</span><br>"
          })
          this.map_info_text += "<hr>"
        }
      })

      let points = this.$store.state.fickle.routes.objectsInfo.info.filter(function (e) {
        return e["id"] === id;
      });

      let lines = points[0].lines

      routes_map.drawInfoObject({lines: lines, type: type, zoom: needZoom})

    },
    changeSettings() {
      this.show2D = false
      this.showSettings1()
    },
    okPressed() {
      this.show2D = false

      if (this.isNeedCheckSafety) {
        this.tabIndex = this.tabIndexWaypoints
        this.isNeedCheckSafetyWarning = true
      } else {
        this.$refs['passage-plan-table'].calculateUKC()
      }
    },
    cancelPressed() {
      this.show2D = false
      this.tabIndex = this.tabIndexWaypoints
    },
  },
}
</script>

<style scoped>
:deep(.compact-tabs .nav-tabs) {
  border-bottom: 1px solid #d1d9e6;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
  padding: 0.15rem 0.15rem 0;
}

:deep(.compact-tabs .nav-tabs .nav-item) {
  margin-bottom: -1px;
  white-space: nowrap;
  flex: 0 0 auto;
  margin-right: 1px;
}

:deep(.compact-tabs .nav-link) {
  padding: 0.3rem 0.6rem;
  font-size: 0.8rem;
  border-radius: 0.4rem 0.4rem 0 0;
  transition: all 0.2s ease;
  border: 1px solid #e9ecef;
  border-bottom: none;
  color: #5a6a85;
}

:deep(.compact-tabs .nav-link:hover:not(.active)) {
  background-color: rgba(108, 117, 125, 0.08);
  border-color: #e2e8f0;
  color: #495057;
}

:deep(.compact-tabs .nav-link.active) {
  background-color: #f8f9fa;
  background-image: linear-gradient(to bottom, #ffffff, #f8f9fa);
  border-color: #d1d9e6;
  color: #3a3f51;
  font-weight: 500;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.03);
}

:deep(.compact-tabs .tab-content) {
  border: 1px solid #d1d9e6;
  border-top: none;
  padding: 0.75rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  background-color: #ffffff;
}

/* Hide scrollbar for Chrome/Safari */
:deep(.compact-tabs .nav-tabs::-webkit-scrollbar) {
  height: 3px;
  width: 0;
}

:deep(.compact-tabs .nav-tabs::-webkit-scrollbar-thumb) {
  background: rgba(108, 117, 125, 0.2);
  border-radius: 3px;
}

:deep(.compact-tabs .tab-pane) {
  padding-top: 0.5rem;
}
</style>

<!--<style>-->

<!--.nav-link.active {-->
<!--  color: #72a4d8;-->
<!--  background-color: #f60e65;-->
<!--  border-color: #156df4 #ffdb50 #1ce10e;-->
<!--}-->

<!--.aaa12 {-->
<!--  color: #f60e65;-->
<!--  background-color: #f1a20a;-->
<!--  border-color: #e4a0a8 #ffe107 #fff;-->
<!--}-->

<!--</style>-->

<!--<script>-->
<!--// eslint-disable-next-line no-unexpected-multiline-->
<!--(function(d,t) {-->
<!--var BASE_URL="https://app.chatwoot.com";-->
<!--var g=d.createElement(t),s=d.getElementsByTagName(t)[0];-->
<!--g.src=BASE_URL+"/packs/js/sdk.js";-->
<!--g.defer = true;-->
<!--g.async = true;-->
<!--s.parentNode.insertBefore(g,s);-->
<!--g.onload=function(){-->
<!--window.chatwootSDK.run({-->
<!--websiteToken: 'nrnUHZ5ZQ66jotURNVUDNuet',-->
<!--baseUrl: BASE_URL-->
<!--})-->
<!--}-->
<!--})(document,"script");-->
<!--</script>-->