import {defineStore} from 'pinia'
import dayjs from "dayjs";
import {useCatalogsStore} from "@/stores/catalogs";

export const useMomentaryStore = defineStore('momentary', {
    state: () => ({
        isBusy: false,
        isBusy1: false,
        isAPIError: false,
        APIErrorText: '',
        isOrdersBusy: false,
        isChartsByLegReady: true,
        isUKCReady: false,
        route: {
            chartsbyroute: [],
            adpbyroute: [],
            enpbyroute: [],
            chartsbylegs: [],
            pubsbylegs: [],
            datasetbylegs: []
        },
        charts: {
            listByRoute: [],
            cellsByRoute: [],
            graphByRoute: []
        },
        graphs: {
            chartsGraph: [],
            shipImage: null,
            shipImageRequest: null,
            shipImageRequestOffset: 0,
        }
    }),
    getters: {
        isChartsByLegLoaded: (state) => state.route.isChartsByLegReady,
        isUKCLoaded: (state) => state.isUKCReady,
        getENPByRoute: (state) => state.route.enpbyroute,
        getADPByRoute: (state) => state.route.adpbyroute,
        getChartsByRoute: (state) => state.route.chartsbyroute,

        getCellsByRoute: (state) => state.charts.cellsByRoute,
        getGraphByRoute: (state) => state.charts.graphByRoute,
        getListByRoute: (state) => state.charts.listByRoute,

        getChartsGraph: (state) => state.graphs.chartsGraph,

        getShipImage: (state) => state.graphs.shipImage,
        getShipImageRequest: (state) => state.graphs.shipImageRequest,
        getShipImageRequestOffset: (state) => state.graphs.shipImageRequestOffset,
    },
    actions: {
        setBusy(flag) {
            this.isBusy = flag
        },
        setBusy1(flag) {
            this.isBusy1 = flag
        },
        set2DImageRequest(val) {
          this.graphs.shipImageRequest = val
        },
        set2DImageRequestoOffset(val) {
            this.graphs.shipImageRequestOffset = val
        },
        setIsChartsByLegLoadedFlag(flag) {
            this.isChartsByLegReady = flag
        },
        setShipImage(val) {
            try {

                // const bytes = new Uint8Array(val);
                // let binary = '';
                // for (let i = 0; i < bytes.byteLength; i++) {
                //     binary += String.fromCharCode(bytes[i]);
                // }
                // this.graphs.shipImage = 'data:image/png;base64,' + window.btoa(val);

                console.log("bbbb", typeof(val))
                console.log("bbbb", val)

                if (typeof val === 'string' && val.startsWith('/')) {
                    val = val.substring(1);
                }

                const blob = new Blob([new Uint8Array([...val].map(c => c.charCodeAt(0)))], {type: 'image/png'});
                const reader = new FileReader();
                reader.onloadend = () => {
                    this.graphs.shipImage = reader.result;
                };
                reader.readAsDataURL(blob);

                return

                // Check if already a valid data URL
                if (typeof val === 'string' && val.startsWith('data:image/png;base64,i')) {
                    this.graphs.shipImage = val;
                    return;
                }

                // For ArrayBuffer or binary data
                if (val instanceof ArrayBuffer) {
                    const bytes = new Uint8Array(val);
                    let binary = '';
                    for (let i = 0; i < bytes.byteLength; i++) {
                        binary += String.fromCharCode(bytes[i]);
                    }
                    this.graphs.shipImage = 'data:image/png;base64,' + window.btoa(binary).substring(1);
                    return;
                }

                // Handle string that might be binary data or malformed base64
                if (typeof val === 'string') {
                    // If it starts with the incorrect slash character
                    if (val.startsWith('/')) {
                        // Remove the slash and add proper prefix
                        this.graphs.shipImage = 'data:image/png;base64,' + val.substring(1);
                        return;
                    }

                    // If it's raw binary data with PNG header
                    if (val.indexOf('PNG') > -1) {
                        const blob = new Blob([new Uint8Array([...val].map(c => c.charCodeAt(0)))], {type: 'image/png'});
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            this.graphs.shipImage = reader.result;
                        };
                        reader.readAsDataURL(blob);
                        return;
                    }

                    // Regular base64 string without prefix
                    this.graphs.shipImage = 'data:image/png;base64,' + val;
                }
            } catch (error) {
                console.error('Error processing image:', error);
            }
        },
        setCellsByRoute(val) {
          this.charts.cellsByRoute = val
        },
        setChartsGraph(val) {
          this.graphs.chartsGraph = val
        },
        setAPIError(flag, text = '') {
            this.isAPIError = flag
            if (text)
                this.APIErrorText = ' (' + text + ')'
            else
                this.APIErrorText = ''
        },
        setOrdersBusy(flag) {
            this.isOrdersBusy = flag
        },
        addChartsByRoute(val) {
            this.route.chartsbyroute = val
        },
        appendChartsByRoute(val) {
            this.route.chartsbyroute.push(val)
        },
        appendCellsByRoute(val) {
            this.charts.cellsByRoute.push(val)
        },
        addADPByRoute(val) {
            this.route.adpbyroute = val
            // val["1"].forEach(item => {
            //     this.route.adpbyroute.push({id: item, disabled: false})
            // })
        },
        addENPByRoute(val) {
            this.route.enpbyroute = val
            // val["1"].forEach(item => {
            //     this.route.enpbyroute.push({id: item, disabled: false})
            // })
        },
        // addAllPublications() {
        //     let catalogsStore = useCatalogsStore()
        //
        //     catalogsStore.getENP.products.forEach(item => {
        //         this.route.enpbyroute.push({id: item.id, title: item.title, disabled: true})
        //     })
        //     catalogsStore.getADP1.products.forEach(item => {
        //         this.route.adpbyroute.push({id: item.id, title: item.title, disabled: true})
        //     })
        //     catalogsStore.getADP2.products.forEach(item => {
        //         this.route.adpbyroute.push({id: item.id, title: item.title, disabled: true})
        //     })
        //     catalogsStore.getADP3.products.forEach(item => {
        //         this.route.adpbyroute.push({id: item.id, title: item.title, disabled: true})
        //     })
        // },
        addChartsByLegs(val) {
            this.route.chartsbylegs = val
            this.isChartsByLegLoaded = true
        },
        addDatasetByLegs(val) {
            this.route.datasetbylegs = val
            this.isChartsByLegLoaded = true
        },
        setUKCLoadedFlag(flag) {
            // alert(flag)
            this.isUKCReady = flag
        },
        addPubsByLegs(val) {
            this.route.pubsbylegs = val
        },
        clearChartsByRoute() {
            this.route.chartsbyroute = []
        },
        clearChartsByLegs() {
            this.route.chartsbylegs = []
            this.route.datasetbylegs = []
        },
    },
    persist: false
})
