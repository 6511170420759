<template>
  <div>
    <b-container>
<!--      ||{{this.aaaa}}||-->

      <file-pond-input ref="logbook-images"></file-pond-input>

      <b-button-group class="d-flex mb-2">
        <b-button size="sm" variant="light" class="mr-1" v-if="!audio.is_recording" @click="recordAudio">
          <b-icon icon="mic"></b-icon>
        </b-button>
        <b-button size="sm" variant="danger" class="mr-1" v-else @click="stopAudio">
          <b-icon icon="mic-mute"></b-icon>
        </b-button>
      </b-button-group>

      <b-form size="sm" class="report-form">
        <b-row class="mb-2">
          <b-col cols="6">
            <div class="form-group-sm">
              <label class="small mb-1">LAT:</label>
              <b-form-input size="sm" v-model="form.lat" :state="latState" type="text" required></b-form-input>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="form-group-sm">
              <label class="small mb-1">LON:</label>
              <b-form-input size="sm" v-model="form.lon" :state="lonState" type="text" required></b-form-input>
            </div>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col cols="6">
            <div class="form-group-sm">
              <label class="small mb-1">HDG:</label>
              <b-form-spinbutton size="sm" class="compact-control" min="0" step="10" max="350" v-model="form.hdg"/>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="form-group-sm">
              <label class="small mb-1">SOG:</label>
              <b-form-spinbutton size="sm" class="compact-control" min="0" step="1" max="50" v-model="form.sog"/>
            </div>
          </b-col>
        </b-row>

        <div class="form-group-sm mb-2">
          <label class="small mb-1">MESSAGE:</label>
          <b-form-textarea
              v-model="form.message"
              rows="3"
              max-rows="3"
              :state="messageState"
              required
              class="compact-textarea"
          ></b-form-textarea>
        </div>

        <div class="d-flex justify-content-between mt-3">
          <b-button size="sm" variant="secondary" @click="closeReport">Cancel</b-button>
          <b-button size="sm" variant="primary" @click="saveReport">Save Report</b-button>
        </div>
      </b-form>
    </b-container>

  </div>
</template>

<script>
import FilePondInput from "@/components/FilePondInput";
import store from "@/store";
import {mapStores} from "pinia";
import {useRoutesStore} from "@/stores/routes";
import {useLogbookStore} from "@/stores/logbook";
import {eventBus, logbooks_map} from "@/main";
import dayjs from "dayjs";
// import qrcode from "@/data/ship2.json";

export default {
  name: "LogbookReport",
  components: {FilePondInput},
  data() {
    return {
      windowWidth: window.innerWidth,
      isMobileView: window.innerWidth <= 760,

      // aaaa: '1234',

      audio: {
        is_recording: false,
        recorder: null,
        chunks: [],
        device: null,
        blobObj: null
      },

      audio_blob: null,
      audio_src: null,

      audioInput: false,
      photoInput: false,
      // aaaa:
      //     {
      //       aaa:'1234'
      //     },
      form: {
        // aaa: '1234',
        lat: '',
        lon: '',
        hdg: 0,
        sog: 10,
        cog: 0,
        rot: 0,
        message: '',
      }
    }
  },
  mounted() {

    setTimeout(() => {
      this.aaaa = 'aaaa'
    }, 5000)


    window.addEventListener('resize', this.checkMobileView);

    eventBus.$off('set-logbook-coordinates')
    eventBus.$on('set-logbook-coordinates',(coordinates) => {
      console.log("coordinates", coordinates)
      this.form.lat = coordinates.lat.toFixed(6)
      this.form.lon = coordinates.lng.toFixed(6)
    })


    // this.$root.$off('bv::toggle::collapse')
    // this.$root.$on('bv::toggle::collapse', (bvEvent, modalId) => {
    // if (this.is_mobile)
    //   navigator.geolocation.getCurrentPosition(success, error);
    // this.$refs['manlog-sidebar'].show();
    // this.$refs['manlog-sidebar'].toggle()
    // this.$root.$emit('bv::toggle::collapse', 'manlog-sidebar')
    // })

    // this.$root.$on('bv::sidebar::show', (bvEvent, modalId) => {
    //   alert(2)
    //   console.log('Modal is about to be shown', bvEvent, modalId)
    // })

    if (this.is_mobile)
      navigator.geolocation.getCurrentPosition(success, error);

    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }

    const success = (position) => {
      this.form.lat = String(position.coords.latitude);
      this.form.lon = String(position.coords.longitude);

      // console.log("pos ", latitude, longitude)
    };

    const error = (err) => {
      console.log(error)
    };


  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobileView);
  },
  computed: {
    is_mobile() {
      return this.windowWidth <= 760
    },
    latState() {
      return this.form.lat.length > 0
    },
    lonState() {
      return this.form.lon.length > 0
    },
    messageState() {
      return this.form.message.length > 0
    },
    ...mapStores(useLogbookStore),
  },
  methods: {
    // Add new methods
    checkMobileView() {
      this.isMobileView = window.innerWidth <= 760;
    },
    goBackMobile() {
      this.$parent.$parent.activeMobileView = 'map';
    },
    updateCoordinates(coordinates) {
      console.log("aaaaaaaaaaaaa")
      // this.$set(this.aaaa, 'aaa', '1212');
      this.form.lat = '1'
      this.form.lon = '2'
      // this.$forceUpdate()
    },
    closeReport() {
      this.clearForm();
      if (this.isMobileView) {
        this.$parent.$parent.activeMobileView = 'map';
      } else {
        this.$parent.$parent.displayContent = 'logbook';
      }
    },
    clearForm() {
      this.form.lat = ''
      this.form.lon = ''
      this.form.hdg = 0
      this.form.sog = 10
      this.form.message = ''
      this.audio_blob = null
      this.audio_src = null
    },
    aaa() {
      alert("aaaa")
    },
    saveReport() {
      // console.log(this.form.lat)
      // console.log(this.form.lon)
      // console.log(this.$parent.selected_row)
      //
      // console.log(this.$parent.selected_row.primarInfo.primarPermit)
      // console.log(this.$parent.selected_row.primarInfo.shipInfo.name)

      // const nowTime = new Date().toISOString()
      // const a = dayjs()
      let report = {
        source: 'web',
        time: dayjs(),

        // name: this.$parent.selected_row.primarInfo.shipInfo.name,
        // name: 'AAAA',
        hdg: this.form.hdg,
        sog: this.form.sog,
        cog: this.form.cog,
        rot: this.form.rot,

        message: this.form.message,

        pos: {
          lat: this.form.lat,
          lon: this.form.lon
        },
        // route: {
        //   name: "",
        //   points: []
        // },
        // history: [],
        // permit: '1234',
        images: this.$refs['logbook-images'].getImages(),
        audio: {
          src: this.audio_src,
          blob: this.audio_blob
        }
        // permit: this.$parent.selected_row.primarInfo.primarPermit
      }

      // this.$refs['logbook-images'].aaaa()

      // logbooks_map.drawAllReportPoints(report)
      // logbooks_map.drawReportPoint(form, time: dayjs(a).format("YYYY-MM-DD HH:mm:ss")})

      this.logbookStore.addReport(report)
      this.clearForm()
      this.$parent.$parent.displayContent = 'logbook'


      // store.commit("fickle/setQRInfo", report)

      // this.photoInput = true
      // this.$refs['manlog-sidebar'].hide()
    },
    addPhotos() {
      this.photoInput = !this.photoInput;
      // this.$refs['logbook-sidebar'].hide()
    },
    cancelPhotos() {
      this.photoInput = !this.photoInput;
      // this.$refs['logbook-sidebar'].hide()
    },
    recordAudio() {
      if (!this.audio.device)
        this.audio.device = navigator.mediaDevices.getUserMedia({audio: true});
      this.audio.is_recording = true
      this.audio.device.then((stream) => {
        this.audio.recorder = new MediaRecorder(stream);
        this.audio.recorder.ondataavailable = (e) => {
          this.audio.chunks.push(e.data);
          if (this.audio.recorder.state === "inactive") {
            let blob = new Blob(this.audio.chunks, {type: "audio/wav"});
            // save to blobObj
            this.audio.blobObj = blob;
            this.audio.chunks = [];
            // emit to parent
            console.log("finished")
            const audioURL = window.URL.createObjectURL(this.audio.blobObj);
            console.log(audioURL)

            this.audio_src = audioURL
            this.audio_blob = this.audio.blobObj

            // store.commit("fickle/setQRAudio", {blob: this.audio.blobObj, url: audioURL})

            this.audio.blobObj = null;
          }
        };
        // start
        this.audio.recorder.start();
      });
    },
    stopAudio() {
      // stop
      this.audio.is_recording = false
      this.audio.recorder.stop();

      console.log("llll", this.audio)
    },
    changeShip() {
      logbooks_map.restoreReports()
    }

  }


}
</script>

<style scoped>
/* Mobile form styling */
.report-form .small {
  font-size: 0.8rem;
  font-weight: 500;
}

@media (max-width: 760px) {
  .form-group-sm {
    margin-bottom: 0.5rem;
  }

  .compact-control {
    height: 32px;
  }

  .compact-textarea {
    font-size: 0.875rem;
    min-height: 60px;
  }

  .b-form-spinbutton.compact-control >>> .btn {
    padding: 0.1rem 0.3rem;
  }

  /* Adjust container padding */
  .container {
    padding: 0.5rem;
  }
}
</style>