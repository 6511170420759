<template>
  <div>
<!--    <div>-->
<!--      <img id='2dimage' src="https://img.freepik.com/free-psd/luxury-yacht-bow-watercraft-elegance-nautical-vessel_632498-25525.jpg">-->
<!--    </div>-->

    <div id="myDiv"></div>
    <div id="testmap" style="width: 100%; height: 600px;"></div>
  </div>
</template>

<script>
import Plotly from "plotly.js/dist/plotly"
import L from 'leaflet';
import {load2DImage} from "@/helpers/api";

export default {
  name: "Test",
  mounted() {

    this.$nextTick(() => {
      this.init_map()
      // this.init_newgraph();
      // this.init_img()
    });

  },
  methods: {


init_map() {
  let lines = {
    "startPoint": {
      "lat": 59.45401004054021,
      "lon": 4.020923170129139
    },
    "endPoint": {
      "lat": 58.587153576388246,
      "lon": 9.90867329454412
    },
    "lines": [
      {
        "id": "1742138105875",
        "name": "Path 05875",
        "depth": 10,
        "status": "medium",
        "type": "coastal",
        "source": "map",
        "points": [
          {
            "lat": 59.11582819144571,
            "lon": 4.036825770056477
          },
          {
            "lat": 58.97880138692991,
            "lon": 4.0313294667868425
          }
        ]
      },
      {
        "id": "1742138017038",
        "name": "Path 17038",
        "depth": 10,
        "status": "medium",
        "type": "coastal",
        "source": "map",
        "points": [
          {
            "lat": 58.33881491414508,
            "lon": 5.208787546711391
          },
          {
            "lat": 58.25798925070412,
            "lon": 5.368180341531384
          }
        ]
      },
      {
        "id": "1742114121874",
        "name": "Path 21874",
        "depth": 10,
        "status": "medium",
        "type": "coastal",
        "source": "map",
        "points": [
          {
            "lat": 57.763775379520744,
            "lon": 6.471359959251314
          },
          {
            "lat": 57.71612962859331,
            "lon": 6.577163797192132
          },
          {
            "lat": 57.71466260992425,
            "lon": 6.695334317489708
          }
        ]
      },
      {
        "id": "1742114089914",
        "name": "Path 89914",
        "depth": 10,
        "status": "medium",
        "type": "coastal",
        "source": "map",
        "points": [
          {
            "lat": 57.687022654856726,
            "lon": 7.648166765805829
          },
          {
            "lat": 57.68555445676063,
            "lon": 7.862522593322365
          },
          {
            "lat": 57.71784107709872,
            "lon": 8.024663539777173
          }
        ]
      },
      {
        "id": "1742114060666",
        "name": "Path 60666",
        "depth": 10,
        "status": "medium",
        "type": "coastal",
        "source": "map",
        "points": [
          {
            "lat": 57.94887326873087,
            "lon": 8.777291370086324
          },
          {
            "lat": 57.97072977619161,
            "lon": 8.876224828940101
          },
          {
            "lat": 58.01585768883243,
            "lon": 8.961417529619752
          }
        ]
      }
    ]
  }

  // Initialize map centered on the Baltic Sea region
  const map = L.map('testmap').setView([59.7, 20], 8);

  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    maxZoom: 19
  }).addTo(map);

  // Create layer groups similar to ship-leaf.js
  const routeGroup = L.layerGroup().addTo(map);

  // Add start point marker
  const startMarker = L.circleMarker([lines.startPoint.lat, lines.startPoint.lon], {
    color: 'green',
    fillColor: '#0f0',
    fillOpacity: 0.8,
    radius: 8
  }).addTo(routeGroup);
  startMarker.bindTooltip("Start Point").openTooltip();

  // Add end point marker
  const endMarker = L.circleMarker([lines.endPoint.lat, lines.endPoint.lon], {
    color: 'red',
    fillColor: '#f00',
    fillOpacity: 0.8,
    radius: 8
  }).addTo(routeGroup);
  endMarker.bindTooltip("End Point").openTooltip();

  // Add line segments with different colors
  const colors = ['blue', 'purple', 'orange'];

  lines.lines.forEach((line, index) => {
    const color = colors[index % colors.length];
    const latlngs = line.points.map(point => [point.lat, point.lon]);

    // Draw polyline for the route
    const polyline = L.polyline(latlngs, {
      color: color,
      weight: 3,
      opacity: 0.8
    }).addTo(routeGroup);

    // Add tooltips to line points
    line.points.forEach((point, ptIndex) => {
      const marker = L.circleMarker([point.lat, point.lon], {
        radius: 5,
        color: color,
        fillColor: '#fff',
        fillOpacity: 0.5,
        weight: 2
      }).addTo(routeGroup);

      marker.bindTooltip(`Line ${line.name}: Point ${ptIndex + 1}`);
    });

    // Add line info popup
    polyline.bindPopup(`<b>Line ${line.name}</b><br>Type: ${line.type}<br>Status: ${line.status}<br>Depth: ${line.depth}m`);
  });

  // Fit map to show all markers and lines
  const bounds = routeGroup.getBounds();
  map.fitBounds(bounds);
  },



    init_img() {
      // Create a button for image upload
      const uploadButton = document.createElement('button');
      uploadButton.textContent = 'Upload Image';
      uploadButton.style.margin = '10px 0';
      uploadButton.style.padding = '8px 16px';
      uploadButton.style.marginRight = '10px';

      // Create a button for loading image from API
      const apiButton = document.createElement('button');
      apiButton.textContent = 'Load Image from API';
      apiButton.style.margin = '10px 0';
      apiButton.style.padding = '8px 16px';

      // Create a hidden file input
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'image/png';
      fileInput.style.display = 'none';

      // Add the buttons and input to the page
      const imageElement = document.getElementById('2dimage');
      imageElement.parentNode.insertBefore(uploadButton, imageElement.nextSibling);
      imageElement.parentNode.insertBefore(apiButton, uploadButton.nextSibling);
      imageElement.parentNode.insertBefore(fileInput, apiButton.nextSibling);

      // Handle button click to trigger file selection
      uploadButton.addEventListener('click', () => {
        fileInput.click();
      });

      // Handle button click to load image from API
      apiButton.addEventListener('click', () => {
        load2DImage().then((data) => {
          console.log("bbbb2", data);

          // Process the image data
          let imgData = data;

          imageElement.src = data;
        }).catch(error => {
          console.error("Error loading image from API:", error);
          alert('Failed to load image from API');
        });
      });

      // Handle file selection
      fileInput.addEventListener('change', (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'image/png') {
          const reader = new FileReader();

          reader.onload = (e) => {
            // Get the base64 data
            let imgData = e.target.result;

            // Remove any leading slash if present
            if (imgData.startsWith('/')) {
              imgData = imgData.substring(1);
            }

            // Ensure proper data URL format
            if (!imgData.startsWith('data:image/png;base64,')) {
              // If it's already base64 but missing the prefix
              if (imgData.startsWith('iVBOR') || imgData.includes('base64,')) {
                // Extract just the base64 part if it has a prefix
                const base64Part = imgData.includes('base64,')
                    ? imgData.split('base64,')[1]
                    : imgData;

                imgData = 'data:image/png;base64,' + base64Part;
              }
            }

            // Update the image source
            imageElement.src = imgData;
          };

          // Read the file as a data URL
          reader.readAsDataURL(file);
        } else {
          alert('Please select a PNG image file.');
        }
      });
    },
    init_map1() {
      let line = [
        {
          "lat": 45.2155108842999,
          "lon": -16.69921875
        },
        {
          "lat": 42.8148031421006,
          "lon": -14.1943359375
        },
        {
          "lat": 45.3391700237989,
          "lon": -11.25
        },
        {
          "lat": 46.786638032645,
          "lon": -9.64599609375
        }
      ];

      let cells = [
        {
          "provider": "Primar",
          "legsInfo": [
            {
              "distanceFromStartInKm": 0,
              "mapIntersections": [
                {
                  "id": "FR166230",
                  "cuts": [
                    {
                      "start": {
                        "lat": 45.2155,
                        "lon": -16.6992
                      },
                      "end": {
                        "lat": 44.5,
                        "lon": -15.9526
                      },
                      "distanceFromLegStartInKm": 0,
                      "lengthInKm": 98.953
                    }
                  ]
                },
                {
                  "id": "PT111101",
                  "cuts": [
                    {
                      "start": {
                        "lat": 44.5,
                        "lon": -15.9526
                      },
                      "end": {
                        "lat": 42.8148,
                        "lon": -14.1943
                      },
                      "distanceFromLegStartInKm": 98.953,
                      "lengthInKm": 234.756
                    }
                  ]
                }
              ]
            },
            {
              "distanceFromStartInKm": 333.687,
              "mapIntersections": [
                {
                  "id": "ES201080",
                  "cuts": [
                    {
                      "start": {
                        "lat": 42.9814,
                        "lon": -14
                      },
                      "end": {
                        "lat": 45.3392,
                        "lon": -11.25
                      },
                      "distanceFromLegStartInKm": 24.364,
                      "lengthInKm": 341.785
                    }
                  ]
                },
                {
                  "id": "FR166230",
                  "cuts": [
                    {
                      "start": {
                        "lat": 44.5,
                        "lon": -12.2288
                      },
                      "end": {
                        "lat": 45.3392,
                        "lon": -11.25
                      },
                      "distanceFromLegStartInKm": 245.156,
                      "lengthInKm": 121.017
                    }
                  ]
                },
                {
                  "id": "PT111101",
                  "cuts": [
                    {
                      "start": {
                        "lat": 42.8148,
                        "lon": -14.1943
                      },
                      "end": {
                        "lat": 44.5,
                        "lon": -12.2288
                      },
                      "distanceFromLegStartInKm": 0,
                      "lengthInKm": 245.156
                    }
                  ]
                }
              ]
            },
            {
              "distanceFromStartInKm": 699.827,
              "mapIntersections": [
                {
                  "id": "ES201080",
                  "cuts": [
                    {
                      "start": {
                        "lat": 45.3392,
                        "lon": -11.25
                      },
                      "end": {
                        "lat": 46.7866,
                        "lon": -9.646
                      },
                      "distanceFromLegStartInKm": 0,
                      "lengthInKm": 203.004
                    }
                  ]
                },
                {
                  "id": "FR166230",
                  "cuts": [
                    {
                      "start": {
                        "lat": 45.3392,
                        "lon": -11.25
                      },
                      "end": {
                        "lat": 46.7866,
                        "lon": -9.646
                      },
                      "distanceFromLegStartInKm": 0,
                      "lengthInKm": 203.004
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]

      var map = L.map('testmap').setView([45.2155108842999, -16.69921875], 5);

      // Add OpenStreetMap tile layer

      // L.TileLayer.TokenLayer = L.TileLayer.extend({
      //   initialize: function(url, options) {
      //     this._token = options.token;
      //     L.TileLayer.prototype.initialize.call(this, url, options);
      //   },
      //   createTile: function(coords, done) {
      //     const tile = L.TileLayer.prototype.createTile.call(this, coords, done);
      //     const originalSrc = tile.src;
      //     const xhr = new XMLHttpRequest();
      //     xhr.open('GET', originalSrc, true);
      //     xhr.responseType = 'blob';
      //     xhr.setRequestHeader('Authorization', 'Bearer ' + this._token);
      //     xhr.onload = function() {
      //       if (xhr.status === 200) {
      //         tile.src = URL.createObjectURL(xhr.response);
      //       }
      //     };
      //     xhr.send();
      //     return tile;
      //   }
      // });
      //
      // L.tileLayer.tokenLayer = function(url, options) {
      //   return new L.TileLayer.TokenLayer(url, options);
      // };

      L.TileLayer.FetchTokenLayer = L.TileLayer.extend({
        initialize: function(url, options) {
          this._token = options.token;
          L.TileLayer.prototype.initialize.call(this, url, options);
        },
        createTile: function(coords, done) {
          const tile = document.createElement('img');
          tile.alt = '';
          tile.setAttribute('role', 'presentation');

          tile.onload = function() {
            done(null, tile);
          };

          tile.onerror = function(err) {
            done(err, tile);
          };

          const url = this.getTileUrl(coords);
          fetch(url, {
            headers: {
              'Authorization': 'Bearer ' + this._token
            }
          })
              .then(response => {
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
                return response.blob();
              })
              .then(blob => {
                tile.src = URL.createObjectURL(blob);
              })
              .catch(err => {
                console.error(err);
                done(err, tile);
              });

          return tile;
        }
      });

      L.tileLayer.fetchTokenLayer = function(url, options) {
        return new L.TileLayer.FetchTokenLayer(url, options);
      };

      let token = "ABCD";
      L.tileLayer.fetchTokenLayer('https://mwend.bgeo.fi/api/v1/nogotile/{z}/{x}/{y}/?depth=1', {
        token: token,
        minZoom: 4,
        maxZoom: 20
      }).addTo(map);

      // let token = "ABCD"

      // L.tileLayer('https://mwend.bgeo.fi/api/v1/nogotile/{z}/{x}/{y}/?depth=1', {}).addTo(map);

      // L.tileLayer.tokenLayer('https://mwend.bgeo.fi/api/v1/nogotile/{z}/{x}/{y}/?depth=1', {
      //   token: token
      // }).addTo(map);

      // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {}).addTo(map);

      var latlngs = line.map(point => [point.lat, point.lon]);
      var polyline = L.polyline(latlngs, {color: 'blue'}).addTo(map);

      // Add points from cells to the map
      cells.forEach(cell => {
        cell.legsInfo.forEach(leg => {
          leg.mapIntersections.forEach(intersection => {
            intersection.cuts.forEach(cut => {
              L.circleMarker([cut.start.lat, cut.start.lon], {
                radius: 6,
                fillColor: "#ff7800",
                color: "#000",
                weight: 1,
                opacity: 1,
                fillOpacity: 0.8
              }).addTo(map)
                  .bindPopup(intersection.id + " Start");

              L.circleMarker([cut.end.lat, cut.end.lon], {
                radius: 6,
                fillColor: "#ff7800",
                color: "#000",
                weight: 1,
                opacity: 1,
                fillOpacity: 0.8
              }).addTo(map)
                  .bindPopup(intersection.id + " End");
            });
          });
        });
      });

      // Fit the map to the polyline bounds
      map.fitBounds(polyline.getBounds());
    },
    init_graph() {
      let line = [
        {"lat": 45.2155108842999, "lon": -16.69921875},
        {"lat": 42.8148031421006, "lon": -14.1943359375},
        {"lat": 45.3391700237989, "lon": -11.25},
        {"lat": 46.786638032645, "lon": -9.64599609375}
      ];

      let cells = [
        {
          "legsInfo": [
            {
              "mapIntersections": [
                {
                  "id": "FR166230",
                  "cuts": [
                    {
                      "start": {
                        "lat": 45.2155,
                        "lon": -16.6992
                      },
                      "end": {
                        "lat": 44.5,
                        "lon": -15.9526
                      },
                    }
                  ]
                },
                {
                  "id": "PT111101",
                  "cuts": [
                    {
                      "start": {
                        "lat": 44.5,
                        "lon": -15.9526
                      },
                      "end": {
                        "lat": 42.8148,
                        "lon": -14.1943
                      },
                    }
                  ]
                },
                {
                  "id": "FR166230",
                  "cuts": [
                    {
                      "start": {
                        "lat": 45.2155,
                        "lon": -16.6992
                      },
                      "end": {
                        "lat": 44.5,
                        "lon": -15.9526
                      },
                    }
                  ]
                },
                {
                  "id": "PT111101",
                  "cuts": [
                    {
                      "start": {
                        "lat": 44.5,
                        "lon": -15.9526
                      },
                      "end": {
                        "lat": 42.8148,
                        "lon": -14.1943
                      },
                    }
                  ]
                }
              ]
            },
            {
              "mapIntersections": [
                {
                  "id": "ES201080",
                  "cuts": [
                    {
                      "start": {
                        "lat": 42.9814,
                        "lon": -14
                      },
                      "end": {
                        "lat": 45.3392,
                        "lon": -11.25
                      },
                    }
                  ]
                },
                {
                  "id": "FR166230",
                  "cuts": [
                    {
                      "start": {
                        "lat": 44.5,
                        "lon": -12.2288
                      },
                      "end": {
                        "lat": 45.3392,
                        "lon": -11.25
                      },
                    }
                  ]
                },
                {
                  "id": "PT111101",
                  "cuts": [
                    {
                      "start": {
                        "lat": 42.8148,
                        "lon": -14.1943
                      },
                      "end": {
                        "lat": 44.5,
                        "lon": -12.2288
                      },
                    }
                  ]
                },
                {
                  "id": "ES201080",
                  "cuts": [
                    {
                      "start": {
                        "lat": 42.9814,
                        "lon": -14
                      },
                      "end": {
                        "lat": 45.3392,
                        "lon": -11.25
                      },
                    }
                  ]
                },
                {
                  "id": "FR166230",
                  "cuts": [
                    {
                      "start": {
                        "lat": 44.5,
                        "lon": -12.2288
                      },
                      "end": {
                        "lat": 45.3392,
                        "lon": -11.25
                      },
                    }
                  ]
                },
                {
                  "id": "PT111101",
                  "cuts": [
                    {
                      "start": {
                        "lat": 42.8148,
                        "lon": -14.1943
                      },
                      "end": {
                        "lat": 44.5,
                        "lon": -12.2288
                      },
                    }
                  ]
                }
              ]
            },
            {
              "mapIntersections": [
                {
                  "id": "ES201080",
                  "cuts": [
                    {
                      "start": {
                        "lat": 45.3392,
                        "lon": -11.25
                      },
                      "end": {
                        "lat": 46.7866,
                        "lon": -9.646
                      },
                    }
                  ]
                },
                {
                  "id": "FR166230",
                  "cuts": [
                    {
                      "start": {
                        "lat": 45.3392,
                        "lon": -11.25
                      },
                      "end": {
                        "lat": 46.7866,
                        "lon": -9.646
                      },
                    }
                  ]
                },
                {
                  "id": "ES201080",
                  "cuts": [
                    {
                      "start": {
                        "lat": 45.3392,
                        "lon": -11.25
                      },
                      "end": {
                        "lat": 46.7866,
                        "lon": -9.646
                      },
                    }
                  ]
                },
                {
                  "id": "FR166230",
                  "cuts": [
                    {
                      "start": {
                        "lat": 45.3392,
                        "lon": -11.25
                      },
                      "end": {
                        "lat": 46.7866,
                        "lon": -9.646
                      },
                    }
                  ]
                }
              ]
            }
          ]
        }
      ];

      // Convert line coordinates to format for x-axis
      let xLine = line.map(point => point.lon);
      let yLine = line.map(point => point.lat);

      // Create traces for cells
      let traces = [];
      cells.forEach((cell, cellIndex) => {
        cell.legsInfo.forEach((leg, legIndex) => {
          leg.mapIntersections.forEach((intersection, intersectionIndex) => {
            let x = [];
            let y = [];
            intersection.cuts.forEach(cut => {
              x.push(cut.start.lon, cut.end.lon, null);
              y.push(cellIndex + legIndex + intersectionIndex, cellIndex + legIndex + intersectionIndex, null);
            });
            traces.push({
              x: x,
              y: y,
              mode: 'lines',
              name: intersection.id,
              line: {shape: 'linear'}
            });
          });
        });
      });

      // Add the line trace
      traces.push({
        x: xLine,
        y: yLine,
        mode: 'lines+markers',
        name: 'Line',
        line: {color: 'blue'}
      });

      var layout = {
        showlegend: true,
        hovermode: 'closest',
        xaxis: {title: 'Longitude'},
        yaxis: {title: 'Cells', autorange: 'reversed'}
      };

      Plotly.newPlot('myDiv', traces, layout, {
        responsive: true,
        displaylogo: false
      });
    },
    init_newgraph() {

      let cells = [
        {
          "provider": "Primar",
          "legsInfo": [
            {
              "distanceFromStartInKm": 0,
              "mapIntersections": [
                {
                  "id": "FR166230",
                  "cuts": [
                    {
                      "start": {
                        "lat": 45.2155,
                        "lon": -16.6992
                      },
                      "end": {
                        "lat": 44.5,
                        "lon": -15.9526
                      },
                      "distanceFromLegStartInKm": 0,
                      "lengthInKm": 98.953
                    }
                  ]
                },
                {
                  "id": "PT111101",
                  "cuts": [
                    {
                      "start": {
                        "lat": 44.5,
                        "lon": -15.9526
                      },
                      "end": {
                        "lat": 42.8148,
                        "lon": -14.1943
                      },
                      "distanceFromLegStartInKm": 98.953,
                      "lengthInKm": 234.756
                    }
                  ]
                }
              ]
            },
            {
              "distanceFromStartInKm": 333.687,
              "mapIntersections": [
                {
                  "id": "ES201080",
                  "cuts": [
                    {
                      "start": {
                        "lat": 42.9814,
                        "lon": -14
                      },
                      "end": {
                        "lat": 45.3392,
                        "lon": -11.25
                      },
                      "distanceFromLegStartInKm": 24.364,
                      "lengthInKm": 341.785
                    }
                  ]
                },
                {
                  "id": "FR166230",
                  "cuts": [
                    {
                      "start": {
                        "lat": 44.5,
                        "lon": -12.2288
                      },
                      "end": {
                        "lat": 45.3392,
                        "lon": -11.25
                      },
                      "distanceFromLegStartInKm": 245.156,
                      "lengthInKm": 121.017
                    }
                  ]
                },
                {
                  "id": "PT111101",
                  "cuts": [
                    {
                      "start": {
                        "lat": 42.8148,
                        "lon": -14.1943
                      },
                      "end": {
                        "lat": 44.5,
                        "lon": -12.2288
                      },
                      "distanceFromLegStartInKm": 0,
                      "lengthInKm": 245.156
                    }
                  ]
                }
              ]
            },
            {
              "distanceFromStartInKm": 699.827,
              "mapIntersections": [
                {
                  "id": "ES201080",
                  "cuts": [
                    {
                      "start": {
                        "lat": 45.3392,
                        "lon": -11.25
                      },
                      "end": {
                        "lat": 46.7866,
                        "lon": -9.646
                      },
                      "distanceFromLegStartInKm": 0,
                      "lengthInKm": 203.004
                    }
                  ]
                },
                {
                  "id": "FR166230",
                  "cuts": [
                    {
                      "start": {
                        "lat": 45.3392,
                        "lon": -11.25
                      },
                      "end": {
                        "lat": 46.7866,
                        "lon": -9.646
                      },
                      "distanceFromLegStartInKm": 0,
                      "lengthInKm": 203.004
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]

      function convert(data) {
        let result = []

        data[0].legsInfo.forEach(leg => {
          let fromStartDistance = leg.distanceFromStartInKm
          // console.log("aaaa aaaa", leg, leg.distanceFromStartInKm)
          leg.mapIntersections.forEach(intersection => {
            let id = intersection.id

            console.log("aaaa", intersection.cuts[0])



            let start = Number(fromStartDistance) + Number(intersection.cuts[0].distanceFromLegStartInKm)
            let finish = start + Number(intersection.cuts[0].lengthInKm)

            result.push({id:id, start:start, finish:finish} )
          })
        })

        return result
      }

      let x = convert(cells);

      // console.log("eeee12", aaa12)

      // let x = [
      //   {
      //     "id": "FR166230",
      //     "start": 0,
      //     "finish": 98.953
      //   },
      //   {
      //     "id": "PT111101",
      //     "start": 98.953,
      //     "finish": 333.709
      //   },
      //   {
      //     "id": "ES201080",
      //     "start": 358.051,
      //     "finish": 699.836
      //   },
      //   {
      //     "id": "FR166230",
      //     "start": 578.8430000000001,
      //     "finish": 699.8600000000001
      //   },
      //   {
      //     "id": "PT111101",
      //     "start": 333.687,
      //     "finish": 578.8430000000001
      //   },
      //   {
      //     "id": "ES201080",
      //     "start": 699.827,
      //     "finish": 902.831
      //   },
      //   {
      //     "id": "FR166230",
      //     "start": 699.827,
      //     "finish": 902.831
      //   }
      // ]

      let traces = [];
      let shapes = [];
      const categories = [1, 2, 3, 4, 5, 6];

// Group items by id and calculate overlaps
      let idGroups = {};
      x.forEach(item => {
        if (!idGroups[item.id]) {
          idGroups[item.id] = [];
        }
        idGroups[item.id].push(item);
      });



// Sort groups by total span
      const sortedGroups = Object.entries(idGroups).sort((a, b) => {
        const aSpan = a[1].reduce((sum, item) => sum + (item.finish - item.start), 0);
        const bSpan = b[1].reduce((sum, item) => sum + (item.finish - item.start), 0);
        return bSpan - aSpan;
      });

// Calculate y-positions considering overlaps
      let yPositions = {};
      sortedGroups.forEach(([id, ranges], groupIndex) => {
        // Base y-position based on first character of id
        const baseY = Number(7 - id.charAt(2));

        // Check for overlaps with previous groups
        let offset = 0;
        for (let i = 0; i < groupIndex; i++) {
          const prevRanges = sortedGroups[i][1];
          const hasOverlap = ranges.some(range =>
              prevRanges.some(prevRange =>
                  range.finish > prevRange.start && range.start < prevRange.finish
              )
          );
          if (hasOverlap) {
            offset += 0.1;
          }
        }

        yPositions[id] = baseY + offset;
      });

// Create traces
      x.forEach(item => {
        traces.push({
          type: 'line',
          x: [item.start, item.finish],
          y: [yPositions[item.id], yPositions[item.id]],
          mode: 'lines',
          line: {
            width: 14,
            color: 'rgba(83, 231, 115, 0.5)',
            simplify: false
          },
          name: item.id,
          hoverinfo: 'text',
          text: `${item.id} [${Math.ceil(item.start)}-${Math.floor(item.finish)}]`,
          hoverlabel: {
            bgcolor: 'rgba(83, 231, 115, 0.5)'
          }
        });

        shapes.push({
          type: 'rect',
          x0: item.start,
          x1: item.finish,
          y0: yPositions[item.id] - 0.04,
          y1: yPositions[item.id] + 0.04,
          line: {
            color: 'rgb(100, 100, 100)',
            width: 1
          },
          fillcolor: 'transparent'
        });
      });

      const layout = {
        title: 'Charts on route',
        width: 700,
        height: 300,
        shapes: shapes,
        xaxis: {
          title: 'Distance (km)',
          zeroline: false,
          showgrid: true,
          gridwidth: 1,
          gridcolor: 'rgb(233, 233, 233)',
          showspikes: true,
          spikemode: 'across',
          spikesnap: 'cursor',
          tickvals: [250, 290, 450],
          ticktext: ['1', '2', '3']
        },
        yaxis: {
          title: 'Scale',
          showgrid: false,
          tickvals: categories,
          ticktext: ['Berthing', 'Harbour', 'Approach', 'Coastal', 'General', 'Overview']
        },
        margin: {
          l: 80,
          r: 20,
          t: 20,
          b: 20
        },
        dragmode: 'pan',
        showlegend: false,
        hovermode: 'closest',
        spikedistance: -1,
        hoverdistance: -1
      };

      Plotly.newPlot('myDiv', traces, layout, {
        modeBarButtonsToRemove: ['toImage', 'lasso2d', 'zoom2d', 'select2d', 'zoomIn2d', 'zoomOut2d', 'autoScale2d'],
        displayModeBar: false,
        displaylogo: false,
        scrollZoom: true
      });


    },
    init() {

      let points = [{
        "lat": 45.2155108842999,
        "lon": -16.69921875
      },
        {
          "lat": 42.8148031421006,
          "lon": -14.1943359375
        },
        {
          "lat": 45.3391700237989,
          "lon": -11.25
        },
        {
          "lat": 46.786638032645,
          "lon": -9.64599609375
        }];
      let wpVals = [1, 2, 3]

      let aaa = [
        {
          "provider": "Primar",
          "legsInfo": [
            {
              "distanceFromStartInKm": 0,
              "mapIntersections": [
                {
                  "id": "FR166230",
                  "cuts": [
                    {
                      "start": {
                        "lat": 45.2155,
                        "lon": -16.6992
                      },
                      "end": {
                        "lat": 44.5,
                        "lon": -15.9526
                      },
                      "distanceFromLegStartInKm": 0,
                      "lengthInKm": 98.953
                    }
                  ]
                },
                {
                  "id": "PT111101",
                  "cuts": [
                    {
                      "start": {
                        "lat": 44.5,
                        "lon": -15.9526
                      },
                      "end": {
                        "lat": 42.8148,
                        "lon": -14.1943
                      },
                      "distanceFromLegStartInKm": 98.953,
                      "lengthInKm": 234.756
                    }
                  ]
                }
              ]
            },
            {
              "distanceFromStartInKm": 333.687,
              "mapIntersections": [
                {
                  "id": "ES201080",
                  "cuts": [
                    {
                      "start": {
                        "lat": 42.9814,
                        "lon": -14
                      },
                      "end": {
                        "lat": 45.3392,
                        "lon": -11.25
                      },
                      "distanceFromLegStartInKm": 24.364,
                      "lengthInKm": 341.785
                    }
                  ]
                },
                {
                  "id": "FR166230",
                  "cuts": [
                    {
                      "start": {
                        "lat": 44.5,
                        "lon": -12.2288
                      },
                      "end": {
                        "lat": 45.3392,
                        "lon": -11.25
                      },
                      "distanceFromLegStartInKm": 245.156,
                      "lengthInKm": 121.017
                    }
                  ]
                },
                {
                  "id": "PT111101",
                  "cuts": [
                    {
                      "start": {
                        "lat": 42.8148,
                        "lon": -14.1943
                      },
                      "end": {
                        "lat": 44.5,
                        "lon": -12.2288
                      },
                      "distanceFromLegStartInKm": 0,
                      "lengthInKm": 245.156
                    }
                  ]
                }
              ]
            },
            {
              "distanceFromStartInKm": 699.827,
              "mapIntersections": [
                {
                  "id": "ES201080",
                  "cuts": [
                    {
                      "start": {
                        "lat": 45.3392,
                        "lon": -11.25
                      },
                      "end": {
                        "lat": 46.7866,
                        "lon": -9.646
                      },
                      "distanceFromLegStartInKm": 0,
                      "lengthInKm": 203.004
                    }
                  ]
                },
                {
                  "id": "FR166230",
                  "cuts": [
                    {
                      "start": {
                        "lat": 45.3392,
                        "lon": -11.25
                      },
                      "end": {
                        "lat": 46.7866,
                        "lon": -9.646
                      },
                      "distanceFromLegStartInKm": 0,
                      "lengthInKm": 203.004
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]

      let aaa1 = {
        "1": [
          "FR166230",
        ],
        "2": [
          "FR166230",
          "PT111101",
        ],
        "3": [
          "FR166230",
          "PT111101",
        ],
        "4": [
          "ES201080",
          "FR166230",
          "PT111101",
        ],
      }

      let a1 = [
        {
          "id": "FR166230",
          "start": 0,
          "finish": 98.953
        },
        {
          "id": "PT111101",
          "start": 98.953,
          "finish": 333.709
        },
        {
          "id": "ES201080",
          "start": 358.051,
          "finish": 699.836
        },
        {
          "id": "FR166230",
          "start": 578.8430000000001,
          "finish": 699.8600000000001
        },
        {
          "id": "PT111101",
          "start": 333.687,
          "finish": 578.8430000000001
        },
        {
          "id": "ES201080",
          "start": 699.827,
          "finish": 902.831
        },
        {
          "id": "FR166230",
          "start": 699.827,
          "finish": 902.831
        }
      ]

      let b1 = {
        "0": [
          "FR166230",
        ],
        "98.953": [
          "FR166230",
          "PT111101"
        ],
        "333.687": [
          "PT111101"
        ],
        "333.709": [
        ],
        "358.051": [
          "ES201080"
        ],
      }


      // function convertToIntersectionMap(data) {
      //   // Store distances and their corresponding IDs
      //   let distanceMap = new Map();
      //
      //   // Process each cell's legs and intersections
      //   data.forEach(cell => {
      //     cell.legsInfo.forEach(leg => {
      //       leg.mapIntersections.forEach(intersection => {
      //         intersection.cuts.forEach(cut => {
      //           // Calculate start and end distances
      //           const startDistance = leg.distanceFromStartInKm + cut.distanceFromLegStartInKm;
      //           const endDistance = startDistance + cut.lengthInKm;
      //
      //           // Add distances to map
      //           [startDistance, endDistance].forEach(distance => {
      //             const wpNumber = Math.ceil(distance / 300);
      //             if (!distanceMap.has(wpNumber)) {
      //               distanceMap.set(wpNumber, new Set());
      //             }
      //             distanceMap.get(wpNumber).add(intersection.id);
      //           });
      //         });
      //       });
      //     });
      //   });
      //
      //   // Convert Map to object format
      //   let result = {};
      //   distanceMap.forEach((ids, wpNumber) => {
      //     result[wpNumber] = Array.from(ids);
      //   });
      //
      //   return result;
      // }

      function convert(data) {
        let result = []

        data[0].legsInfo.forEach(leg => {
          let fromStartDistance = leg.distanceFromStartInKm
          // console.log("aaaa aaaa", leg, leg.distanceFromStartInKm)
          leg.mapIntersections.forEach(intersection => {
            let id = intersection.id

            console.log("aaaa", intersection.cuts[0])



            let start = Number(fromStartDistance) + Number(intersection.cuts[0].distanceFromLegStartInKm)
            let finish = start + Number(intersection.cuts[0].lengthInKm)

            result.push({id:id, start:start, finish:finish} )
          })
        })

        return result
      }

      let aaa12 = convert(aaa);

      function createDistanceMap(data) {
        // Create a map to store unique distances and their IDs
        let distanceMap = new Map();

        // Collect all unique distances and their corresponding IDs
        data.forEach(item => {
          // Add start distance
          if (!distanceMap.has(item.start)) {
            distanceMap.set(item.start, new Set());
          }
          distanceMap.get(item.start).add(item.id);

          // Add finish distance
          if (!distanceMap.has(item.finish)) {
            distanceMap.set(item.finish, new Set());
          }
          distanceMap.get(item.finish).add(item.id);
        });

        // Convert Map to object with sorted keys
        let result = {};
        Array.from(distanceMap.keys())
            .sort((a, b) => a - b)
            .forEach(distance => {
              result[distance] = Array.from(distanceMap.get(distance));
            });

        return result;
      }

      console.log("aaaa aaaa1", aaa12)

      aaa1 = createDistanceMap(aaa12);

      console.log("aaaa aaaa2", aaa1)

      aaa1 = {
        "0": [
          "FR166230"
        ],
        "98.953": [
          "FR166230",
          "PT111101"
        ],
        "333.687": [
          "PT111101"
        ],
        "333.709": [
          "PT111101"
        ],
        "358.051": [
          "ES201080"
        ],
        "578.8430000000001": [
          "FR166230",
          "PT111101"
        ],
        "699.827": [
          "ES201080",
          "FR166230"
        ],
        "699.836": [
          "ES201080"
        ],
        "699.8600000000001": [
          "FR166230"
        ],
        "902.831": [
          "ES201080",
          "FR166230"
        ]
      }

      let x = [
        {
          "id": "FR166230",
          "start": 0,
          "finish": 98.953
        },
        {
          "id": "PT111101",
          "start": 98.953,
          "finish": 333.709
        },
        {
          "id": "ES201080",
          "start": 358.051,
          "finish": 699.836
        },
        {
          "id": "FR166230",
          "start": 578.8430000000001,
          "finish": 699.8600000000001
        },
        {
          "id": "PT111101",
          "start": 333.687,
          "finish": 578.8430000000001
        },
        {
          "id": "ES201080",
          "start": 699.827,
          "finish": 902.831
        },
        {
          "id": "FR166230",
          "start": 699.827,
          "finish": 902.831
        }
      ]








      // aaa1 = {
      //   "1": [
      //     "SE100001",
      //   ],
      //   "2": [
      //     "SE100001",
      //     "SE100002"
      //   ],
      //   "3": [
      //     "SE100001",
      //     "SE100002",
      //     "SE400004"
      //   ],
      //   "4": [
      //     "DE3NO008",
      //   ],
      // }

      let bbb = [];
      let occurrences = {};

      // Collect occurrences of each string
      for (let key in aaa1) {
        let numKey = parseInt(key);
        aaa1[key].forEach(item => {
          if (!occurrences[item]) {
            occurrences[item] = [];
          }
          occurrences[item].push(numKey);
        });
      }

      let cats = ['Berthing', 'Harbour', 'Approach', 'Coastal', 'General', 'Overview'];

      // Process occurrences to create bbb
      for (let name in occurrences) {
        let ranges = [];
        let currentRange = {start: null, end: null, name: 'overview', y: 1};
        let occurrenceList = occurrences[name].sort((a, b) => a - b); // Sort numerically

        let yOffset = 0; // Initialize yOffset for overlapping bars

        occurrenceList.forEach(occurrence => {
          if (currentRange.start === null) {
            currentRange = {start: occurrence, end: occurrence};
          } else if (occurrence === currentRange.end + 1) {
            currentRange.end = occurrence;
          } else {
            ranges.push(currentRange);
            currentRange = {start: occurrence, end: occurrence};
            // yOffset += 0.1; // Increment yOffset for each new range
          }

          currentRange.y = Number(7 - name.charAt(2)) + yOffset; // Apply yOffset to y value
          currentRange.color = 'rgba(83, 231, 115, 0.5)'
        });

        ranges.push(currentRange);

        ranges.forEach(range => {
          bbb.push({
            name: name,
            data: [range],
          });
        });
      }

      console.log("aaa bbb", bbb)



      bbb.forEach((item, index) => {
        let start = Number(item.data[0].start);
        let end = Number(item.data[0].end);

        if (end - start === 0) {
          item.data[0].start = start - 0.1;
          item.data[0].end = end + 0.1;
          item.data[0].len = 1;
        } else {
          item.data[0].start = start + 0.15;
          item.data[0].end = end - 0.15;
          item.data[0].len = end - start + 1;
        }
      });

      const categories = [1, 2, 3, 4, 5, 6];
      let traces = [];

      categories.forEach(category => {
        const categoryData = bbb.filter(item => item.data[0].y === category);

        // Group lines by name and calculate their total span
        const lineSpans = {};
        categoryData.forEach(item => {
          if (!lineSpans[item.name]) {
            lineSpans[item.name] = {
              ranges: [],
              totalSpan: 0
            };
          }
          lineSpans[item.name].ranges.push([item.data[0].start, item.data[0].end]);
          lineSpans[item.name].totalSpan += item.data[0].end - item.data[0].start;
        });

        // Sort lines by total span (descending)
        const sortedLines = Object.entries(lineSpans).sort((a, b) => b[1].totalSpan - a[1].totalSpan);

        // Assign yOffset based on overlaps
        const lineOffsets = {};
        sortedLines.forEach(([lineName, lineData], index) => {
          if (index === 0) {
            lineOffsets[lineName] = 0; // widest line gets 0 offset
            return;
          }

          // Check for overlaps with previously processed lines
          let maxOffset = 0;
          for (let i = 0; i < index; i++) {
            const prevLine = sortedLines[i];
            const hasOverlap = lineData.ranges.some(range =>
                prevLine[1].ranges.some(prevRange =>
                    // Changed overlap condition: now true only for actual overlaps, not just touching points
                    range[1] > prevRange[0] && range[0] < prevRange[1]
                )
            );
            if (hasOverlap) {
              maxOffset = Math.max(maxOffset, lineOffsets[prevLine[0]] + 0.1);
            }
          }
          lineOffsets[lineName] = maxOffset;
        });

        // Create traces with calculated offsets
        categoryData.forEach(item => {
          console.log("aaaa12", item.name, item.data[0], item)

          let text = ' (in folio)'
          if (item.data[0].color === 'rgba(74,148,255,0.5)')
            text = ' (selected)'
          if (item.data[0].color === 'rgba(244, 156, 74, 0.5)')
            text = ' (unselected)'

          let wpStart = Math.ceil(item.data[0].start)
          let wpEnd = Math.floor(item.data[0].end)

          if (wpEnd < wpStart) [wpStart, wpEnd] = [wpEnd, wpStart]

          let wpInterval = wpEnd === wpStart ? ' [' + wpStart + ']' : ' [' + wpStart + '-' + wpEnd + ']'

          traces.push({
            type: 'line',
            x: [item.data[0].start, item.data[0].end],
            y: [category + lineOffsets[item.name], category + lineOffsets[item.name]],
            mode: 'lines+markers',
            line: {width: 14, color: item.data[0].color, simplify: false},
            name: item.name,
            hoverinfo: 'text',
            text: item.name + wpInterval + text,

            hoverlabel: {bgcolor: item.data[0].color},

          });
        });
      });


      let traces12 = [
        {
          "x": [
            2.9,
            3.1
          ],
          "y": [
            2,
            2
          ],
          "mode": "lines",
          "line": {
            "width": 10,
            "color": "rgba(244, 156, 74, 0.5)",
            "simplify": false
          },
          "name": "SE400004",
          "hoverinfo": "text",
          "text": "SE400004 (2.9 - 3.1)"
        },
        {
          "x": [
            4,
            5
          ],
          "y": [
            3,
            3
          ],
          "mode": "lines",
          "line": {
            "width": 10,
            "color": "rgba(244, 156, 74, 0.5)",
            "simplify": false
          },
          "name": "DE3NO008",
          "hoverinfo": "text",
          "text": "DE3NO008 (4 - 5)"
        },
        {
          "x": [
            1,
            3
          ],
          "y": [
            5,
            5
          ],
          "mode": "lines",
          "line": {
            "width": 10,
            "color": "rgba(244, 156, 74, 0.5)",
            "simplify": false
          },
          "name": "SE100001",
          "hoverinfo": "text",
          "text": "SE100001 (1 - 3)"
        },
        {
          "x": [
            5,
            6
          ],
          "y": [
            5,
            5
          ],
          "mode": "lines",
          "line": {
            "width": 10,
            "color": "rgba(244, 156, 74, 0.5)",
            "simplify": false
          },
          "name": "SE100001",
          "hoverinfo": "text",
          "text": "SE100001 (5 - 6)"
        },
        {
          "x": [
            2,
            3
          ],
          "y": [
            5.1,
            5.1
          ],
          "mode": "lines",
          "line": {
            "width": 10,
            "color": "rgba(244, 156, 74, 0.5)",
            "simplify": false
          },
          "name": "SE100002",
          "hoverinfo": "text",
          "text": "SE100002 (2 - 3)"
        }
      ]

      const layout = {
        title: 'Charts on route',
        width: 700,
        height: 300,
        xaxis: {
          title: 'Band',
          zeroline: false,
          showgrid: true,
          range: [0, null],
          gridwidth: 1,
          gridcolor: 'rgb(233, 233, 233)',
          dtick: 1,
          tickvals: wpVals,
          showspikes: true,
          spikemode: 'across',
          spikesnap: 'cursor'
        },
        yaxis: {
          title: 'Waypoints',
          // autorange: 'reversed',
          showgrid: false,
          gridwidth: 1,
          gridcolor: 'rgb(233, 233, 233)',
          tickvals: categories,
          ticktext: cats,
          // ticktext: cats.map((_, index) => cats[index + 1] || cats[index]),
        },
        // hovermode: 'x unified',
        // hoverdistance: 1,
        margin: {
          l: 80,
          r: 20,
          t: 20,
          b: 20
        },
        dragmode: 'pan',
        barmode: 'overlay',
        showlegend: false,
        bargap: 0.3,
        hovermode: 'closest',
        spikedistance: -1,
        hoverdistance: -1,
      };


      const config = {
        responsive: false,
        displayModeBar: true,
        displaylogo: false
      };

      const graphDiv = document.getElementById('myDiv');

      Plotly.newPlot('myDiv', traces, layout, {
        modeBarButtonsToRemove: ['toImage', 'lasso2d', 'zoom2d', 'select2d', 'zoomIn2d', 'zoomOut2d', 'autoScale2d'],
        displayModeBar: false,
        displaylogo: false,
        scrollZoom: true
      });

      graphDiv.on('plotly_click', function (data) {
        console.log("Clicked chart name:", data);
        console.log("Clicked chart name:", data.points[0].data.name);
      });

    }
  },
}
</script>