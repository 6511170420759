<template>
  <div>
    <h1>Reactivity isssue</h1>
    {{test}}
  </div>
</template>

<script>

export default {
  name: "Test1",
  data() {
    return {
      test: '1'
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init()
    });

  },
  methods: {
    init() {
      setTimeout(() => {
        this.test = '2'
      }, 1000)
    }
  },
}
</script>