// Create a new file: src/components/SplashScreen.vue
<template>
  <div class="splash-container" v-show="visible">
    <img src="/dKart.png" alt="dKart Logo" class="logo-image"/>
  </div>
</template>

<script>
export default {
  name: 'SplashScreen',
  props: {
    visible: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>
.splash-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.logo-image {
  max-width: 100%;
  max-height: 100%;
}
</style>