import L from "leaflet";
import {NavLeaf} from "../base/nav-leaf";
import '../styles/route-styles.css'
import "../extensions/leaflet-search/leaflet-search.min.js"
import "../extensions/leaflet-search/leaflet-search.min.css"
import "leaflet-polylinedecorator";
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/regular'
import {eventBus} from "@/main";

export class EditorLeaf extends NavLeaf {
    editorPatchesGroup = null
    editorTempGroup = null
    editorLinksGroup = null
    editorMarkersGroup = null
    drawingMode = false
    currentPoints = []
    tempLine = null
    tempMarker = null

    constructor() {
        super();
    }

    async init(el) {
        super.init(el);

        // Create layer groups
        this.editorTempGroup = L.layerGroup().addTo(this.map);
        this.editorPatchesGroup = L.layerGroup().addTo(this.map);
        this.editorLinksGroup = L.layerGroup().addTo(this.map);
        this.editorMarkersGroup = L.layerGroup().addTo(this.map);

        // Add draw button
        this.addDrawControl();

        // Load existing patches
        this.loadPatches();
        this.loadLinks();

        // Setup map event handlers
        this.setupMapEvents();
    }

    setupMapEvents() {
        this.map.on('click', (e) => {
            if (!this.drawingMode) return;

            if (this.drawingMode === 'patch') {
                this.addPoint(e.latlng);
            } else if (this.drawingMode === 'link') {
                this.addLinkPoint(e.latlng);
            }
        });

        this.map.on('contextmenu', (e) => {
            if (this.drawingMode === 'patch' && this.currentPoints.length > 1) {
                this.finishDrawing();
            } else if (this.drawingMode === 'link' && this.currentPoints.length > 1) {
                this.finishLinkDrawing();
            } else if (!this.drawingMode) {
                this.checkLineClick(e);
            }
        });

        this.map.on('mousemove', (e) => {
            if (!this.drawingMode || this.currentPoints.length === 0) return;

            if (this.drawingMode === 'patch') {
                this.updateTempLine(e.latlng);
            } else if (this.drawingMode === 'link') {
                this.updateTempLinkLine(e.latlng);
            }
        });
    }

    addDrawControl() {
        // Draw patches button (original)
        const drawPatchButton = L.control({position: 'topleft'});
        drawPatchButton.onAdd = () => {
            const div = L.DomUtil.create('div', 'leaflet-control leaflet-bar');
            div.innerHTML = '<a href="#" id="draw-patch-btn" title="Draw Line" style="font-weight: bold;">📏</a>';

            div.onclick = (e) => {
                e.preventDefault();
                L.DomEvent.stopPropagation(e);
                this.toggleDrawingMode('patch');
            };
            return div;
        };
        drawPatchButton.addTo(this.map);

        // Draw links button (new)
        const drawLinkButton = L.control({position: 'topleft'});
        drawLinkButton.onAdd = () => {
            const div = L.DomUtil.create('div', 'leaflet-control leaflet-bar');
            div.innerHTML = '<a href="#" id="draw-link-btn" title="Draw Link" style="font-weight: bold; color: blue;">🔗</a>';

            div.onclick = (e) => {
                e.preventDefault();
                L.DomEvent.stopPropagation(e);
                this.toggleDrawingMode('link');
            };
            return div;
        };
        drawLinkButton.addTo(this.map);
    }

    toggleDrawingMode(mode = 'patch') {
        // If already in the same mode, toggle off
        if (this.drawingMode === mode) {
            this.drawingMode = false;
            this.map.getContainer().style.cursor = '';
        } else {
            // Switch to new mode
            this.drawingMode = mode;
            this.map.getContainer().style.cursor = 'crosshair';
        }

        // Reset drawing state
        this.currentPoints = [];
        this.removeTempElements();

        // Update button appearance
        this.updateDrawButtonState();
    }

    removeTempElements() {
        if (this.tempLine) {
            this.map.removeLayer(this.tempLine);
            this.tempLine = null;
        }
        if (this.tempMarker) {
            this.map.removeLayer(this.tempMarker);
            this.tempMarker = null;
        }
    }

    updateDrawButtonState() {
        const patchButtonElement = document.getElementById('draw-patch-btn');
        const linkButtonElement = document.getElementById('draw-link-btn');

        if (patchButtonElement) {
            patchButtonElement.style.backgroundColor = this.drawingMode === 'patch' ? '#f4a0a0' : '#ffffff';
            patchButtonElement.style.color = this.drawingMode === 'patch' ? '#000' : '#000';
            patchButtonElement.title = this.drawingMode === 'patch' ? 'Cancel Drawing' : 'Draw Path';
        }

        if (linkButtonElement) {
            linkButtonElement.style.backgroundColor = this.drawingMode === 'link' ? '#a0a0f4' : '';
            linkButtonElement.style.color = this.drawingMode === 'link' ? '#000' : '';
            linkButtonElement.title = this.drawingMode === 'link' ? 'Cancel Drawing' : 'Draw Link';
        }
    }

    addPoint(latlng) {
        this.currentPoints.push(latlng);

        // Create marker for the point
        let marker;
        if (this.currentPoints.length === 1) {
            // First point - create "From" marker
            marker = L.circleMarker(latlng, {
                radius: 4,
                fillColor: "#ff0b0b",
                color: "#000000",
                weight: 1,
                opacity: 1,
                fillOpacity: 1
            });
        } else {
            // Intermediate point - create small marker
            marker = L.circleMarker(latlng, {
                radius: 4,
                fillColor: "#000",
                color: "#000",
                weight: 1,
                opacity: 1,
                fillOpacity: 1
            });
        }

        marker.addTo(this.editorMarkersGroup);

        // Update line if we have multiple points
        if (this.currentPoints.length > 1) {
            if (this.tempLine) {
                this.map.removeLayer(this.tempLine);
            }

            L.polyline(this.currentPoints, {
                color: 'red',
                weight: 3
            }).addTo(this.editorTempGroup);
        }
    }

    updateTempLine(latlng) {
        if (this.currentPoints.length === 0) return;

        if (this.tempLine) {
            this.map.removeLayer(this.tempLine);
        }

        const points = [...this.currentPoints, latlng];
        this.tempLine = L.polyline(points, {
            color: 'black',
            weight: 3,
            dashArray: '5, 10',
            opacity: 0.6
        }).addTo(this.map);
    }

    finishDrawing() {
        if (this.currentPoints.length < 2) return;

        // Remove temporary line
        this.removeTempElements();

        // Create a unique ID for the line
        const lineId = Date.now().toString();

        // Prompt for line details
        this.promptLineDetails(lineId);

        // Reset drawing state
        // this.currentPoints = [];
        this.drawingMode = false;
        this.map.getContainer().style.cursor = '';

        // Update draw button state
        this.updateDrawButtonState();
    }

    addLinkPoint(latlng) {
        // For the first point, find the nearest patch end point
        if (this.currentPoints.length === 0) {
            const nearestPoint = this.findNearestPatchEndpoint(latlng, 'end');
            if (nearestPoint) {
                latlng = nearestPoint;
            }
        }

        this.currentPoints.push(latlng);

        // Create marker for the point
        const marker = L.circleMarker(latlng, {
            radius: 4,
            fillColor: "#0000ff",
            color: "#0000ff",
            weight: 1,
            opacity: 1,
            fillOpacity: 1
        });

        marker.addTo(this.editorMarkersGroup);

        // Update line if we have multiple points
        if (this.currentPoints.length > 1) {
            if (this.tempLine) {
                this.map.removeLayer(this.tempLine);
            }

            L.polyline(this.currentPoints, {
                color: 'green',
                weight: 3,
                dashArray: '5, 5'
            }).addTo(this.editorTempGroup);
        }
    }

    updateTempLinkLine(latlng) {
        this.map.removeLayer(this.editorTempGroup)

        if (this.currentPoints.length === 0) return;

        if (this.tempLine) {
            this.map.removeLayer(this.tempLine);
        }

        const points = [...this.currentPoints, latlng];
        this.tempLine = L.polyline(points, {
            color: 'blue',
            weight: 3,
            dashArray: '5, 5',
            opacity: 0.6
        }).addTo(this.map);
    }

    finishLinkDrawing() {
        if (this.currentPoints.length < 2) return;

        // Find the nearest patch start point for the true last position
        const lastPointIndex = this.currentPoints.length - 1;
        const lastPoint = this.currentPoints[lastPointIndex];
        const nearestStartPoint = this.findNearestPatchEndpoint(lastPoint, 'start');

        if (nearestStartPoint) {
            // Replace the last point with the nearest start point
            this.currentPoints[lastPointIndex] = nearestStartPoint;

            // Also update the marker position if it exists
            this.editorMarkersGroup.eachLayer(layer => {
                // Check if this is the last marker (no reliable way to identify it directly)
                // So we compare coordinates
                const markerLatLng = layer.getLatLng();
                if (Math.abs(markerLatLng.lat - lastPoint.lat) < 0.000001 &&
                    Math.abs(markerLatLng.lng - (lastPoint.lng || lastPoint.lon)) < 0.000001) {
                    // Update marker position
                    layer.setLatLng(nearestStartPoint);
                }
            });
        }

        // Remove temporary line
        this.removeTempElements();

        // Create a unique ID for the link
        const linkId = Date.now().toString();

        // Create link data
        const linkData = {
            id: linkId,
            name: 'Link ' + linkId.substring(linkId.length - 5),
            points: this.currentPoints.map(point => ({
                lat: point.lat,
                lon: point.lng || point.lon
            }))
        };

        // Add to store
        this.editorStore.addLink(linkData);

        // Render the link
        this.renderLink(linkData);

        // Reset drawing state
        this.currentPoints = [];
        this.drawingMode = false;
        this.map.getContainer().style.cursor = '';

        // Update button appearance
        this.updateDrawButtonState();
    }

    findNearestPatchEndpoint(latlng, pointType) {
        let nearestPoint = null;
        let minDistance = Infinity;

        // Get all patches
        const patches = this.editorStore.getPatches;

        patches.forEach(patch => {
            if (!patch.points || patch.points.length === 0) return;

            // Get the reference point based on pointType
            let refPoint;
            if (pointType === 'start') {
                refPoint = patch.points[0];  // First point of patch
            } else if (pointType === 'end') {
                refPoint = patch.points[patch.points.length - 1];  // Last point of patch
            }

            if (!refPoint) return;

            // Calculate distance
            const distance = this.calculateDistance(
                latlng.lat,
                latlng.lng || latlng.lon,
                refPoint.lat,
                refPoint.lon
            );

            // Update nearest point if this one is closer
            if (distance < minDistance) {
                minDistance = distance;
                nearestPoint = L.latLng(refPoint.lat, refPoint.lon);
            }
        });

        // Always return the nearest point without distance threshold
        // This ensures both first and last points of links always snap
        return nearestPoint;
    }

    calculateDistance(lat1, lon1, lat2, lon2) {
        // Simple Euclidean distance, sufficient for small areas
        const R = 6371000; // Earth radius in meters
        const dLat = this.toRadians(lat2 - lat1);
        const dLon = this.toRadians(lon2 - lon1);

        const a =
            Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(this.toRadians(lat1)) * Math.cos(this.toRadians(lat2)) *
            Math.sin(dLon/2) * Math.sin(dLon/2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        return R * c;
    }

    toRadians(degrees) {
        return degrees * Math.PI / 180;
    }

    pixelsToMeters(pixels) {
        // Convert pixels to meters based on current map zoom
        const currentZoom = this.map.getZoom();
        const metersPerPixel = 40075016.686 * Math.abs(Math.cos(this.map.getCenter().lat * Math.PI/180)) / Math.pow(2, currentZoom+8);
        return pixels * metersPerPixel;
    }

    renderLink(link) {
        if (!link.points || link.points.length < 2) return;

        // Convert points to LatLng objects
        const latLngs = link.points.map(point => L.latLng(point.lat, point.lon));

        // Create polyline
        const polyline = L.polyline(latLngs, {
            color: 'blue',
            weight: 2,
            dashArray: '5, 5',
            opacity: 0.8,
            linkId: link.id
        }).addTo(this.editorLinksGroup);

        // Add context menu for deletion
        polyline.on('contextmenu', (e) => {
            if (confirm('Delete this link?')) {
                this.deleteLink(link.id);
            }
        });
    }

    deleteLink(linkId) {
        console.log("Deleting link:", linkId);

        // STEP 1: Remove all layers associated with this linkId
        this.editorLinksGroup.eachLayer(layer => {
            if ((layer.options && layer.options.linkId === linkId) ||
                layer.linkId === linkId) {
                this.editorLinksGroup.removeLayer(layer);
            }
        });

        // STEP 2: Remove any markers associated with the link
        this.editorMarkersGroup.eachLayer(layer => {
            if ((layer.options && layer.options.linkId === linkId) ||
                layer.linkId === linkId) {
                this.editorMarkersGroup.removeLayer(layer);
            }
        });

        // STEP 3: Update the store
        this.editorStore.removeLink(linkId);
        console.log("Link removal completed");
    }

    // Update in editor-leaf.js
    promptLineDetails(lineId) {
        // Prepare initial values with a sensible default name
        const initialValues = {
            name: 'Path ' + lineId.substring(lineId.length - 5),
            depth: 10,
            status: 'medium',
            type: 'coastal',
            source: 'map'
        };

        // Create a promise to handle the async dialog interaction
        return new Promise((resolve, reject) => {
            // Set up event listeners for dialog response
            const handleSubmit = (formData) => {
                eventBus.$off('line-details:submit', handleSubmit);
                eventBus.$off('line-details:cancel', handleCancel);

                // Create patch data
                const patchData = {
                    id: lineId,
                    name: formData.name,
                    depth: parseFloat(formData.depth) || 0,
                    status: formData.status,
                    type: formData.type,
                    source: formData.source,
                    points: this.currentPoints.map(point => ({
                        lat: point.lat,
                        lon: point.lng
                    }))
                };

                // Clear temporary markers
                this.clearTempMarkers();

                // Add to store and render
                this.editorStore.addPatch(patchData);
                this.renderPatch(patchData);

                // Reset drawing state
                this.currentPoints = [];
                this.drawingMode = false;
                this.map.getContainer().style.cursor = '';

                resolve(patchData);
            };

            const handleCancel = () => {
                eventBus.$off('line-details:submit', handleSubmit);
                eventBus.$off('line-details:cancel', handleCancel);

                // Just reset drawing without saving
                this.currentPoints = [];
                this.drawingMode = false;
                this.map.getContainer().style.cursor = '';

                reject(new Error('User cancelled'));
            };

            // Register event listeners
            eventBus.$on('line-details:submit', handleSubmit);
            eventBus.$on('line-details:cancel', handleCancel);

            // Show the dialog by emitting an event
            eventBus.$emit('show-line-details-dialog', initialValues);
        }).catch(err => {
            console.log('Dialog cancelled or error:', err);
        });
    }

    clearTempMarkers() {
        this.editorMarkersGroup.eachLayer(layer => {
            if (!layer.options.patchId) {
                this.editorMarkersGroup.removeLayer(layer);
            }
        });
    }

    loadPatches() {
        const patches = this.editorStore.getPatches;
        patches.forEach(patch => this.renderPatch(patch));
    }

    renderPatch(patch) {
        if (!patch.points || patch.points.length < 2) return;

        this.editorTempGroup.clearLayers();

        // Convert points to LatLng objects
        const latLngs = patch.points.map(point => L.latLng(point.lat, point.lon));

        // Create polyline
        const polyline = L.polyline(latLngs, {
            color: 'black',
            weight: 3,
            patchId: patch.id
        }).addTo(this.editorPatchesGroup);

        // Add arrow decorations
        const decorator = L.polylineDecorator(polyline, {
            patterns: [
                {
                    offset: '25%',
                    repeat: '50%',
                    symbol: L.Symbol.arrowHead({
                        pixelSize: 12,
                        polygon: true,
                        pathOptions: {
                            fillOpacity: 1,
                            weight: 0,
                            color: 'black'
                        }
                    })
                }
            ]
        }).addTo(this.editorPatchesGroup);

        decorator.options.patchId = patch.id;

        // Add context menu for deletion
        polyline.on('contextmenu', (e) => {
            if (confirm('Delete this line?')) {
                this.deletePatch(patch.id);
            }
        });

        // Add From marker
        this.addFromMarker(latLngs[0], patch.id);

        // Add intermediate points
        for (let i = 1; i < latLngs.length - 1; i++) {
            L.circleMarker(latLngs[i], {
                radius: 4,
                fillColor: "#000",
                color: "#000",
                weight: 1,
                opacity: 1,
                fillOpacity: 1,
                patchId: patch.id
            }).addTo(this.editorMarkersGroup);
        }

        // Add To marker
        this.addToMarker(latLngs[latLngs.length - 1], patch.id);
    }

    addFromMarker(position, patchId) {
        const marker = L.circleMarker(position, {
            radius: 4,
            fillColor: "#ff0b0b", // Default red color
            color: "#000000",
            weight: 1,
            opacity: 1,
            fillOpacity: 1,
            patchId: patchId,
            isSelected: false // Track selection state
        }).addTo(this.editorMarkersGroup);

        // Add right-click handler for selection
        marker.on('contextmenu', (e) => {
            // Toggle selection state
            marker.options.isSelected = !marker.options.isSelected;
            const isSelected = marker.options.isSelected;

            // Change marker color
            marker.setStyle({
                fillColor: isSelected ? "#00ff00" : "#ff0b0b"
            });

            // Change color of all related polylines directly
            this.editorPatchesGroup.eachLayer(layer => {
                if (layer.options && layer.options.patchId === patchId) {
                    // Handle regular polyline
                    if (layer instanceof L.Polyline) {
                        // Apply color directly without checking instance type
                        layer.setStyle({
                            color: isSelected ? 'green' : 'black'
                        });
                    }
                }
            });

            // Handle arrow decorators separately - remove and recreate them
            let decoratorsToRemove = [];
            this.editorPatchesGroup.eachLayer(layer => {
                // Check if it's a polyline decorator
                if (layer.options &&
                    layer.options.patchId === patchId &&
                    layer.options.patterns &&
                    layer.options.patterns[0] &&
                    layer.options.patterns[0].symbol) {
                    decoratorsToRemove.push(layer);
                }
            });

            // Remove the decorators
            decoratorsToRemove.forEach(decorator => {
                this.editorPatchesGroup.removeLayer(decorator);
            });

            // Find the polyline to decorate
            let targetPolyline = null;
            this.editorPatchesGroup.eachLayer(layer => {
                if (layer.options &&
                    layer.options.patchId === patchId &&
                    layer instanceof L.Polyline &&
                    !layer.options.patterns) {
                    targetPolyline = layer;
                }
            });

            // Create new decorator if we found the polyline
            if (targetPolyline) {
                const decorator = L.polylineDecorator(targetPolyline, {
                    patterns: [
                        {
                            offset: '25%',
                            repeat: '50%',
                            symbol: L.Symbol.arrowHead({
                                pixelSize: 12,
                                polygon: true,
                                pathOptions: {
                                    fillOpacity: 1,
                                    weight: 0,
                                    color: isSelected ? 'green' : 'black'
                                }
                            })
                        }
                    ]
                }).addTo(this.editorPatchesGroup);
                decorator.options.patchId = patchId;
            }

            // Update the selection in store
            if (isSelected) {
                this.editorStore.addToExportSelection(patchId);
            } else {
                this.editorStore.removeFromExportSelection(patchId);
            }

            L.DomEvent.stop(e);
        });

        return marker;
    }

    updateLineColor(patchId, color) {
        // Update regular polylines
        this.editorPatchesGroup.eachLayer(layer => {
            if (layer.options && layer.options.patchId === patchId) {
                if (layer instanceof L.Polyline && !(layer instanceof L.Polyline.Decorator)) {
                    layer.setStyle({ color: color });
                }
            }
        });

        // Update arrow decorators - need to remove and recreate
        let decoratorsToRemove = [];
        let polylinesToDecorate = [];

        // First identify all decorators to remove and polylines to redecorate
        this.editorPatchesGroup.eachLayer(layer => {
            if (layer.options && layer.options.patchId === patchId) {
                if (layer instanceof L.Polyline.Decorator) {
                    decoratorsToRemove.push(layer);
                } else if (layer instanceof L.Polyline && !(layer instanceof L.Polyline.Decorator)) {
                    polylinesToDecorate.push(layer);
                }
            }
        });

        // Remove old decorators
        decoratorsToRemove.forEach(decorator => {
            this.editorPatchesGroup.removeLayer(decorator);
        });

        // Create new decorators with updated color
        polylinesToDecorate.forEach(polyline => {
            const decorator = L.polylineDecorator(polyline, {
                patterns: [
                    {
                        offset: '25%',
                        repeat: '50%',
                        symbol: L.Symbol.arrowHead({
                            pixelSize: 12,
                            polygon: true,
                            pathOptions: {
                                fillOpacity: 1,
                                weight: 0,
                                color: color
                            }
                        })
                    }
                ]
            }).addTo(this.editorPatchesGroup);

            decorator.options.patchId = patchId;
        });
    }

    addToMarker(position, patchId) {
        return L.circleMarker(position, {
            radius: 4,
            fillColor: "#000000",
            color: "#000000",
            weight: 1,
            opacity: 1,
            fillOpacity: 1,
            patchId: patchId,
        }).addTo(this.editorMarkersGroup);
    }

    deletePatch(patchId) {
        // Remove all layers associated with this patchId
        // this.editorMarkersGroup.clearLayers();
        // this.editorPatchesGroup.clearLayers();

        // this.editorMarkersGroup.clearLayers();
        // this.editorPatchesGroup.clearLayers();
        // return
        //
        // console.log(patchId)
        // console.log(this.editorMarkersGroup)
        // console.log(this.editorPatchesGroup)

        // console.log("a", patchId)
        // this.editorPatchesGroup.eachLayer(layer => {
        //     console.log("b", layer.options.patchId)
        //     if (layer.options.patchId === patchId) {
        //         console.log("c")
        //         this.editorPatchesGroup.removeLayer(layer);
        //     }
        // })
        //
        // return

        [this.editorPatchesGroup, this.editorMarkersGroup].forEach(group => {
            group.eachLayer(layer => {
                if (layer.options.patchId === patchId) {
                    group.removeLayer(layer);
                }
            });
        });

        // Update the store
        const patches = this.editorStore.getPatches.filter(patch => patch.id !== patchId);
        this.editorStore.$patch({patches});
    }

    loadData() {
        this.clearData();
        this.loadPatches();
        this.loadLinks();
    }

    loadLinks() {
        const links = this.editorStore.getLinks;
        links.forEach(link => this.renderLink(link));
    }

    clearData() {
        this.editorTempGroup.clearLayers();
        this.editorPatchesGroup.clearLayers();
        this.editorMarkersGroup.clearLayers();
        this.editorLinksGroup.clearLayers();
    }

    // Placeholder for the line click handler
    checkLineClick(e) {
        // Implementation for handling right-click on lines if needed
    }
}