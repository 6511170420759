<script setup>
</script>

<template>
  <div>

    <b-modal v-model="showRouteNameModal" title="Route name" @ok="handleRouteNameOk" ok-only>
      <b-form-group label="Route name:" label-for="route-name-input">
        <b-form-input
            id="route-name-input"
            v-model="routeNameInput"
            placeholder="Enter route name"
        ></b-form-input>
      </b-form-group>
    </b-modal>

    <b-container>
      <b-overlay :show="preparingPassagePlan" rounded="sm" spinner-variant="danger">

        <template #overlay>
          <br><br>
          <div class="text-center">
            <b-icon icon="hourglass" variant="warning" font-scale="2" animation="spin"></b-icon>
            <br>
            <b-badge variant="warning">Please wait...</b-badge>
          </div>
        </template>

        <b-button size="sm" variant="warning" class="mt-2" block @click="downloadPDF()">Download PDF
        </b-button>
      </b-overlay>
    </b-container>
  </div>
</template>

<script>
import {myAxiosRetry} from "@/services/myAxios";
import {createVoyagePlan} from "@/views/routes/components/VoyagePlan";
import {mapStores} from "pinia";
import {useRoutesStore} from "@/stores/routes";
import {useMomentaryStore} from "@/stores/momentary";

export default {
  data() {
    return {
      preparingPassagePlan: false,

      showRouteNameModal: false,
      routeNameInput: '',
    }
  },
  computed: {
    ...mapStores(useRoutesStore, useMomentaryStore),
    routePoints() {
      return this.routesStore.getShipWaypoints
    },
  },
  methods: {

    async getRouteName() {
      this.showRouteNameModal = true;
      return new Promise((resolve) => {
        this.$once('route-name-entered', (routeName) => {
          resolve(routeName);
        });
      });
    },
    handleRouteNameOk() {
      this.showRouteNameModal = false;
      // this.$emit('route-name-entered', this.routeNameInput);
      this.routesStore.getShipSettings.name = this.routeNameInput
      this.downloadPDF1()
    },

    downloadPDF() {
      this.routeNameInput = this.routesStore.getRouteName
      this.showRouteNameModal = true;
    },

    createPDF() {
      let points = []

      this.preparingPassagePlan = true

      this.routePoints.forEach(item => {
        points.push({lat: item.lat, lon: item.lon})
      })

      return new Promise(resolve => {
        myAxiosRetry.post("api/v1/mss/mapswithlegs", points
        ).then(resp => {
          this.momentaryStore.addChartsByLegs(resp.data)

          myAxiosRetry.post("api/v1/mss/adpenp", points
          ).then(resp => {
            this.momentaryStore.addPubsByLegs(resp.data)

            this.preparingPassagePlan = false
            resolve()
          })
        });
      })
    },
    downloadPDF1() {
      createVoyagePlan(this.routeNameInput)
    }
  },
}
</script>

<style scoped>

</style>