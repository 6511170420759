import {useUserStore} from "@/stores/user";
import {useMomentaryStore} from "@/stores/momentary";
import {myAxios, myAxios2, myAxiosRetry} from "@/services/myAxios";
import {addIndexToArray} from "@/utils/utils";
import store from "@/store";
import {useDeviceStore} from "@/stores/device";
import {file} from "paths.macro";
import {useRoutesStore} from "@/stores/routes";
import {useCatalogsStore} from "@/stores/catalogs";
import axios from "axios";
import {useLogbookStore} from "@/stores/logbook";
import * as fxparser from "fast-xml-parser";
import {eventBus} from "@/main";

let userStore, routesStore, deviceStore, momentaryStore, catalogsStore

export function loadShips() {
    userStore = useUserStore()
    routesStore = useRoutesStore()
    momentaryStore = useMomentaryStore()

    return new Promise(resolve => {
        if (userStore.isShipsLoaded) {
            resolve()
        } else {
            let URL = "api/v1/installations/brief"

            momentaryStore.setBusy(true);
            momentaryStore.setAPIError(false)
            myAxiosRetry.get(URL).then(resp => {
                let ECDISList = resp.data.filter(function (e) {
                    return e.applicationId === 11
                });

                let MkartList = resp.data.filter(function (e) {
                    return e.applicationId !== 11
                });

                ECDISList = addIndexToArray(ECDISList);
                MkartList = addIndexToArray(MkartList);

                userStore.addECDISShips({ecdisList: ECDISList, devicesList: MkartList})
                routesStore.addShips(ECDISList)
                momentaryStore.setBusy(false);
                resolve();
            }).catch(err => {
                momentaryStore.setAPIError(true)
            });
        }
    })
}

export function loadInstallations() {
    userStore = useUserStore()
    routesStore = useRoutesStore()
    momentaryStore = useMomentaryStore()

    return new Promise(resolve => {
        if (userStore.isInstallationsLoaded) {
            resolve()
        } else {
            let URL = "api/v1/installations"

            momentaryStore.setBusy(true);
            momentaryStore.setAPIError(false)
            myAxios.get(URL).then(resp => {
                let instECDIS = resp.data.userInfos.filter(function (e) {
                    return e.appState !== null && e.applicationId === 11
                });

                let instDevices = resp.data.userInfos.filter(function (e) {
                    return e.appState !== null && e.applicationId !== 11
                });

                instECDIS = addIndexToArray(instECDIS);
                instDevices = addIndexToArray(instDevices);

                if (instECDIS.length > 0) {
                    userStore.addInstallations({ecdisList: instECDIS, devicesList: instDevices})
                    routesStore.addShips(instECDIS)
                }
                momentaryStore.setBusy(false);
                resolve();
            }).catch(err => {
                momentaryStore.setAPIError(true)
            });
        }
    })
}

export function loadAgreements() {
    deviceStore = useDeviceStore()
    momentaryStore = useMomentaryStore()

    return new Promise(resolve => {
        if (deviceStore.isAgreementLoaded) {
            resolve()
        } else {
            let URL = "api/v2/agreement"

            momentaryStore.setBusy(true);
            momentaryStore.setAPIError(false)
            myAxios.get(URL).then(resp => {
                deviceStore.addAgreements(resp.data)

                if (store.state.auth.user.role === 'distributor') {
                    let devices = []
                    resp.data.forEach(function (agreement) {
                        agreement.users.forEach(function (user) {
                            devices.push({
                                deviceId: user.deviceId,
                                applicationId: user.applicationId,
                                shipName: user.shipName + " (" + agreement.name + ")",
                                email: user.email,
                            })
                        })
                    })
                    devices = addIndexToArray(devices);
                    userStore.addECDISAgreementShips({ecdisList: devices})
                    routesStore.addShips(devices)
                }

                momentaryStore.setBusy(false);
                resolve();
            }).catch(err => {
                momentaryStore.setAPIError(true)
            });
        }
    })
}

export function loadMSSChartsByLegs(points) {
    momentaryStore = useMomentaryStore()

    return new Promise((resolve, reject) => {
        let URL = "api/v1/mss/mapswithlegs"

        if (points.length === 0) {
            reject()
            return
        }

        momentaryStore.setBusy(true);
        momentaryStore.setAPIError(false)
        myAxios.post(URL, points).then(resp => {
            momentaryStore.addChartsByLegs(resp.data)
            momentaryStore.setBusy(false);
            resolve();
        }).catch(err => {
            momentaryStore.setAPIError(true)
            reject()
        });
    })
}

export function loadDatasetByLegs(points, providers = ['Primar']) {
    momentaryStore = useMomentaryStore()

    return new Promise((resolve, reject) => {
        let URL = "api/v1/maps-by-legs"

        if (points.length === 0) {
            reject()
            return
        }

        let request = {
            Providers: providers,
            Points: points
        }

        momentaryStore.setBusy(true);
        momentaryStore.setAPIError(false)
        myAxios.post(URL, request).then(resp => {
            momentaryStore.addDatasetByLegs(resp.data)

            // Create collections for each provider type
            const primarData = resp.data.find(item => item.provider === 'Primar');
            const avcsData = resp.data.find(item => item.provider === 'avcs');
            const enpData = resp.data.find(item => item.provider === 'enp');
            // const adpData = resp.data.filter(item =>
            //     ['radio', 'light', 'tide'].includes(item.provider));

            const adpData = resp.data.filter(item =>
                ['radio', 'light', 'tide'].includes(item.provider)).reduce((combined, item) => {
                // Merge the current item into the combined object
                return {
                    ...combined,
                    ...item,
                    // If both have legsInfo, combine them
                    legsInfo: combined.legsInfo && item.legsInfo ?
                        [...combined.legsInfo, ...item.legsInfo] :
                        (combined.legsInfo || item.legsInfo || []),
                    provider: 'adp' // Set a common provider name for the merged object
                };
            }, {});

            console.log("uniq adp", adpData)
            console.log("uniq enp", enpData)

            const uniqueIds = new Set();
            const uniqueIdsENP = new Set();
            const uniqueIdsADP = new Set();

            // Process primary ENC charts
            if (primarData && primarData.legsInfo) {
                primarData.legsInfo.forEach(leg => {
                    if (leg.mapIntersections) {
                        leg.mapIntersections.forEach(intersection => {
                            if (intersection.id && intersection.id !== 'PAYSF') {
                                uniqueIds.add(intersection.id);
                            }
                        });
                    }
                });
            }

            // Process AVCS charts
            if (avcsData && avcsData.legsInfo) {
                avcsData.legsInfo.forEach(leg => {
                    if (leg.mapIntersections) {
                        leg.mapIntersections.forEach(intersection => {
                            if (intersection.id && intersection.id !== 'PAYSF') {
                                uniqueIds.add(intersection.id);
                            }
                        });
                    }
                });
            }

            if (enpData && enpData.legsInfo) {
                enpData.legsInfo.forEach(leg => {
                    if (leg.mapIntersections) {
                        leg.mapIntersections.forEach(intersection => {
                            if (intersection.id && intersection.id !== 'PAYSF') {
                                uniqueIdsENP.add(intersection.id);
                            }
                        });
                    }
                });
            }

            if (adpData && adpData.legsInfo) {
                adpData.legsInfo.forEach(leg => {
                    if (leg.mapIntersections) {
                        leg.mapIntersections.forEach(intersection => {
                            if (intersection.id && intersection.id !== 'PAYSF') {
                                uniqueIdsADP.add(intersection.id);
                            }
                        });
                    }
                });
            }

            console.log("uniqueIds", uniqueIds)
            console.log("uniqueIdsENP", uniqueIdsENP)
            console.log("uniqueIdsADP", uniqueIdsADP)

            momentaryStore.addChartsByRoute(Array.from(uniqueIds))
            momentaryStore.addENPByRoute(Array.from(uniqueIdsENP))
            momentaryStore.addADPByRoute(Array.from(uniqueIdsADP))
            momentaryStore.setBusy(false);
            resolve();
        }).catch(err => {
            momentaryStore.setAPIError(true)
            reject()
        });
    })
}

// export function loadPubsByLegs(points) {
//     momentaryStore = useMomentaryStore()
//
//     function load_Pub(points, provider) {
//         return new Promise((resolve, reject) => {
//             let URL = "api/v1/maps-by-legs"
//
//             if (points.length === 0) {
//                 reject()
//                 return
//             }
//
//             let request = {
//                 Providers: provider,
//                 Points: points
//             }
//
//             momentaryStore.setBusy(true);
//             momentaryStore.setAPIError(false)
//             myAxios.post(URL, request).then(resp => {
//                 momentaryStore.addDatasetByLegs(resp.data)
//
//                 const uniqueIds = new Set();
//                 if (resp.data[0] && resp.data[0].legsInfo) {
//                     resp.data[0].legsInfo.forEach(leg => {
//                         if (leg.mapIntersections) {
//                             leg.mapIntersections.forEach(intersection => {
//                                 if (intersection.id && intersection.id !== 'PAYSF') {
//                                     uniqueIds.add(intersection.id);
//                                 }
//                             });
//                         }
//                     });
//                 }
//
//                 console.log("uniqueIds", provider, uniqueIds)
//                 // momentaryStore.addChartsByRoute(Array.from(uniqueIds))
//                 momentaryStore.setBusy(false);
//                 resolve();
//             }).catch(err => {
//                 momentaryStore.setAPIError(true)
//                 reject()
//             });
//         })
//     }
//
//     return new Promise(resolve => {
//         let encRequests = [load_Pub(points, "enp"), load_Pub(points, "tide")]
//         Promise.allSettled(encRequests).then(([r1, r2]) => {
//             resolve()
//         })
//     })
//
//     return new Promise((resolve, reject) => {
//         let URL = "api/v1/maps-by-legs"
//
//         if (points.length === 0) {
//             reject()
//             return
//         }
//
//         let request = {
//             Providers: [provider],
//             Points: points
//         }
//
//         momentaryStore.setBusy(true);
//         momentaryStore.setAPIError(false)
//         myAxios.post(URL, request).then(resp => {
//             momentaryStore.addDatasetByLegs(resp.data)
//
//             const uniqueIds = new Set();
//             if (resp.data[0] && resp.data[0].legsInfo) {
//                 resp.data[0].legsInfo.forEach(leg => {
//                     if (leg.mapIntersections) {
//                         leg.mapIntersections.forEach(intersection => {
//                             if (intersection.id && intersection.id !== 'PAYSF') {
//                                 uniqueIds.add(intersection.id);
//                             }
//                         });
//                     }
//                 });
//             }
//
//             momentaryStore.addChartsByRoute(Array.from(uniqueIds))
//             momentaryStore.setBusy(false);
//             resolve();
//         }).catch(err => {
//             momentaryStore.setAPIError(true)
//             reject()
//         });
//     })
// }

export function loadDataByPoint(point) {
    return new Promise((resolve, reject) => {
        let URL = "api/v1/maps-by-legs"

        let request = {
            Providers: ["Primar"],
            Points: [point]
        }

        myAxios.post(URL, request).then(resp => {

            const uniqueIds = new Set();

            if (resp.data[0] && resp.data[0].legsInfo) {
                resp.data[0].legsInfo.forEach(leg => {
                    if (leg.mapIntersections) {
                        leg.mapIntersections.forEach(intersection => {
                            if (intersection.id) {
                                uniqueIds.add(intersection.id);
                            }
                        });
                    }
                });
            }

            const sortedIds = Array.from(uniqueIds).sort((a, b) => {
                if (a.length >= 3 && b.length >= 3) {
                    return a[2].localeCompare(b[2]);
                }
                return a.localeCompare(b);
            });

            resolve(sortedIds);
        }).catch(err => {
            //TODO
            momentaryStore.setAPIError(true)
            reject()
        });
    })
}

export function load2DImage(coordinates, cog, repeat = false) {
    momentaryStore = useMomentaryStore()
    routesStore = useRoutesStore()

    // routesStore.getShipDraught
    // routesStore.getShipWidth
    // routesStore.getShipLength


    return new Promise(resolve => {
        let URL = "api/v1/ship_seabed"

        let xclip = 30
        let yclip = 30

        let data = momentaryStore.getShipImageRequest

        if (repeat) {

            if (xclip + momentaryStore.getShipImageRequestOffset * 10 >= 0 &&
                yclip + momentaryStore.getShipImageRequestOffset * 10 >= 0) {
                xclip += momentaryStore.getShipImageRequestOffset * 10
                yclip += momentaryStore.getShipImageRequestOffset * 10
            } else {
                xclip = data.imageInfo.xClip
                yclip = data.imageInfo.yClip
            }
        }

        console.log(momentaryStore.getShipImageRequestOffset, xclip, yclip)

        if (!repeat) {
            data = {
                "shipInfo": {
                    "draught": routesStore.getShipDraught,
                    "width": routesStore.getShipWidth,
                    "length": routesStore.getShipLength,
                    "xPos": 3
                },
                "positionInfo": {
                    "lat": coordinates.lat,
                    "lon": coordinates.lng,
                    "cog": cog
                },
                "imageInfo": {
                    "xClip": xclip,
                    "yClip": yclip,
                    "autoCalcClip": false
                }
            }
        } else {
            data.imageInfo.xClip = xclip
            data.imageInfo.yClip = yclip
        }

        momentaryStore.set2DImageRequest(data)

        myAxios2.post(URL, data).then(resp => {
            console.log("img resp", resp.data)
            const base64Image = resp.data; // Assuming resp.data contains the base64 string

            const src = `data:image/png;base64,${base64Image}`;
            resolve(src);
        }).catch(err => {
        });
    })
}


export function loadMSSChartsByRoute(points) {
    momentaryStore = useMomentaryStore()

    return new Promise(resolve => {
        let URL = "api/v1/mss/maps"

        momentaryStore.setBusy(true);
        momentaryStore.setAPIError(false)
        myAxios.post(URL, points).then(resp => {
            momentaryStore.addChartsByRoute(resp.data)
            momentaryStore.setBusy(false);
            resolve();
        }).catch(err => {
            momentaryStore.setAPIError(true)
        });
    })
}

export function loadMSSADPByRoute(points) {
    momentaryStore = useMomentaryStore()

    return new Promise(resolve => {
        let URL = "api/v1/mss/adp"

        momentaryStore.setBusy(true);
        momentaryStore.setAPIError(false)
        myAxiosRetry.post(URL, points).then(resp => {
            momentaryStore.addADPByRoute(resp.data)
            momentaryStore.setBusy(false);
            resolve();
        }).catch(err => {
            momentaryStore.setAPIError(true)
        });
    })
}

export function loadMSSENPByRoute(points) {
    momentaryStore = useMomentaryStore()

    return new Promise(resolve => {
        let URL = "api/v1/mss/enp"

        momentaryStore.setBusy(true);
        momentaryStore.setAPIError(false)
        myAxiosRetry.post(URL, points).then(resp => {
            console.log("aaaa enp res", resp.data)
            momentaryStore.addENPByRoute(resp.data)
            momentaryStore.setBusy(false);
            resolve();
        }).catch(err => {
            momentaryStore.setAPIError(true)
        });
    })
}

export function loadMSSDataByRoute(points) {
    return new Promise(resolve => {
        let encRequests = [loadMSSChartsByRoute(points), loadMSSADPByRoute(points), loadMSSENPByRoute(points)]
        Promise.allSettled(encRequests).then(([r1, r2, r3]) => {
            resolve()
        })
    })
}


// export function loadCells(sourceType) {
//     return new Promise(resolve => {
//         store.commit("fickle/setPrimarENCDataReady", true)
//         store.commit("fickle/setENCDataLoading", true)
//
//         let encRequests
//         if (sourceType === 'primar')
//             encRequests = [store.dispatch("getENCCountries_Primar"), store.dispatch("getENCProducts_Primar"), store.dispatch("getENCs_Primar")]
//         else if (sourceType === 'ukho')
//             encRequests = [store.dispatch("getENCCountries_Primar"), store.dispatch("getENCProducts_UKHO"), store.dispatch("getENCs_UKHO")]
//         else
//             alert("Error")
//
//         Promise.allSettled(encRequests).then(([r1, r2, r3]) => {
//             let mapInfos = r3.value
//             let errorFlag = false
//             let links = store.state.charts.s63.products.linksToProducts;
//             mapInfos.forEach(item => {
//                 item.productId = links[item.id];
//                 if (!item.productId) {
//                     // alert(item.id)
//                     errorFlag = true
//                 }
//             })
//
//             if (errorFlag) {
//                 // alert('Primar is not available, ' + file)
//                 console.log('PROBLEMA, ' + file)
//             }
//
//             store.commit("addPrimarMapInfos", Object.freeze(mapInfos));
//             store.commit("fickle/setENCDataLoading", false)
//
//             resolve(true)
//         })
//     })
//
// }

export function loadDeliveries(deviceId) {
    userStore = useUserStore()
    momentaryStore = useMomentaryStore()

    const dataAlreadyLoaded = userStore.ordersByDevices.some(e => e.id === deviceId)
    if (dataAlreadyLoaded)
        return

    momentaryStore.setOrdersBusy(true);
    return new Promise(resolve => {
        let URL = "api/v1/orders/";
        myAxiosRetry.get(URL).then(resp => {
            userStore.addOrders({orders: resp.data, id: deviceId})
            momentaryStore.setOrdersBusy(false);
            resolve();
        });
    })
}

export function loadDeliveries1(deviceId, period) {
    deviceStore = useDeviceStore()
    let data = {
        deviceIds: [deviceId],
        dateFrom: period // TODO dateFrom: dayjs().add(-1, "day")
    }

    return new Promise(resolve => {
        let URL = "api/v1/deliveries"
        myAxios.post(URL, data).then(resp => {
            let deliveries = []
            if (resp.data.deliveries.length > 0) {
                deliveries = resp.data.deliveries.filter(d => d.deviceId === deviceStore.getDeviceID)[0].deliveries
                deliveries.forEach(item => {
                    item.ship = deviceStore.getShipName
                })
            }
            deviceStore.addDeliveries(deliveries)
            resolve();
        });
    })
}

export function getTotalPrice(provider, data, id) {
    return new Promise((resolve, reject) => {
        let URL = provider === 'Primar' ? "/api/v1/orders/primar/quotate/v3" : "api/v1/mss/avcquotate"
        myAxios.post(URL, data, {
            headers: {
                'X-Request-ID': id,
            }
        }).then(resp => {
            // console.log("aaaa1212", resp.config.headers['X-Request-ID'])
            console.log("aaaa resp", resp)
            resolve(resp);
        }).catch(err => {
            console.log("aaaa error", err)
            reject(err)
        });
    })
}

export function getENPADPPrice(data, id) {
    console.log("enp getENPADPPrice")
    return new Promise((resolve, reject) => {

        if (data.length === 0) {
            let uoses = []
            let resp = {data: uoses}
            resolve(resp)
            return
        }

        let URL = "api/v1/mss/uosesquotate"
        myAxios.post(URL, data, {
            headers: {
                'X-Request-ID': id,
            }
        }).then(resp => {
            resolve(resp);
        }).catch(err => {
            reject(err)
        });
    })
}

function loadALL_Countries() {
    catalogsStore = useCatalogsStore()

    return new Promise(resolve => {
        let URL = "/api/v1/primar/countries"
        myAxios.get(URL).then(resp => {
            catalogsStore.addCountries(resp.data)
            resolve()
        });
    })
}

function loadUKHO_eNP() {
    return new Promise((resolve, reject) => {
        let URL = "/api/v1/mss/catalog/enp"
        myAxios.get(URL).then(resp => {
            resolve(resp.data);
        }).catch(err => {
            // TODO
            reject();
        })
    })
}

function loadUKHO_ADP1() {
    return new Promise((resolve, reject) => {
        let URL = "/api/v1/mss/catalog/radio"
        myAxios.get(URL).then(resp => {
            resolve(resp.data);
        }).catch(err => {
            // TODO
            reject();
        })
    })
}

function loadUKHO_ADP2() {
    return new Promise((resolve, reject) => {
        let URL = "/api/v1/mss/catalog/tide"
        myAxios.get(URL).then(resp => {
            resolve(resp.data);
        }).catch(err => {
            // TODO
            reject();
        })
    })
}

function loadUKHO_ADP3() {
    return new Promise((resolve, reject) => {
        let URL = "/api/v1/mss/catalog/light"
        myAxios.get(URL).then(resp => {
            resolve(resp.data);
        }).catch(err => {
            // TODO
            reject();
        })
    })
}

function loadUKHO_AVCS() {
    return new Promise((resolve, reject) => {
        let URL = "/api/v1/mss/catalog/enc"
        myAxios.get(URL).then(resp => {
            resolve(resp.data)
        }).catch(err => {
            reject();
        })
    })
}

function loadPrimar_ENC() {
    return new Promise((resolve, reject) => {
        let URL = "/api/v1/primar/enc"
        myAxios.get(URL).then(resp => {
            resolve(resp.data)
        });
    })
}

function loadUKHO_Products() {
    catalogsStore = useCatalogsStore()

    return new Promise((resolve, reject) => {
        let URL = "/api/v1/mss/catalog/avcs"
        myAxios.get(URL).then(resp => {
            let json = resp.data;

            let itemsS57 = {};
            let linksS57 = {};

            let indexENC = json.productTypes.findIndex((a) => a.description === 'avcs')
            let productsS75 = json.productTypes.at(indexENC).productList.products;

            productsS75.forEach(product => {
                if (product.productCellList) {

                    if (product.id !== 'PAYSF') {
                        if (product.productCellList.productCells.length > 1) {
                            itemsS57[product.id] = product;
                        }
                        product.productCellList.productCells.forEach(cell => {
                            linksS57[cell.id] = product.id
                        })
                    }
                }
            })

            catalogsStore.addProducts(productsS75)
            catalogsStore.addMultiItems(itemsS57)
            catalogsStore.addLinks(linksS57)
            resolve(json)
        }).catch(err => {
            reject();
        })
    })
}

function loadPrimar_Products() {
    catalogsStore = useCatalogsStore()

    return new Promise((resolve, reject) => {
        let URL = "/api/v1/primar/products"
        myAxios.get(URL).then(resp => {
            let json = resp.data

            let itemsS57 = {};
            let linksS57 = {};

            let indexENC = json.productTypes.findIndex((a) => a.description === 'S-57 ENC')
            let productsS75 = json.productTypes.at(indexENC).productList.products;

            productsS75.forEach(product => {
                if (product.productCellList) {
                    if (product.productCellList.productCells.length > 1) {
                        itemsS57[product.id] = product;
                    }
                    product.productCellList.productCells.forEach(cell => {
                        linksS57[cell.id] = product.id
                    })
                }
            })

            catalogsStore.addProducts(productsS75)
            catalogsStore.addMultiItems(itemsS57)
            catalogsStore.addLinks(linksS57)
            resolve(json)
        });
    })
}

export function loadUKHO_Publications() {
    catalogsStore = useCatalogsStore()
    deviceStore = useDeviceStore()
    return new Promise((resolve, reject) => {

        let encRequests = []
        if (deviceStore.getAgreementProvider === 'UKHO')
            encRequests = [loadUKHO_ADP1(), loadUKHO_ADP2(), loadUKHO_ADP3(), loadUKHO_eNP()]

        Promise.allSettled(encRequests).then(([r1, r2, r3, r4]) => {
            console.log("aaaa ADP", r1.value, r2.value, r3.value, r4.value)

            catalogsStore.addADP1(r1.value)
            catalogsStore.addADP2(r2.value)
            catalogsStore.addADP3(r3.value)
            catalogsStore.addENP(r4.value)
            resolve()
        }).catch(err => {
            reject()
        })
    })
}

export function prepareCatalog() {
    catalogsStore = useCatalogsStore()
    deviceStore = useDeviceStore()

    catalogsStore.reset()
    return new Promise(resolve => {

        let encRequests
        if (deviceStore.getAgreementProvider === 'UKHO')
            encRequests = [loadALL_Countries(), loadUKHO_AVCS(), loadUKHO_Products()]
        else if (deviceStore.getAgreementProvider === 'Primar')
            encRequests = [loadALL_Countries(), loadPrimar_ENC(), loadPrimar_Products()]

        Promise.allSettled(encRequests).then(([r1, r2, r3]) => {

            let mapInfos = r2.value
            let errorFlag = false
            let links = catalogsStore.links
            mapInfos.forEach(item => {
                item.productId = links[item.id];
                if (!item.productId) {
                    // alert(item.id)
                    console.log('MISSING, ' + item.id)
                    errorFlag = true
                }
            })

            if (errorFlag) {
                // alert('Primar is not available, ' + file)
                console.log('PROBLEMA, ' + file)
            }

            catalogsStore.addItems(mapInfos)
            resolve()
        })
    })
}

export function sendPurchaseRequest(data) {
    return new Promise(resolve => {
        let URL = "/api/v1/purchase/charts/todevice/v2"
        myAxios.post(URL, data).then(resp => {
            resolve();
        }).catch(err => {
            // TODO
            resolve();
        })
    })
}

export function sendUpdateRequest(data, id) {
    return new Promise(resolve => {
        let URL = "/api/v2/order/" + id + "/update-items"
        myAxios.post(URL, data).then(resp => {
            resolve();
        }).catch(err => {
            // TODO
            resolve();
        })
    })
}

export function loadOrder(id) {
    return new Promise(resolve => {
        let URL = "/api/v1/order/" + id
        myAxios.get(URL).then(resp => {
            resolve(resp);
        }).catch(err => {
            // TODO
            resolve();
        })
    })
}

export function registerECDIS(data) {
    return new Promise((resolve, reject) => {
        let URL = "/api/v1/ecdis/register"
        myAxios.post(URL, data).then(resp => {
            resolve();
        }).catch(err => {
            // TODO
            reject();
        })
    })
}

export function updateECDIS(data) {
    return new Promise((resolve, reject) => {
        let URL = "/api/v1/ecdis/update"
        myAxios.post(URL, data).then(resp => {
            resolve();
        }).catch(err => {
            // TODO
            reject();
        })
    })
}

export function loadZOC(data) {
    return new Promise(resolve => {
        let URL = "/api/v1/zoc_list"
        myAxios.post(URL, data).then(resp => {
            resolve(resp.data);
        }).catch(err => {
            // TODO
            resolve();
        })
    })
}

export function loadTides(date, lat, lon, days = 1) {
    return new Promise(resolve => {
        let URL = "https://www.worldtides.info/api/v3?heights&datum=CD&timezone&step=3600&date=" + date + "&lat=" + lat + "&lon=" + lon + "&days=" + days + "&key=4a124677-faa6-45dc-9772-b0ffdb6d62bb"
        axios.get(URL).then(resp => {
            resolve(resp.data)
        });
    })
}

export function loadTidesPlot12(date, lat, lon) {
    return new Promise(resolve => {
        let URL = "https://www.worldtides.info/api/v3?heights&datum=CD&plot&date=" + date + "&lat=" + lat + "&lon=" + lon + "&days=1&key=4a124677-faa6-45dc-9772-b0ffdb6d62bb"
        axios.get(URL).then(resp => {
            resolve(resp.data)
        });
    })
}

export function sendECDISRequest(type, deviceId) {
    return new Promise(resolve => {
        let datetime = new Date().toISOString()
        let URL = "api/v1/userfile?device_id=" + deviceId + "&type=logbookRequest_" + type + "&path=logbook\\" + deviceId + "\\request&time=" + datetime
        myAxios.post(URL, {request: type}).then(resp => {
            resolve();
        }).catch(err => {
            // TODO
            resolve();
        })
    })
}


export function saveLogbook(data, deviceId) {
    return new Promise(resolve => {
        let datetime = new Date().toISOString()
        let URL = "api/v1/userfile?device_id=" + deviceId + "&type=logbook_record&path=logbook\\" + deviceId + "\\record&time=" + datetime
        myAxios.post(URL, data).then(resp => {
            resolve();
        }).catch(err => {
            // TODO
            resolve();
        })
    })
}

export function loadLogbook(deviceId) {
    let logbookStore = useLogbookStore()
    return new Promise(resolve => {
        let datetime = new Date().toISOString()
        let URL = "api/v1/userfiles?deviceId=" + deviceId
        console.log("eeee", URL)
        myAxios.get(URL).then(resp => {

            console.log("eeee", resp)
            console.log("eeee", resp.data)

            let logbookRecord = resp.data.userFiles.find(file =>
                file.name === 'record' && file.type === 'logbook_record'
            );

            let ecdisMessageRecord = resp.data.userFiles.find(file =>
                file.name === 'Update.txt' && file.type === 'message'
            );

            let ecdisRoute = resp.data.userFiles.find(file =>
                file.name === 'Route.rtz' && file.type === 'route'
            );

            console.log("eeee1", logbookRecord)
            console.log("eeee2", ecdisMessageRecord)

            if (ecdisRoute) {
                URL = "api/v1/userfile/" + ecdisRoute.id
                myAxios.get(URL).then(resp => {
                    console.log("eeee12 resp", resp.data.length)

                    let coordinates = []
                    if (resp.data.length > 10) {
                        const options = {
                            ignoreAttributes: false,
                            compact: true,
                        };
                        const parser = new fxparser.XMLParser(options)
                        let xml = parser.parse(resp.data)
                        let points = xml.route.waypoints.waypoint

                        points.forEach((waypoint) => {
                            let lat = waypoint.position['@_lat']
                            let lon = waypoint.position['@_lon']
                            coordinates.push({lat: lat, lon: lon})
                        })
                    }
                    eventBus.$emit('draw-ecdis-route', coordinates);

                    console.log("eeee12 coo", coordinates.length)

                })
            }


            if (logbookRecord) {
                console.log("eeee3")
                URL = "api/v1/userfile/" + logbookRecord.id
                myAxios.get(URL).then(resp => {

                    let logRecords = resp.data

                    if (ecdisMessageRecord) {
                        URL = "api/v1/userfile/" + ecdisMessageRecord.id
                        myAxios.get(URL).then(resp => {
                            console.log("iiii", resp.data)


                            let ecdisMessages = []
                            resp.data.messages.forEach(ecdisMessage => {
                                let newMessage = {
                                    source: 'ecdis',
                                    time: ecdisMessage.timestamp,
                                    hdg: ecdisMessage?.hdg ?? 0,
                                    rot: ecdisMessage?.rot ?? 0,
                                    cog: ecdisMessage?.cog ?? 0,
                                    sog: ecdisMessage?.sog ?? 0,
                                    message: ecdisMessage.message,
                                    pos: {
                                        lat: ecdisMessage?.lat ?? "",
                                        lon: ecdisMessage?.lon ?? ""
                                    },
                                    images: [],
                                    audio: {
                                        src: null,
                                        blob: null
                                    },
                                    deviceId: deviceId
                                }
                                ecdisMessages.push(newMessage)
                            })
                            logbookStore.initEcdisMessages(ecdisMessages)
                            //logRecords.push(newMessage)

                            resolve(logRecords);

                        })
                    } else {
                        resolve(logRecords);
                    }
                }).catch(err => {
                    // TODO
                    resolve();
                })
            } else {
                console.log("eeee4")
                if (ecdisMessageRecord) {
                    console.log("aaa12_2")

                    URL = "api/v1/userfile/" + ecdisMessageRecord.id
                    console.log("aaa12", URL)
                    myAxios.get(URL).then(resp => {

                        let ecdisMessages = []

                        console.log("iiii", resp.data)

                        resp.data.messages.forEach(ecdisMessage => {
                            let newMessage = {
                                source: 'ecdis',
                                time: ecdisMessage.timestamp,
                                hdg: ecdisMessage?.hdg ?? 0,
                                cog: ecdisMessage?.cog ?? 0,
                                sog: ecdisMessage?.sog ?? 0,
                                rot: ecdisMessage?.rot ?? 0,
                                message: ecdisMessage.message,
                                pos: {
                                    lat: ecdisMessage?.lat ?? "",
                                    lon: ecdisMessage?.lon ?? ""
                                },
                                images: [],
                                audio: {
                                    src: null,
                                    blob: null
                                },
                                deviceId: deviceId
                            }
                            ecdisMessages.push(newMessage)
                        })
                        logbookStore.initEcdisMessages(ecdisMessages)
                        resolve([]);
                    })
                } else {
                    resolve([]);
                }
            }


        }).catch(err => {
            // TODO
            console.log("eeee8")
            resolve();
        })
    })
}