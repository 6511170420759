<template>
  <div>
    <div class="button-row mb-3">
      <b-button-group size="sm">
        <b-button variant="light" @click="saveData" v-b-tooltip.hover title="Save">
          <i class="fas fa-save"></i>
        </b-button>
        <b-button variant="light" @click="loadData" v-b-tooltip.hover title="Load">
          <i class="fas fa-upload"></i>
        </b-button>
        <b-button variant="light" @click="clearData" v-b-tooltip.hover title="Clear">
          <i class="fas fa-trash"></i>
        </b-button>
        <b-button variant="light" @click="exportSelectedLines" v-b-tooltip.hover title="Export Selected Lines">
          <i class="fas fa-download"></i>
        </b-button>
      </b-button-group>
    </div>
    <div class="table-container">
      <b-table selectable thead-class="d-none" select-mode="single" ref="editortable"
               @row-clicked="onRowClicked" :items="visibleRows()" :fields="visible_fields">
        <template #cell(name)="row">
          <div :class="{'text-primary': row.item._type === 'link',
                       'text-success': row.item._type === 'patch' && isSelectedForExport(row.item.id)}">
            {{ row.item.name }}
          </div>
        </template>
        <template #cell(actions)="row">
          <b-button size="sm" variant="outline-danger" @click.stop="deleteItem(row.item)" class="delete-btn">
            <i class="fas fa-times"></i>
          </b-button>
        </template>
      </b-table>
    </div>

    <!-- Hidden file input for loading data -->
    <input type="file" ref="fileInput" @change="handleFileUpload" style="display: none" accept=".json">
  </div>
</template>

<script>
import {useEditorStore} from "@/stores/editor";
import {mapStores} from "pinia";
import {eventBus} from "@/main";
import L from "leaflet";

export default {
  props: ["map"],
  data() {
    return {
      isMobileView: window.innerWidth <= 760,
      startMarker: null,
      endMarker: null
    }
  },
  mounted() {
    window.addEventListener('resize', this.checkMobileView);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobileView);
  },
  computed: {
    ...mapStores(useEditorStore),
    visible_fields() {
      return [
        { key: 'name', label: 'Name' },
        { key: 'depth', label: 'Depth (m)' },
        { key: 'type', label: 'Type' },
        { key: 'actions', label: '', thClass: 'action-column', tdClass: 'action-column' }
      ]
    },
  },
  methods: {
    saveData() {
      const filename = prompt('Enter filename to save:', 'editor_data');
      if (!filename) return;

      const dataToSave = {
        patches: this.editorStore.getPatches,
        links: this.editorStore.getLinks || []
      };

      const jsonString = JSON.stringify(dataToSave, null, 2);
      const blob = new Blob([jsonString], { type: 'application/json' });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = `${filename}.json`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },

    loadData() {
      this.$refs.fileInput.click();
    },

    isSelectedForExport(patchId) {
      return this.editorStore.selectedForExport.includes(patchId);
    },

    exportSelectedLines() {
      const selectedIds = this.editorStore.selectedForExport; // Use the direct property, not a getter

      if (selectedIds.length === 0) {
        alert('No lines selected for export. Right-click on the first (red) point of lines to select them.');
        return;
      }

      const filename = prompt('Enter filename for export:', 'selected_lines');
      if (!filename) return;

      // Create a temporary mode for selecting start and end points
      alert('Now, please click on the map to select the START point.');

      let startPoint = null;
      let endPoint = null;

      // Store original map click handler
      const originalClickHandler = this.map.map._events.click?.[0]?.fn || null;

      // Disable the original click handler
      if (originalClickHandler) {
        this.map.map.off('click', originalClickHandler);
      }

      // Set up handler for start point selection
      this.map.map.once('click', (e) => {
        startPoint = {
          lat: e.latlng.lat,
          lon: e.latlng.lng
        };

        // Add a temporary marker to show the start point
        this.startMarker = L.circleMarker([startPoint.lat, startPoint.lon], {
          radius: 8,
          fillColor: "#00ff00",
          color: "#000",
          weight: 2,
          opacity: 1,
          fillOpacity: 1
        }).addTo(this.map.map);

        alert('Now, please click on the map to select the END point.');

        // Set up handler for end point selection
        this.map.map.once('click', (e) => {
          endPoint = {
            lat: e.latlng.lat,
            lon: e.latlng.lng
          };

          // Add a temporary marker to show the end point
          this.endMarker = L.circleMarker([endPoint.lat, endPoint.lon], {
            radius: 8,
            fillColor: "#ff0000",
            color: "#000",
            weight: 2,
            opacity: 1,
            fillOpacity: 1
          }).addTo(this.map.map);

          // Get only the selected patches in the correct order
          const patchesToExport = selectedIds.map(id => {
            return this.editorStore.getPatches.find(patch => patch.id === id);
          }).filter(Boolean); // Filter out any undefined entries (in case of errors)

          // Add start and end points to the export data
          const exportData = {
            startPoint: startPoint,
            endPoint: endPoint,
            lines: patchesToExport
          };

          // Export as JSON
          const jsonString = JSON.stringify(exportData, null, 2);
          const blob = new Blob([jsonString], { type: 'application/json' });
          const url = URL.createObjectURL(blob);

          const a = document.createElement('a');
          a.href = url;
          a.download = `${filename}.json`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);

          // Reset all selected markers to unselected state
          this.clearExportSelection();

          // Restore the original click handler
          if (originalClickHandler) {
            this.map.map.on('click', originalClickHandler);
          }
        });
      });
    },

    clearExportSelection() {
      // Clear selection in store
      this.editorStore.clearExportSelection();

      // Reset marker colors if map exists
      if (this.map && this.map.editorMarkersGroup && this.map.editorPatchesGroup) {
        // Reset marker colors
        this.map.editorMarkersGroup.eachLayer(layer => {
          if (layer.options && layer.options.patchId && layer.options.isSelected) {
            layer.options.isSelected = false;
            layer.setStyle({
              fillColor: "#ff0b0b" // Reset to red
            });
          }
        });

        // Reset line colors
        this.map.editorPatchesGroup.eachLayer(layer => {
          const patchId = layer.options.patchId;

          // Reset polyline color
          if (patchId && layer instanceof L.Polyline && !(layer instanceof L.Polyline.Decorator)) {
            layer.setStyle({
              color: 'black'
            });
          }

          // Reset arrow decorators
          if (patchId && layer instanceof L.Polyline.Decorator) {
            // Remove the old decorator
            this.map.editorPatchesGroup.removeLayer(layer);

            // Find the corresponding polyline
            let polyline;
            this.map.editorPatchesGroup.eachLayer(l => {
              if (l instanceof L.Polyline &&
                  !(l instanceof L.Polyline.Decorator) &&
                  l.options.patchId === patchId) {
                polyline = l;
              }
            });

            if (polyline) {
              // Create new decorator with the default color
              const decorator = L.polylineDecorator(polyline, {
                patterns: [
                  {
                    offset: '25%',
                    repeat: '50%',
                    symbol: L.Symbol.arrowHead({
                      pixelSize: 12,
                      polygon: true,
                      pathOptions: {
                        fillOpacity: 1,
                        weight: 0,
                        color: 'black'
                      }
                    })
                  }
                ]
              }).addTo(this.map.editorPatchesGroup);

              decorator.options.patchId = patchId;
            }
          }
        });
      }
    },

    handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = JSON.parse(e.target.result);

          if (confirm('Loading will replace all existing data. Continue?')) {
            // Clear existing data
            this.clearAllData();
            this.removeStartEndMarkers();

            // Load new data
            if (data.patches && Array.isArray(data.patches)) {
              data.patches.forEach(patch => {
                this.editorStore.addPatch(patch);
              });
            }

            if (data.links && Array.isArray(data.links)) {
              data.links.forEach(link => {
                this.editorStore.addLink(link);
              });
            }

            // Redraw map elements
            if (this.map && typeof this.map.loadData === 'function') {
              this.map.loadData();
            } else {
              console.warn('Map object is missing or does not have loadData method');
            }
          }
        } catch (error) {
          alert('Error loading file: ' + error.message);
        }

        // Reset file input
        this.$refs.fileInput.value = '';
      };

      reader.readAsText(file);
    },

    clearData() {
      if (confirm('Are you sure you want to clear all data?')) {
        this.clearAllData();

        // Clear map display
        if (this.map && typeof this.map.clearData === 'function') {
          this.map.clearData();
        } else {
          console.warn('Map object is missing or does not have clearData method');
        }

        this.removeStartEndMarkers();
      }
    },

    removeStartEndMarkers() {
      // Remove start marker if it exists
      if (this.startMarker && this.map && this.map.map) {
        this.map.map.removeLayer(this.startMarker);
        this.startMarker = null;
      }

      // Remove end marker if it exists
      if (this.endMarker && this.map && this.map.map) {
        this.map.map.removeLayer(this.endMarker);
        this.endMarker = null;
      }
    },

    clearAllData() {
      this.editorStore.$patch({
        patches: [],
        links: []
      });
    },

    checkMobileView() {
      this.isMobileView = window.innerWidth <= 760;
    },

    visibleRows() {
      const patches = this.editorStore.getPatches.map(patch => ({
        ...patch,
        _type: 'patch' // Add type marker for styling
      }));

      const links = this.editorStore.getLinks.map(link => ({
        ...link,
        _type: 'link', // Add type marker for styling
        depth: '—', // Placeholder for depth (links don't have depth)
        type: 'link', // Display type as 'link'
      }));

      // Combine both types of items
      return [...patches, ...links];
    },

    deleteItem(item) {
      const itemType = item._type === 'link' ? 'link' : 'path';
      const itemName = item.name || `${itemType} ${item.id}`;

      if (confirm(`Are you sure you want to delete "${itemName}"?`)) {
        if (item._type === 'link') {
          // Delete link
          if (this.map && typeof this.map.deleteLink === 'function') {
            this.map.deleteLink(item.id);
          } else {
            console.warn('Map object is missing or deleteLink is not a function');
            eventBus.$emit('delete-link', item.id);
          }
        } else {
          // Delete patch
          this.editorStore.removePatch(item.id);

          if (this.map && typeof this.map.deletePatch === 'function') {
            this.map.deletePatch(item.id);
          } else {
            console.warn('Map object is missing or deletePatch is not a function');
            eventBus.$emit('delete-patch', item.id);
          }
        }
      }
    },

    onRowClicked(row, index) {
      // When a row is clicked, center the map on the first point of this line or link
      if (row && row.points && row.points.length > 0) {
        // Get the first point of the line or link
        const firstPoint = row.points[0];

        // Center map on the first point
        if (this.map && this.map.map) {
          this.map.map.setView([firstPoint.lat, firstPoint.lon], 8);
        }
      }
    }
  }
}
</script>

<style scoped>
.button-row {
  display: flex;
  gap: 10px;
}

.unstyled-button {
  border: none;
  padding: 0;
  background: none;
}

.active-btn {
  background-color: #e9ecef;
  border-color: #ced4da;
  font-weight: 500;
}

.btn {
  border-radius: 0;
}

.btn-group .btn:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.btn-group .btn:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.custom-button-group .btn {
  background-color: #007bff;
  color: white;
  border: none;
  transition: background-color 0.3s ease;
}

.custom-button-group .btn:hover {
  background-color: #0056b3;
}

.delete-btn {
  padding: 0.15rem 0.3rem;
  font-size: 0.75rem;
}

.action-column {
  width: 40px;
  text-align: center;
}

.table-container {
  height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
  overflow-y: auto;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}
</style>