import { defineStore } from 'pinia'
import dayjs from "dayjs";

export const useDeviceStore = defineStore('device', {
    state: () => ({
        isAgreementReady: false,
        isOrderedDataReady: false,
        deviceID: "",
        appType: 0,
        agreementID: "",
        shipName: "",
        orderedData: {
            ukho: {
                avcs: [],
                adp: [],
                enp: [],
            },
            primar: {
                enc: []
            }
        },
        agreements: [],
        deliveries: []
    }),
    getters: {
        isAgreementLoaded: (state) => state.isAgreementReady,
        isOrderedDataLoaded: (state) => state.isOrderedDataReady,
        getAgreementByDeviceID: (state) => (val) => {
                let flag = false
                let theAgreement = null
                state.agreements.forEach(agreement => {
                    let deviceID = agreement?.users.find((a) => a.deviceId == val)
                    if (deviceID) {
                        flag = true
                        theAgreement = agreement
                        return
                    }
                })
                return theAgreement
        },

        // getAgreementByDeviceID1: (state) => {
        //     let agreement = "1234"
        //     state.agreements.forEach(item => {
        //         agreement = item.users.find((a) => a.deviceId === state.deviceId)
        //         if (agreement) {
        //             return agreement
        //         }
        //     })
        //     return agreement
        // },

        getAgreementProviderByDeviceID: (state) => (val) => {
            // return (val) => state.agreements[0]?.users.find((a) => a.deviceId === val)?.availableOrderTypes[0] ?? ''

            let theAgreement = null
            state.agreements.forEach(agreement => {
                let deviceID = agreement?.users.find((a) => a.deviceId == val)
                if (deviceID) {
                    theAgreement = agreement.availableOrderTypes[0]
                    return
                }
            })
            return theAgreement
        },

        isDeviceInAgreement: (state) => (val) => {
            // return (val) => (state.agreements[0]?.users.find((a) => a.deviceId === val) !== undefined)

            let flag = false
            state.agreements.forEach(agreement => {
                let deviceID = agreement?.users.find((a) => a.deviceId == val)
                if (deviceID) {
                    flag = true
                    return
                }
            })
            return flag
        },

        isAgreementValid: (state) => (val) => {
            // return (val) => state.agreements[0]?.users.find((a) => a.deviceId === val)?.availableOrderTypes[0] ?? ''

            let theAgreement = null
            state.agreements.forEach(agreement => {
                let deviceID = agreement?.users.find((a) => a.deviceId == val)
                if (deviceID) {
                    theAgreement = agreement
                    return
                }
            })
            let valid = dayjs(theAgreement?.validTill).diff(dayjs()) > 0
            return valid
        },

        getAgreementID: (state) => {
            // return (val) => state.agreements[0]?.users.find((a) => a.deviceId === val)?.availableOrderTypes[0] ?? ''
            const deviceID = state.deviceID
            let theAgreement = null
            state.agreements.forEach(agreement => {
                let user = agreement?.users.find((a) => a.deviceId == deviceID)
                if (user) {
                    theAgreement = agreement.id
                    return
                }
            })
            return theAgreement
        },

        getAgreementCurrency: (state) => {
            // return (val) => state.agreements[0]?.users.find((a) => a.deviceId === val)?.availableOrderTypes[0] ?? ''
            const deviceID = state.deviceID
            let theAgreementCurrency = null
            state.agreements.forEach(agreement => {
                let user = agreement?.users.find((a) => a.deviceId == deviceID)
                if (user) {
                    theAgreementCurrency = agreement.currency
                    return
                }
            })
            return theAgreementCurrency
        },

        getAgreementName: (state) => {
            // return (val) => state.agreements[0]?.users.find((a) => a.deviceId === val)?.availableOrderTypes[0] ?? ''
            const deviceID = state.deviceID
            let theAgreementCurrency = null
            state.agreements.forEach(agreement => {
                let user = agreement?.users.find((a) => a.deviceId == deviceID)
                if (user) {
                    theAgreementCurrency = agreement.name
                    return
                }
            })
            return theAgreementCurrency
        },


        getAgreementProvider: (state) =>  {
            const deviceID = state.deviceID
            let provider = null
            state.agreements.forEach(agreement => {
                let user = agreement?.users.find((a) => a.deviceId === deviceID)
                if (user) {
                    provider = user.availableOrderTypes[0] ?? ''
                    return
                }
            })
            return provider
        },

        getAgreementApplicationType: (state) =>  {
            const deviceID = state.deviceID
            let type = null
            state.agreements.forEach(agreement => {
                let user = agreement?.users.find((a) => a.deviceId === deviceID)
                if (user) {
                    type = user.applicationId
                    return
                }
            })
            return type
        },

        // getAgreementCurrency: (state) =>  {
        //     const deviceID = state.deviceID
        //     let currency = null
        //     state.agreements.forEach(agreement => {
        //         let user = agreement?.users.find((a) => a.deviceId === deviceID)
        //         if (user) {
        //             currency = user.currency
        //             return
        //         }
        //     })
        //     return currency
        // },


        // isAgreementValid: (state) => dayjs(state.agreements[0]?.validTill).diff(dayjs()) > 0 ,
        // isAgreementDays: (state) => dayjs(state.agreements[0]?.validTill).diff(dayjs()) ,
        // getAgreementID: (state) => state.agreements[0]?.id,
        // getAgreementID: (state) => state.agreementID,

        isDeviceIDExist: (state) => state.deviceID !== '',
        getDeviceID: (state) => state.deviceID,
        getAppType: (state) => state.appType,
        getShipName: (state) => state.shipName,
        // getLatestLog: (state) => state.logbook[state.logbook.length - 1]
        // getLatestLog: (state) => {
        //     return () => state.logbook[state.logbook.length - 1]
        // },
        getDeliveries: (state) => state.deliveries,
        getDeliveriesCount: (state) => state.deliveries.length
    },
    actions: {
        addAgreements(val) {
            this.agreements = val
            this.isAgreementReady = true
        },
        selectDevice(val) {
            this.deviceID = val
        },
        addOrdersHistoryAVCS(val) {
            this.orderedData.ukho.avcs = val
        },
        addOrdersHistoryPrimar(val) {
            this.orderedData.primar.enc = val
        },
        addDeliveries(val) {
          this.deliveries = val
        },
        reset() {
            //TODO
        }
    },
    persist: false
})
