import {myAxios} from "@/services/myAxios";
import {file} from "paths.macro";
// import {commit} from "lodash/seq";
import axios from "axios";
import Vue from "vue";
import store from "@/store";
import {eventBus} from "@/main";
import {keepRoute} from "@/helpers/lsHelpers";
// let LsRoute = localStorage.getItem('route')

// let LsPoints
// let LsSettings
// let LsZones
// let LsInfo

// if (LsRoute){
    // if (JSON.parse(LsRoute)['route_temp_1']){
    //     LsPoints = JSON.parse(LsRoute)['route_temp_1']
    // }
    // if (JSON.parse(LsRoute)['route_temp_2']){
    //     LsSettings = JSON.parse(LsRoute)['route_temp_2']
    // }
    // if (JSON.parse(LsRoute)['route_temp_3']){
    //     LsZones = JSON.parse(LsRoute)['route_temp_3']
    // }
    // if (JSON.parse(LsRoute)['route_temp_4']){
    //     LsInfo = JSON.parse(LsRoute)['route_temp_4']
    // }
// }
const initialState = {
    progress: {
        isCalculating: false,
        calculatingProgress: 0,
    },
    prev: {
        resp: {},
        wpIDs: [],
        useBypass: false,
        // routeWithInfo: [],
        // route: [],
        // dangers: [],
        // dangers_filtered: [],
        // dangers_only: [],
        // dangers_keys: {
        //     categories: null,
        // },
        // dangers_legs: [],
        // warning_legs: [],
    },
    routeWithInfo: [],
    // route: LsPoints ? LsPoints : [],
    route: [],
    routePrevious: [],
    segment1Points: null,
    segment2Points: null,
    route_settings:  {
        draught: 5,
        height: 20,
        radius: 120,
        xtel: 120,
        xter: 120,
        userecommendedtracks: true,
        speed: 10,
        trackratio: 0.7,
        shallowratio: 1,
        avoidshallowstrength: 1,
        avoiddistance: 120,
        refinecoefficient: 1,
        startdate: '',
        starttime: ''
    },
    bounds: null,
    length: '',
    length_manual: '',
    dangers: [],
    dangers_filtered: [],
    dangers_only: [],
    dangers_keys: {
        categories: null,
    },
    dangers_legs: [],
    warning_legs: [],
    debug: [],
    info:  [],
    use_for_order: false,
    movingWP: -1,
    nogoZones: {
        zones: []
    }
}

export const routes = {
    namespaced: true,
    state: initialState,
    actions: {},
    mutations: {
        setCalculatingStatus(state, status) {
            state.progress.isCalculating = status;
            if (status) {
                state.progress.calculatingProgress = 0;
            }
        },
        setProgress(state, progress) {
            if (progress === 0)
                state.progress.calculatingProgress += 1
            else {
                if (progress > state.progress.calculatingProgress )
                    state.progress.calculatingProgress = progress;
            }

        },
        storeBypassResp(state, payload) {
           state.prev.resp = payload
        },
        clearBypassResp(state) {
           state.prev.useBypass = false
           state.prev.resp = null
           state.prev.wpIDs = []

        },
        useBypass(state, wpIDs) {
            state.prev.useBypass = true
            state.prev.wpIDs = wpIDs
            // state.prev.route = state.route
            // state.prev.routeWithInfo = state.routeWithInfo
            // state.prev.dangers = state.dangers
            // state.prev.dangers_filtered = state.dangers_filtered
            // state.prev.dangers_only = state.dangers_only
            // state.prev.dangers_legs = state.dangers_legs
            // state.prev.warning_legs = state.warning_legs
        },
        // restoreBypass(state) {
        //     state.useBypass = false
        //     state.route = state.prev.route
        //     state.routeWithInfo = state.prev.routeWithInfo
        //     state.dangers = state.prev.dangers
        //     state.dangers_filtered = state.prev.dangers_filtered
        //     state.dangers_only = state.prev.dangers_only
        //     state.dangers_legs = state.prev.dangers_legs
        //     state.warning_legs = state.prev.warning_legs
        // },
        setRoute(state, payload) {
            state.routeWithInfo = payload.route;
            state.length = Number(payload.length).toFixed(2);
            state.dangers = payload.dangers;
            state.debug = payload.debug

            let filtered = payload.dangers.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.id === value.id
                    ))
            )
            state.dangers_filtered = Object.freeze(filtered)

            let dangers_only = payload.dangers.filter(function (e) {
                    return e.isDanger === true;
                })
            state.dangers_only = Object.freeze(dangers_only)

            // eventBus.$emit('draw-all-nogo-objects2')
            store.commit("fickle/clearMetric")

            let allrequests = []

            // filtered.forEach(item => {
            //     const req = myAxios.get(`https://mwend.bgeo.fi/api/v1/objectinfo?id=${item.id}`).then(resp => {
            //         store.commit("fickle/setMetric", resp.data)});
            //     allrequests.push(req);
            // })

            // return

            Promise.allSettled(allrequests).then((res) => {
                console.log("iiii", res);
                eventBus.$emit('draw-all-nogo-objects');
                // console.log("off, loadUserData DONE")
                // store.commit("fickle/setUserDataLoading", false)
                // localStorage.setItem('proto', JSON.stringify(store.state.proto));
                // localStorage.setItem('transactions', JSON.stringify(store.state.transactions));
                // localStorage.setItem('distributor', JSON.stringify(store.state.distributor));
                // resolve(true)
            })


            // filtered.forEach(item => {
            //     myAxios.get(`https://mwend.bgeo.fi:6004/api/v1/objectinfo?id=${item.id}`)
            //         .then(resp => {
            //             store.commit("fickle/setMetric", resp.data)
            //             eventBus.$emit('draw-all-nogo-objects')
            //             // eventBus.$emit("draw-nogo-object", resp.data)
            //             // this.prepareInfo(id)
            //         });
            // })

            let mySet = new Set()
            payload.dangers.forEach(item => {
                mySet.add(item.name)

                if (item.isDanger) {
                    state.dangers_legs[item.legIndex] = true
                } else {
                    state.warning_legs[item.legIndex] = true
                    if (state.dangers_legs[item.legIndex] !== true) {
                        state.dangers_legs[item.legIndex] = false
                    }
                }
            })
            Vue.set(state.dangers_keys, "categories", mySet );

            state.info = payload.info;
        },
        setRoutePointsBackup(state) {
            state.routePrevious = state.route;
        },
        setRoutePoints(state, payload) {
            payload.forEach((item, i) => {
                payload[i]['id'] = i

                let dangers = state.dangers.filter(function (e) {
                    return e.isDanger === true && e.legIndex === i + 1
                });

                if (dangers.length > 0)
                    payload[i].isDanger = true
            })

            state.route = payload;
        },
        setRoutePointsDetailsFlag(state, index) {
            state.route.forEach(item => {
                item._showDetails = false
            })
            state.route[index]._showDetails = true;
        },
        clearRoutePointsDetailsFlag(state, index) {
            state.route.forEach(item => {
                item._showDetails = false
            })
        },
        setRouteLengthManual(state, length) {
            state.length_manual = Number(length).toFixed(2);
        },
        setRouteSettings(state, payload) {
            state.route_settings.draught = payload.draught
            state.route_settings.height = payload.height
            state.route_settings.radius = payload.radius
            state.route_settings.xtel = payload.xtel
            state.route_settings.xter = payload.xter
            state.route_settings.recommended = payload.recommended
            state.route_settings.speed = payload.speed
            state.route_settings.date = payload.date
            state.route_settings.time = payload.time
            state.route_settings.ratio = payload.ratio
            state.route_settings.shallowratio = payload.shallowratio
            state.route_settings.shallowstrength = payload.shallowstrength
            state.route_settings.avoiddistance = payload.avoiddistance
            state.route_settings.refinecoefficient = payload.refinecoefficient

        },
        setRouteBounds(state, bounds) {
            state.bounds = bounds;
        },
        setRouteSegment1Points(state, data) {
            state.segment1Points = data;
        },
        setMovingWP(state, index) {
            state.movingWP = index;
        },
        setRouteSegment2Points(state, data) {
            state.segment2Points = data;
        },
        setZone(state, data) {
            state.nogoZones.zones.push(data)
        },
        deleteZone(state, name) {
            let index = state.nogoZones.zones.findIndex(p => p.name == name);
            state.nogoZones.zones.splice(index, 1);
        },
        useRouteForOrder(state, flag) {
            state.use_for_order = flag;
        },
        resetStorage(state) {
            //state = initialState;
            //Object.assign(state, initialState)
            state.route = [];
            state.segment1Points = null;
            state.segment2Points = null;
            state.dangers = [];
            state.dangers_keys.categories = null;

            state.dangers_legs = []
            state.warning_legs = []
            state.info = [];
            // state.length = '';
            state.progress.isCalculating = false;
            state.progress.calculatingProgress = 0;
            state.use_for_order = false;
            // state.nogoZones.zones = [];
        }
    }
}