import {defineStore} from 'pinia'

export const useCatalogsStore = defineStore('catalogs', {
    state: () => ({
        adp_light: [],
        adp_radio: [],
        adp_tide: [],
        enp: [],
        countries: [],
        products: [],
        links: {},
        items: [],
        multiitems: {},
        chartsfeatures: undefined
    }),
    getters: {
        getCatalogLength: (state) => state.products.length,
        getCountries: (state) => state.countries,
        getProducts: (state) => state.products,
        getMiltiItems: (state) => state.multiitems,
        getLinks: (state) => state.links,
        getItems: (state) => state.items,
        getItemsById: (state) => {
            return (id) => {
                return state.items.find(chart => chart.id === id)
            }
        },
        getChartsFeatures: (state) => state.chartsfeatures,
        getProductsCount: (state) => state.products.length,
        getENP: (state) => state.enp,
        getADP1: (state) => state.adp_light,
        getADP2: (state) => state.adp_radio,
        getADP3: (state) => state.adp_tide,
        // getADP: (state) => Object.freeze([...state.adp_light, ...state.adp_radio, ...state.adp_tide]),
    },
    actions: {
        addENP(val) {
            this.enp = Object.freeze(val.productTypes[0].productList)
        },
        addADP1(val) {
            this.adp_radio = Object.freeze(val.productTypes[0].productList)
        },
        addADP2(val) {
            this.adp_tide = Object.freeze(val.productTypes[0].productList)
        },
        addADP3(val) {
            this.adp_light = Object.freeze(val.productTypes[0].productList)
        },
        addCountries(val) {
            this.countries = Object.freeze(val)
        },
        addProducts(val) {
            this.products = Object.freeze(val)
        },
        addMultiItems(val) {
            this.multiitems = Object.freeze(val)
        },
        addLinks(val) {
            this.links = Object.freeze(val)
        },
        addItems(val) {
            this.items = Object.freeze(val)
        },
        addChartsFeatures(val) {
            this.chartsfeatures = Object.freeze(val)
        },
        reset() {
            this.countries = []
            this.products = []
            this.links = {}
            this.items = []
            this.multiitems = {}
            this.adp_light = []
            this.adp_radio = []
            this.adp_tide = []
            this.enp = []
            this.chartsfeatures = undefined
        }
    },
    persist: false
})
