import L from "leaflet";
import {NavLeaf} from "../base/nav-leaf";
import {SELECTED_CELL_COLOR} from "./colors-control";
import {drawCharts} from "@/my-leaf/routes/components/route-graphs";
import {eventBus} from "@/main";
import store from "@/store";
import * as buttonControls from "@/my-leaf/routes/components/route-buttons";
import {createContextButtons} from "@/my-leaf/charts/components/charts-context";

export class ChartsByRouteLeaf extends NavLeaf {

    constructor() {
        super();
        this.cellsLayer = null;
        this.activeCell = null;
        this.allCells = null;
        this.contextMenu = null
        this.oneCellLayer = null

        this.categoryLayers = [7];

        // this.cellsLayer = L.layerGroup();
        // this.cellsLayer.addTo(this.map);
    }

    init(el) {
        super.init(el);
        this.cellsLayer = L.layerGroup().addTo(this.map);
        this.oneCellLayer = L.layerGroup().addTo(this.map);

        for (let i = 0; i <= 6; i++) {
            this.categoryLayers[i] = L.layerGroup().addTo(this.map);
        }

        new L.Control.EasyButton({
            position: 'topleft',
            states: [{
                icon: 'bi bi-bar-chart-steps',
                title: 'Show graph',
                onClick: (btn) => {
                    // drawCharts.call(this, 'charts')
                    drawCharts.call(this, 'charts')
                    // eventBus.$emit('show-graph-on-map')
                }
            }]
        }).addTo(this.map)

        eventBus.$off('re-contextmenu')
        eventBus.$on('re-contextmenu', () => {
            this.map.closePopup()
            // alert(1)
            setTimeout(() => {
                createContextButtons.call(this)
            }, 1000)
        })

        createContextButtons.call(this)
        // this.contextMenu = new button

        // this.map.getPane('tooltipPane').style.display = 'none';

        // let lastZoom;
        // this.map.on('zoomend', () => {
        //     let tooltipThreshold = 10;
        //     let zoom = this.map.getZoom();
        //     if (zoom < tooltipThreshold && (!lastZoom || lastZoom >= tooltipThreshold)) {
        //         this.map.getPane('tooltipPane').style.display = 'none';
        //     } else if (zoom >= tooltipThreshold && (!lastZoom || lastZoom < tooltipThreshold)) {
        //         this.map.getPane('tooltipPane').style.display = 'block';
        //     }
        //     lastZoom = zoom;
        //
        //     // var zoom = this.map.getZoom();
        //     // console.log("aaa", zoom)
        //     // if (zoom < 15 && (!lastZoom || lastZoom >= 15)) {
        //     //     this.map.eachLayer((l) => {
        //     //         if (l.getTooltip) {
        //     //             var toolTip = l.getTooltip();
        //     //             l.unbindTooltip().bindTooltip(toolTip, {
        //     //                 permanent: false
        //     //             })
        //     //         }
        //     //     });
        //     // } else if (zoom >= 15 && (!lastZoom || lastZoom < 15)) {
        //     //     this.map.eachLayer((l) => {
        //     //         if (l.getTooltip) {
        //     //             var toolTip = l.getTooltip();
        //     //             l.unbindTooltip().bindTooltip(toolTip, {
        //     //                 permanent: true
        //     //             })
        //     //         }
        //     //     });
        //     // }
        //     // lastZoom = zoom;
        // })

        // eventBus.$off('highlight-onroute-cell')
        // eventBus.$on('highlight-onroute-cell', (cellName) => {
        //     this.highlightCell(cellName);
        // })
    }

    aaa1212() {
        alert(12)
    }

    showLayer(category, hide) {
        // alert(category + " " + hide)
        if (hide)
            this.map.removeLayer(this.categoryLayers[category])
        else
            this.map.addLayer(this.categoryLayers[category])
    }

    displayCell(cellData, isInFolio, category) {
        let style = isInFolio ? {color: 'rgba(11,163,11,0.72)', fillOpacity: 0, fillColor: 'transparent', weight: 1} :
            {color: 'rgba(74,148,255,0.84)', fillOpacity: 0, fillColor: 'transparent', weight: 1}
        if (category === 0)
            style = {
                color: 'white',
                weight: 3,
                opacity: 1,
                fillOpacity: 0,
                dashArray: '3',
                dashOffset: '0',
                fillColor: 'transparent',
                fill: false,
                stroke: true
            }

        const cell = L.geoJSON(cellData, {

            // layer.setStyle({ color: '#ffffff', weight: 2, opacity: 1, fillOpacity: 0, dashArray: '3', dashOffset: '0', fillColor: 'transparent', fill: false, stroke: true});
            style: style,
            onEachFeature: (feature, layer) => {
                // layer.on('click', () => {
                //     this.highlightCell(feature.properties.id);
                // });

                // layer.bindTooltip(`${feature.properties.id}`, {
                //     permanent: false,
                //     direction: 'center'
                // });
            }
        });

        // cell.addTo(this.cellsLayer);
        cell.addTo(this.categoryLayers[category]);
        return cell;
    }

    switchCell(cellName, isSelected) {
        console.log("aaa layer 00", this.cellsLayer)

        this.cellsLayer.getLayers().forEach(layer => {
            console.log("aaa layer 1", layer)
            console.log("aaa layer 2", layer._layers)
            // console.log("aaa layer 3", layer._layers.feature)
            // console.log("aaa layer 4", layer._layers.feature.id)

            let lefId = Object.keys(layer._layers)[0]
            console.log("aaa layer 4", lefId)

            console.log("aaa layer 12", layer._layers[lefId].feature.properties.id)

            if (layer._layers[lefId].feature.properties.id === cellName) {
                if (!isSelected)
                    layer.setStyle({color: 'rgb(255,225,7)', fillOpacity: 0, fillColor: 'transparent', weight: 1});
                else
                    layer.setStyle({
                        color: 'rgba(74,148,255,0.84)',
                        fillOpacity: 0,
                        fillColor: 'transparent',
                        weight: 1
                    });
            }


            // console.log("aaa layer 4", layer.feature.properties.id)
        })

        return

        const cell = this.cellsLayer.getLayers().find(layer => layer._layers[0].feature && layer._layers[0].feature.properties.id === cellName);

        console.log("aaa cellsLayer 1", this.cellsLayer)
        console.log("aaa cellsLayer 2", this.cellsLayer.layers)
        console.log("aaa cellsLayer 4", this.cellsLayer.getLayers())

        console.log("aaa cellsLayer", cell)

        if (cell) {
            cell.setStyle({color: 'red'});
        }

        return


        // const cell = this.allCells.find(c => c.id === cellName);

        if (cell) {
            console.log("aaa cell", cell)

            // Create GeoJSON cell data
            const cellData = {
                type: "Feature",
                properties: {
                    id: cell.id,
                    name: cell.title || cell.name,
                    type: cell.type
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [[[cell.west, cell.north],
                        [cell.east, cell.north],
                        [cell.east, cell.south],
                        [cell.west, cell.south],
                        [cell.west, cell.north]]]
                }
            };
            const layer = this.displayCell(cellData);
            layer.setStyle({
                color: '#ff000',
                weight: 2,
                opacity: 1,
                fillOpacity: 0,
                dashArray: '3',
                dashOffset: '0',
                fillColor: 'transparent',
                fill: false,
                stroke: true
            });
            // layer.setBorderColor({color: 'red', weight: 4});
            // layer.bringToFront();
            // this.activeCell = layer;
            // this.map.fitBounds(layer.getBounds());
        }

    }

    displayOneCell(cellData) {
        let style = {
            color: 'white',
            weight: 3,
            opacity: 1,
            fillOpacity: 0,
            dashArray: '3',
            dashOffset: '0',
            fillColor: 'transparent',
            fill: false,
            stroke: true
        }

        const cell = L.geoJSON(cellData, {

            // layer.setStyle({ color: '#ffffff', weight: 2, opacity: 1, fillOpacity: 0, dashArray: '3', dashOffset: '0', fillColor: 'transparent', fill: false, stroke: true});
            style: style,
            onEachFeature: (feature, layer) => {
                // layer.on('click', () => {
                //     this.highlightCell(feature.properties.id);
                // });

                // layer.bindTooltip(`${feature.properties.id}`, {
                //     permanent: false,
                //     direction: 'center'
                // });
            }
        });

        // cell.addTo(this.cellsLayer);
        cell.addTo(this.oneCellLayer);
        return cell;
    }

    drawCell(cell) {
        this.oneCellLayer.clearLayers()

        if (cell) {
            const cellData = {
                type: "Feature",
                properties: {
                    id: cell.id,
                    name: cell.title || cell.name,
                    type: cell.type
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [[[cell.west, cell.north],
                        [cell.east, cell.north],
                        [cell.east, cell.south],
                        [cell.west, cell.south],
                        [cell.west, cell.north]]]
                },
                // style: { color: 'white', weight: 2, opacity: 1, fillOpacity: 0, dashArray: '3', dashOffset: '0', fillColor: 'transparent', fill: false, stroke: true}
            };

            const layer = this.displayOneCell(cellData);
            // layer.setStyle({ color: '#ffffff', weight: 2, opacity: 1, fillOpacity: 0, dashArray: '3', dashOffset: '0', fillColor: 'transparent', fill: false, stroke: true});
            // layer.setBorderColor({color: 'red', weight: 4});
            layer.bringToFront();
            // this.activeCell = layer;
            // this.map.panTo(layer.getBounds().getCenter());
        }

    }

    highlightCell(cellName) {
        // if (this.activeCell) {
        //     this.activeCell.setStyle({ color: 'rgba(74,148,255,0.84)' });
        // }

        // this.cellsLayer.eachLayer(layer => {
        //     // layer.setStyle(this.activeStyle);
        //
        //     layer.setStyle({ color: 'red' });
        //
        //     layer.bringToFront();
        //     this.activeCell = layer;
        //     this.map.fitBounds(layer.getBounds());
        // });

        this.categoryLayers[0].clearLayers()
        const cell = this.allCells.find(c => c.id === cellName);
        console.log("aaa cell", cell)


        if (cell) {
            // Create GeoJSON cell data
            const cellData = {
                type: "Feature",
                properties: {
                    id: cell.id,
                    name: cell.title || cell.name,
                    type: cell.type
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [[[cell.west, cell.north],
                        [cell.east, cell.north],
                        [cell.east, cell.south],
                        [cell.west, cell.south],
                        [cell.west, cell.north]]]
                },
                // style: { color: 'white', weight: 2, opacity: 1, fillOpacity: 0, dashArray: '3', dashOffset: '0', fillColor: 'transparent', fill: false, stroke: true}
            };

            const layer = this.displayCell(cellData, true, 0);
            // layer.setStyle({ color: '#ffffff', weight: 2, opacity: 1, fillOpacity: 0, dashArray: '3', dashOffset: '0', fillColor: 'transparent', fill: false, stroke: true});
            // layer.setBorderColor({color: 'red', weight: 4});
            layer.bringToFront();
            // this.activeCell = layer;
            // this.map.panTo(layer.getBounds().getCenter());
        }

    }

    // highlightCell1(cellId) {
    //     // Reset previous active cell style
    //     if (this.activeCell) {
    //         this.activeCell.setStyle(this.defaultStyle);
    //     }
    //
    //     console.log("aaaa layer", this.cellsLayer)
    //
    //     // Find and highlight new active cell
    //     this.cellsLayer.eachLayer(layer => {
    //         console.log("aaaa", layer.feature.properties.id)
    //         if (layer.feature && layer.feature.properties.id === cellId) {
    //             layer.setStyle(this.activeStyle);
    //             layer.bringToFront();
    //             this.activeCell = layer;
    //             this.map.fitBounds(layer.getBounds());
    //         }
    //     });
    // }

    clearCells(redraw) {
        if (this.cellsLayer) {
            this.cellsLayer.clearLayers();
        }

        this.categoryLayers.forEach(layer => {
            if (layer)
                layer.clearLayers()
        })

        this.activeCell = null;
        this.allCells = []
    }

    // redrawCells() {
    //     this.categoryLayers.forEach(layer => {
    //         if (layer) {
    //             layer.clearLayers()
    //         }
    //     })
    //
    //     this.allCells.forEach(cell => {
    //         if (cell.id && (cell.isInFolio || cell.isSelected)) {
    //             // Create GeoJSON cell data
    //             const cellData = {
    //                 type: "Feature",
    //                 properties: {
    //                     id: cell.id,
    //                     name: cell.title || cell.name,
    //                     type: cell.type
    //                 },
    //                 geometry: {
    //                     type: "Polygon",
    //                     coordinates: [[[cell.west, cell.north],
    //                         [cell.east, cell.north],
    //                         [cell.east, cell.south],
    //                         [cell.west, cell.south],
    //                         [cell.west, cell.north]]]
    //                 }
    //             };
    //             let isInFolio = cell.isInFolio
    //             let category = cell.usageBand
    //
    //             // Display the cell
    //             this.displayCell(cellData, isInFolio, category);
    //         }
    //     });
    //
    // }

    drawAllCells(cells = null, redraw = false) {
        // alert("draw")
        // Clear existing cells first

        if (!redraw) {
            this.clearCells();
            this.allCells = cells
        } else {
            this.categoryLayers.forEach(layer => {
                if (layer) {
                    layer.clearLayers()
                }
            })
        }

        console.log("aaaa", this.allCells)

        // Process each cell in the collection
        this.allCells.forEach(cell => {
            if (cell.id && (cell.isInFolio || cell.isSelected)) {
                // Create GeoJSON cell data
                const cellData = {
                    type: "Feature",
                    properties: {
                        id: cell.id,
                        name: cell.title || cell.name,
                        type: cell.type
                    },
                    geometry: {
                        type: "Polygon",
                        coordinates: [[[cell.west, cell.north],
                            [cell.east, cell.north],
                            [cell.east, cell.south],
                            [cell.west, cell.south],
                            [cell.west, cell.north]]]
                    }
                };
                let isInFolio = cell.isInFolio
                let category = cell.usageBand

                // Display the cell
                this.displayCell(cellData, isInFolio, category);
            }
        });

        // Fit map to show all cells
        console.log("aaa map", this.map)
        // if (this.cellsLayer.getLayers().length > 0) {
        //     this.map.fitBounds(this.cellsLayer.getBounds());
        // }

        // drawCharts.call(this, 'chart')

    }

    showRoute() {
        let points = this.routesStore.getShipWaypoints;
        points.forEach((point, index) => {

            let tooltip = 'WP' + index
            let isPermanent = false
            if (index === 0) {
                tooltip = "<div style='color:#16ba16;'>Start</div>"
                isPermanent = true
            } else if (index === points.length - 1) {
                tooltip = "<div style='color:#eda405;'>Finish</div>"
                isPermanent = true
            }

            let newMarker = new L.circleMarker([point.lat, point.lon], {
                bubblingMouseEvents: false,
                autoPan: true,
                radius: 4,
                weight: 15,
                opacity: 0,
                fillOpacity: 0.8,
                color: 'blue',
            }).bindTooltip(tooltip, {permanent: true, direction: 'top', offset: L.point(0, -4)})
                .addTo(this.pmPane);

            // L.marker([point.lat, point.lon])
            //     .bindTooltip(`WP${index + 1}`, { permanent: true, direction: 'top' })
            //     .addTo(this.pmPane);
        });
        L.polyline(points, {color: 'blue', weight: 2}).addTo(this.pmPane);
        this.initTooltips()
    }
}