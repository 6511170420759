import L from "leaflet";
import {NavLeaf} from "../base/nav-leaf";
import {SELECTED_CELL_COLOR} from "./colors-control";
import {drawCharts, drawFolio} from "@/my-leaf/routes/components/route-graphs";

export class FolioLeaf extends NavLeaf {

    constructor() {
        super();
        this.cellsLayer = null;
        this.activeCell = null;
        this.allCells = null;
    }

    init(el) {
        super.init(el);
        this.cellsLayer = L.layerGroup().addTo(this.map);

        new L.Control.EasyButton({
            position: 'topleft',
            states: [{
                icon: 'bi bi-bar-chart-steps',
                title: 'Show graph',
                onClick: (btn) => {
                    drawCharts.call(this, 'folio')
                    // eventBus.$emit('show-graph-on-map')
                }
            }]
        }).addTo(this.map)
    }

    displayCell(cellData) {
        const cell = L.geoJSON(cellData, {
            style: {color: 'rgba(11,163,11,0.72)', fillOpacity: 0, fillColor: 'transparent', weight: 1},
            onEachFeature: (feature, layer) => {
                layer.on('click', () => {
                    this.highlightCell(feature.properties.id);
                });

                layer.bindTooltip(`${feature.properties.id}`, {
                    permanent: false,
                    direction: 'center'
                });
            }
        });

        cell.addTo(this.cellsLayer);
        return cell;
    }

    highlightCell(cellName) {
        if (this.activeCell) {
            this.activeCell.setStyle({ color: 'rgba(11,163,11,0.72)', fillOpacity: 0, fillColor: 'transparent', weight: 1 });
        }

        // this.cellsLayer.eachLayer(layer => {
        //     // layer.setStyle(this.activeStyle);
        //
        //     layer.setStyle({ color: 'red' });
        //
        //     layer.bringToFront();
        //     this.activeCell = layer;
        //     this.map.fitBounds(layer.getBounds());
        // });

        const cell = this.allCells.find(c => c.id === cellName);


        if (cell) {
            // Create GeoJSON cell data
            const cellData = {
                type: "Feature",
                properties: {
                    id: cell.id,
                    name: cell.title || cell.name,
                    type: cell.type
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [[[cell.west, cell.north],
                        [cell.east, cell.north],
                        [cell.east, cell.south],
                        [cell.west, cell.south],
                        [cell.west, cell.north]]]
                }
            };

            const layer = this.displayCell(cellData);
            layer.setStyle({
                color: 'white',
                weight: 2,
                opacity: 1,
                fillOpacity: 0,
                dashArray: '3',
                dashOffset: '0',
                fillColor: 'transparent',
                fill: false,
                stroke: true
            });
            layer.bringToFront();
            this.activeCell = layer;
            this.map.fitBounds(layer.getBounds());
        }

    }

    highlightCell1(cellId) {
        // Reset previous active cell style
        if (this.activeCell) {
            this.activeCell.setStyle(this.defaultStyle);
        }

        console.log("aaaa layer", this.cellsLayer)

        // Find and highlight new active cell
        this.cellsLayer.eachLayer(layer => {
            console.log("aaaa", layer.feature.properties.id)
            if (layer.feature && layer.feature.properties.id === cellId) {
                layer.setStyle(this.activeStyle);
                layer.bringToFront();
                this.activeCell = layer;
                this.map.fitBounds(layer.getBounds());
            }
        });
    }

    clearCells() {
        if (this.cellsLayer) {
            this.cellsLayer.clearLayers();
        }
        this.activeCell = null;
        this.allCells = []
    }

    drawAllCells(cells) {
        // Clear existing cells first
        this.clearCells();
        this.allCells = cells

        // Process each cell in the collection
        cells.forEach(cell => {
            if (cell.id) {
                // Create GeoJSON cell data
                const cellData = {
                    type: "Feature",
                    properties: {
                        id: cell.id,
                        name: cell.title || cell.name,
                        type: cell.type
                    },
                    geometry: {
                        type: "Polygon",
                        coordinates: [[[cell.west, cell.north],
                            [cell.east, cell.north],
                            [cell.east, cell.south],
                            [cell.west, cell.south],
                            [cell.west, cell.north]]]
                    }
                };

                // Display the cell
                this.displayCell(cellData);
            }
        });

        // Fit map to show all cells
        console.log("aaa map", this.map)
        if (this.cellsLayer.getLayers().length > 0) {
            this.map.fitBounds(this.cellsLayer.getBounds());
        }
    }

    showRoute() {
        let points = this.routesStore.getShipWaypoints;
        points.forEach((point, index) => {

            let tooltip = 'WP' + index
            let isPermanent = false
            if (index === 0) {
                tooltip = "<div style='color:#16ba16;'>Start</div>"
                isPermanent = true
            } else if (index === points.length - 1) {
                tooltip = "<div style='color:#eda405;'>Finish</div>"
                isPermanent = true
            }

            let newMarker = new L.circleMarker([point.lat, point.lon], {
                bubblingMouseEvents: false,
                autoPan: true,
                radius: 4,
                weight: 15,
                opacity: 0,
                fillOpacity: 0.8,
                color: 'blue',
            }).bindTooltip(tooltip, {permanent: true, direction: 'top', offset: L.point(0, -4)})
                .addTo(this.pmPane);

            // L.marker([point.lat, point.lon])
            //     .bindTooltip(`WP${index + 1}`, { permanent: true, direction: 'top' })
            //     .addTo(this.pmPane);
        });
        L.polyline(points, {color: 'blue', weight: 2}).addTo(this.pmPane);
        this.initTooltips()
    }
}