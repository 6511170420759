<template>
  <div>
    <b-container v-if="isAuthenticated">
      <!-- Original content goes here -->
      <b-row>
        <b-col>
          <b-form @submit="handleLogin" class="mt-2">
            <b-container fluid>
              <b-form-row>
                <b-col>
                  <b-button size="sm" v-b-toggle.collapse-registration name="show-registration" variant="outline-info">Registration</b-button>
                </b-col>
              </b-form-row>
            </b-container>
          </b-form>

          <b-alert v-if="is_registered" variant="success" show class="mt-3">
            Registration successful. Thank you for registering. Please check your email for a password.
          </b-alert>

          <b-alert v-if="is_wrong_registration" variant="warning" show class="mt-3">
            Registration is not successful. Probably the email is already registered.
          </b-alert>

          <b-collapse size="sm" id="collapse-registration" class="mt-2">
            <b-card>
              <b-form @submit="handleRegistration">
                <b-form-group
                    label="Email:"
                    label-for="email"
                    label-cols-sm="3"
                    label-align-sm="right"
                >
                  <b-form-input type="email" id="r_email" v-model="registration.username"></b-form-input>
                  <b-button class="mt-2" size="sm" type="submit" name="registration" variant="outline-info">Submit</b-button>
                </b-form-group>
              </b-form>
            </b-card>
          </b-collapse>
        </b-col>
      </b-row>
    </b-container>

    <!-- Password protection -->
    <b-container v-else>
      <b-row class="mt-5 justify-content-center">
        <b-col cols="12" md="6">
          <b-card title="Password Required" class="shadow">
            <b-form @submit.prevent="checkPassword">
              <b-form-group label="Enter Password:">
                <b-form-input
                    type="password"
                    v-model="enteredPassword"
                    required
                    placeholder="Enter password to access page"
                ></b-form-input>
              </b-form-group>
              <b-alert v-if="passwordError" variant="danger" show>
                Incorrect password. Please try again.
              </b-alert>
              <b-button type="submit" variant="primary">Submit</b-button>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import router from "@/router";
import {eventBus} from "@/main";
import {IS_GETENC, IS_NP} from "@/utils/build-config";
import store from "@/store";

export default {
  data() {
    return {
      prevRoute: null,
      registration: {
        username: "",
        password: ""
      },
      is_registered: false,
      is_wrong_registration: false,
      isAuthenticated: false,
      enteredPassword: "",
      passwordError: false,
    };
  },
  mounted() {
    this.test()
    eventBus.$off('is-registered1')
    eventBus.$on('is-registered1', (flag) => {
      if (flag) {
        this.is_registered = true;
        this.is_wrong_registration = false;
      } else {
        this.is_wrong_registration = true;
        this.is_registered = false;
      }
    })
  },
  methods: {
    checkPassword() {
      const correctPassword = "1234";
      if (this.enteredPassword === correctPassword) {
        this.isAuthenticated = true;
        this.passwordError = false;
      } else {
        this.passwordError = true;
      }
    },
    handleRegistration(event) {
      console.log(event.submitter.name)
      event.preventDefault();
      this.$root.$emit('bv::toggle::collapse', 'collapse-registration')
      if (event.submitter.name === "registration" && this.registration.username) {
        this.$store.dispatch("auth/register", {
          email: this.registration.username,
          password: '',
          userType: 'user',
          applicationTypeId: 0
        });
      } else {
        // TODO
      }
    },
  },
};
</script>

<style scoped>
</style>