import dayjs from "dayjs";
import {
    load2DImage,
    loadDatasetByLegs,
    loadMSSChartsByLegs,
    loadMSSChartsByRoute,
    loadMSSDataByRoute,
    loadTides
} from "@/helpers/api";

import Plotly from "plotly.js/dist/plotly"
import {useRoutesStore} from "@/stores/routes";
import {eventBus, routes_map} from "@/main";
import {useMomentaryStore} from "@/stores/momentary";
import {useGraphsStore} from "@/stores/graphs";
import {getDistanceFromLatLonInKm} from "@/my-leaf/routes/components/route-utils";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

let mouseState = 0;

function prepareWindows(id, width, height, withImage = true) {
    let graphsStore = useGraphsStore();

    const mymap = document.getElementById('app');
    mymap.style.position = 'relative';

    const popup = document.createElement('div');
    popup.style.position = 'absolute';
    popup.style.top = '10px';
    popup.style.left = '10px';
    popup.style.width = width + 'px';
    popup.style.height = height + 'px';
    popup.style.zIndex = '5000';
    popup.style.backgroundColor = "white"
    popup.style.padding = "15px";
    popup.style.resize = "horizontal";
    popup.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.2)";
    popup.style.borderRadius = "10px";
    popup.style.resize = "horizontal";
    popup.style.overflow = "auto";
    popup.style.minWidth = "400px";
    popup.style.minHeight = "220px";

    const closeButton = document.createElement('button');
    closeButton.innerHTML = '✖';
    closeButton.style.position = 'absolute';
    closeButton.style.top = '3px';
    closeButton.style.right = '3px';
    closeButton.style.border = 'none';
    closeButton.style.background = 'transparent';
    closeButton.style.fontSize = '12px';
    closeButton.style.cursor = 'pointer';

    closeButton.onclick = () => {
        mymap.removeChild(popup);
        graphsStore.setChartGraph(null, null)
        // TODO
        // this.ukcSafeDepth = Number(this.routesStore.getShipNoGo)
        // this.addNoGoLayer()
    };

    const moveButton = document.createElement('button');
    moveButton.id = 'moveButton';
    moveButton.innerHTML = '≡';
    moveButton.style.position = 'absolute';
    moveButton.style.top = '3px';
    moveButton.style.left = '3px';
    moveButton.style.border = 'none';
    moveButton.style.background = 'transparent';
    moveButton.style.fontSize = '12px';
    moveButton.style.cursor = 'pointer';
    // moveButton.style.cursor = 'grab';

    window.handleWheel = function(event) {
        let momentaryStore = useMomentaryStore();
        // Prevent the default scrolling behavior
        event.preventDefault();

        mouseState += event.deltaY > 0 ? 1 : -1;
        momentaryStore.set2DImageRequestoOffset(mouseState);

        console.log("Mouse wheel state:", mouseState);

        const imageElement = document.getElementById('2dimage');
        if (imageElement) {
            imageElement.src = "https://img.freepik.com/premium-vector/system-software-update-upgrade-concept-loading-process-screen-vector-illustration_175838-2182.jpg"
            load2DImage(null, 0, true).then((resp) => {
                let imgData = momentaryStore.getShipImage
                imageElement.src = resp
            })
        }

    }

    if (withImage)
        popup.innerHTML =
            `<div style="display: flex; align-items: center;">
            <img src="https://upload.wikimedia.org/wikipedia/commons/c/ca/1x1.png" id="2dimage" style="width: 200px; height: 200px; margin-top: 10px;" />
            <div id="${id}" style="flex: 1; height: 180px; margin-left: 10px;"></div>
        </div>`
    else
        popup.innerHTML =
            `<div id="${id}" style="flex: 1; height: 180px; margin-left: 10px;"></div>`

    popup.appendChild(moveButton);
    popup.appendChild(closeButton);
    mymap.appendChild(popup);

    const imageElement = document.getElementById('2dimage');
    if (imageElement) {
        imageElement.addEventListener('wheel', window.handleWheel);
    }

    let isDragging = false;
    let offsetX = 0;
    let offsetY = 0;

    moveButton.addEventListener('mousedown', function (e) {
        isDragging = true;
        // Получаем текущие координаты мыши относительно элемента
        offsetX = e.clientX - popup.offsetLeft;
        offsetY = e.clientY - popup.offsetTop;
        // Меняем курсор, чтобы показать, что элемент перетаскивается
        popup.style.cursor = 'grabbing';
        e.preventDefault(); // Предотвращаем выделение текста при перетаскивании
    });

    document.addEventListener('mousemove', function (e) {
        if (!isDragging) return;
        // Вычисляем новые координаты элемента
        let x = e.clientX - offsetX;
        let y = e.clientY - offsetY;

        // Ограничиваем перемещение элемента, чтобы он не выходил за пределы родительского контейнера
        let mymapWidth = mymap.offsetWidth;
        let mymapHeight = mymap.offsetHeight;
        let popupWidth = popup.offsetWidth;
        let popupHeight = popup.offsetHeight;

        x = Math.min(Math.max(0, x), mymapWidth - popupWidth);
        y = Math.min(Math.max(0, y), mymapHeight - popupHeight);

        // Устанавливаем новые координаты элемента
        popup.style.left = x + 'px';
        popup.style.top = y + 'px';
    });

    document.addEventListener('mouseup', function () {
        isDragging = false;
        // Возвращаем курсор в исходное состояние
        popup.style.cursor = 'grab';
    });

    popup.onclick = function (e) {
        e.stopPropagation();
    }

    popup.oncontextmenu = function (e) {
        e.stopPropagation();
    }

    popup.onmousedown = function (e) {
        e.stopPropagation();
    }

    popup.onwheel = function (e) {
        e.stopPropagation();
    }
}

export function drawDepths() {
    let routesStore = useRoutesStore();
    let momentaryStore = useMomentaryStore();

    prepareWindows.call(this, 'graphtide', 840, 240, true);

    let route = routesStore.getShipWaypoints;
    let routePoints = routesStore.getShipWaypointsInfo;
    let x = [];
    let y = [];
    let text = [];


    routePoints.forEach((item, index) => {

        console.log("aaa", index, item.depth)


        x.push(index); // Use waypoint number as x
        y.push(item.depth || 0);
        text.push(`WP ${index}: ${item.depth || 0}m`);
    });

    // Define traces for the plot
    const traces = [
        {
            x: x,
            y: y,
            mode: 'lines+markers',
            type: 'scatter',
            line: {
                color: 'rgb(202,180,167)',
                width: 2
            },
            marker: {
                size: 8,
                color: 'rgba(174,153,143,0.7)'
            },
            fill: 'tozeroy',
            fillcolor: 'rgba(136, 187, 221, 0.5)',
            hoverinfo: 'text',
            text: text
        }
    ];

    // Define layout
    const layout = {
        title: 'Depths along route',
        autosize: true,
        xaxis: {
            title: 'Waypoints',
            tickmode: 'array',
            tickvals: x,
            showgrid: true,
            gridwidth: 1,
            gridcolor: 'rgb(233, 233, 233)',
            showspikes: true,
            spikemode: 'across',
            spikesnap: 'cursor',
        },
        yaxis: {
            title: 'Depth (m)',
            autorange: 'reversed', // Reversed to show depths as positive downward
            fixedrange: true,
            zeroline: false,
            showgrid: false,
            gridwidth: 1,
            gridcolor: 'rgb(233, 233, 233)'
        },
        margin: {
            l: 20,
            r: 20,
            t: 10,
            b: 30
        },
        dragmode: 'pan',
        barmode: 'overlay',
        showlegend: false,
        hovermode: 'closest',
        spikedistance: -1,
        hoverdistance: -1,

        // paper_bgcolor: 'rgba(255,255,255,1)',
        // plot_bgcolor: 'rgba(237,206,163,0.3)'
    };

    // Create plot
    Plotly.newPlot('graphtide', traces, layout, {
        modeBarButtonsToRemove: ['toImage', 'lasso2d', 'select2d'],
        displayModeBar: false,
        displaylogo: false,
        scrollZoom: true,
        responsive: true
    });

    // Add click event handler
    const graphDiv = document.getElementById('graphtide');
    graphDiv.on('plotly_click', function(data) {
        const pointIndex = data.points[0].pointIndex;
        const coordinates1 = {
            lat: routesStore.getShipWaypoints[pointIndex].lat,
            lng: routesStore.getShipWaypoints[pointIndex].lon
        };
        const alt_coordinates = {
            lat: routesStore.getShipWaypoints[pointIndex].lat,
            lon: routesStore.getShipWaypoints[pointIndex].lon
        };

        let coordinates2;
        let directionDegrees = 0;

        console.log(`Clicked waypoint ${pointIndex}: ${coordinates1.lat.toFixed(6)}, ${coordinates1.lng.toFixed(6)}`);

        if (pointIndex + 1 < routesStore.getShipWaypoints.length) {
            coordinates2 = {
                lat: routesStore.getShipWaypoints[pointIndex + 1].lat,
                lng: routesStore.getShipWaypoints[pointIndex + 1].lon
            };

            // Calculate bearing between coordinates
            const lat1 = coordinates1.lat * Math.PI / 180;
            const lat2 = coordinates2.lat * Math.PI / 180;
            const lon1 = coordinates1.lng * Math.PI / 180;
            const lon2 = coordinates2.lng * Math.PI / 180;

            const y = Math.sin(lon2 - lon1) * Math.cos(lat2);
            const x = Math.cos(lat1) * Math.sin(lat2) -
                Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1);

            directionDegrees = Math.atan2(y, x) * 180 / Math.PI;
            directionDegrees = (directionDegrees + 360) % 360; // Convert to 0-360 range

            console.log(`Direction: ${directionDegrees.toFixed(2)}° from waypoint ${pointIndex} to ${pointIndex + 1}`);
        } else {
            coordinates2 = {
                lat: -1,
                lng: -1
            };
        }


        routes_map.panToCoordinates(alt_coordinates, 14);

        // Reset all markers to default color and size
        const newColors = new Array(x.length).fill('rgba(174,153,143,0.7)');
        const newSizes = new Array(x.length).fill(8);

        // Set the clicked point to red and larger
        newColors[pointIndex] = 'red';
        newSizes[pointIndex] = 10;

        // Apply the update to the plot
        Plotly.restyle('graphtide', {
            'marker.color': [newColors],
            'marker.size': [newSizes]
        });

        const imageElement = document.getElementById('2dimage');
        if (imageElement) {
            imageElement.src = "https://img.freepik.com/premium-vector/system-software-update-upgrade-concept-loading-process-screen-vector-illustration_175838-2182.jpg"
            load2DImage(coordinates1, directionDegrees).then((resp) => {
                let imgData = momentaryStore.getShipImage
                imageElement.src = resp
            })
        }
    });

    const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
            Plotly.relayout('graphtide', {
                width: entry.contentRect.width - 200,
                height: entry.contentRect.height
            });
        }
    });

    resizeObserver.observe(graphDiv.parentElement);

    // Clean up when component is destroyed
    eventBus.$on('beforeDestroy', () => {
        resizeObserver.disconnect();
    });

    if (route.length > 0) {
        // Wait for the plot to fully render
        setTimeout(() => {
            // Simulate a click on the first point by executing the same code that would run on click
            const pointIndex = 0;
            const coordinates1 = {
                lat: routesStore.getShipWaypoints[pointIndex].lat,
                lng: routesStore.getShipWaypoints[pointIndex].lon
            };
            const alt_coordinates = {
                lat: routesStore.getShipWaypoints[pointIndex].lat,
                lon: routesStore.getShipWaypoints[pointIndex].lon
            };

            let directionDegrees = 0;

            if (pointIndex + 1 < routesStore.getShipWaypoints.length) {
                const coordinates2 = {
                    lat: routesStore.getShipWaypoints[pointIndex + 1].lat,
                    lng: routesStore.getShipWaypoints[pointIndex + 1].lon
                };

                // Calculate bearing between coordinates
                const lat1 = coordinates1.lat * Math.PI / 180;
                const lat2 = coordinates2.lat * Math.PI / 180;
                const lon1 = coordinates1.lng * Math.PI / 180;
                const lon2 = coordinates2.lng * Math.PI / 180;

                const y = Math.sin(lon2 - lon1) * Math.cos(lat2);
                const x = Math.cos(lat1) * Math.sin(lat2) -
                    Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1);

                directionDegrees = Math.atan2(y, x) * 180 / Math.PI;
                directionDegrees = (directionDegrees + 360) % 360;
            }

            routes_map.panToCoordinates(alt_coordinates, 14);

            // Highlight the first point
            const newColors = new Array(x.length).fill('rgba(174,153,143,0.7)');
            const newSizes = new Array(x.length).fill(8);
            newColors[pointIndex] = 'red';
            newSizes[pointIndex] = 10;

            Plotly.restyle('graphtide', {
                'marker.color': [newColors],
                'marker.size': [newSizes]
            });

            // Load the image for the first waypoint
            const imageElement = document.getElementById('2dimage');
            if (imageElement) {
                imageElement.src = "https://img.freepik.com/premium-vector/system-software-update-upgrade-concept-loading-process-screen-vector-illustration_175838-2182.jpg";
                load2DImage(coordinates1, directionDegrees).then((resp) => {
                    imageElement.src = resp;
                });
            }
        }, 500);
    }
}

export function drawCharts() {
    let routesStore = useRoutesStore();
    let momentaryStore = useMomentaryStore();
    let graphsStore = useGraphsStore();

    let points = [];
    let distances = [0];  // First point starts at distance 0
    let waypoints = [1];  // First point is number 1
    let totalDistance = 0;
    let wpVals = []
    routesStore.getShipWaypoints.forEach((item, index) => {
        points.push({lat: item.lat, lon: item.lon});
        wpVals.push(index);

        if (index > 0) {
            // Calculate distance from previous point
            const prev = points[index - 1];
            const curr = points[index];
            // const distance = getDistanceFromLatLonInKm(prev.lat, prev.lon, curr.lat, curr.lon);
            const distance = getDistanceFromLatLonInKm(prev.lat, prev.lon, curr.lat, curr.lon);
            totalDistance += distance;
            distances.push(totalDistance);
            waypoints.push(index + 1);
        }
    });

    loadDatasetByLegs(points).then(() => {

        prepareWindows.call(this, 'graphchartsplotly', 750, 320, false);

        let cells = momentaryStore.route.datasetbylegs

        function convert(data) {
            let result = []

            data[0].legsInfo.forEach(leg => {
                let fromStartDistance = leg.distanceFromStartInKm
                // console.log("aaaa aaaa", leg, leg.distanceFromStartInKm)
                leg.mapIntersections.forEach(intersection => {
                    let id = intersection.id

                    console.log("aaaa", intersection.cuts[0])


                    let start = Number(fromStartDistance) + Number(intersection.cuts[0].distanceFromLegStartInKm)
                    let finish = start + Number(intersection.cuts[0].lengthInKm)

                    result.push({id: id, start: start, finish: finish})
                })
            })

            return result
        }

        let x = convert(cells);

        let traces = [];
        let shapes = [];
        const categories = [1, 2, 3, 4, 5, 6];

// Group items by id and calculate overlaps
        let idGroups = {};
        x.forEach(item => {
            if (!idGroups[item.id]) {
                idGroups[item.id] = [];
            }
            idGroups[item.id].push(item);
        });


// Sort groups by total span
        const sortedGroups = Object.entries(idGroups).sort((a, b) => {
            const aSpan = a[1].reduce((sum, item) => sum + (item.finish - item.start), 0);
            const bSpan = b[1].reduce((sum, item) => sum + (item.finish - item.start), 0);
            return bSpan - aSpan;
        });

// Calculate y-positions considering overlaps
        let yPositions = {};
        sortedGroups.forEach(([id, ranges], groupIndex) => {
            // Base y-position based on first character of id
            const baseY = Number(7 - id.charAt(2));

            // Check for overlaps with previous groups
            let offset = 0;
            for (let i = 0; i < groupIndex; i++) {
                const prevRanges = sortedGroups[i][1];
                const hasOverlap = ranges.some(range =>
                    prevRanges.some(prevRange =>
                        range.finish > prevRange.start && range.start < prevRange.finish
                    )
                );
                if (hasOverlap) {
                    offset += 0.1;
                }
            }

            yPositions[id] = baseY + offset;
        });

// Create traces
        x.forEach(item => {
            console.log("graph", item, name)
            let colorTypeInFolio = momentaryStore.getCellsByRoute.some(cell => cell.id === item.id && cell.isInFolio);
            let colorTypeIsSelected = momentaryStore.getCellsByRoute.some(cell => cell.id === item.id && cell.isSelected);
            let color = colorTypeInFolio ? 'rgba(83,231,115,0.5)' : 'rgba(74,148,255,0.5)';
            if (!colorTypeInFolio && !colorTypeIsSelected)
                color = 'rgba(244,156,74,0.5)';

            traces.push({
                type: 'line',
                x: [item.start, item.finish],
                y: [yPositions[item.id], yPositions[item.id]],
                mode: 'lines',
                line: {
                    width: 14,
                    color: color,
                    simplify: false
                },
                name: item.id,
                hoverinfo: 'text',
                text: `${item.id} [${Math.ceil(item.start)}-${Math.floor(item.finish)}]`,
                hoverlabel: {
                    bgcolor: color
                }
            });

            shapes.push({
                type: 'rect',
                x0: item.start,
                x1: item.finish,
                y0: yPositions[item.id] - 0.04,
                y1: yPositions[item.id] + 0.04,
                line: {
                    color: 'rgb(154,154,154)',
                    width: 1
                },
                fillcolor: 'transparent'
            });
        });

        const layout = {
            title: 'Charts on route',
            // width: 700,
            // height: 300,
            autosize: true,
            shapes: shapes,
            xaxis: {
                title: 'Distance (km)',
                zeroline: false,
                showgrid: true,
                gridwidth: 1,
                gridcolor: 'rgb(233, 233, 233)',
                showspikes: true,
                spikemode: 'across',
                spikesnap: 'cursor',
                tickvals: distances,
                ticktext: waypoints,
                tickangle: 0, // Keep labels horizontal
                // automargin: true,
                // tickmode: 'array',
                // autorange: true
                // showticklabels: false
            },
            yaxis: {
                title: 'Scale',
                showgrid: false,
                tickvals: categories,
                ticktext: ['Berthing', 'Harbour', 'Approach', 'Coastal', 'General', 'Overview'],
                fixedrange: true,
                range: [0, 6.5]
            },
            margin: {
                l: 80,
                r: 20,
                t: 20,
                b: 40
            },
            dragmode: 'pan',
            barmode: 'overlay',
            showlegend: false,
            hovermode: 'closest',
            spikedistance: -1,
            hoverdistance: -1
        };

        graphsStore.setChartGraph(traces, layout)
        const graphDiv = document.getElementById('graphchartsplotly');

        Plotly.newPlot('graphchartsplotly', traces, layout, {
            modeBarButtonsToRemove: ['toImage', 'lasso2d', 'zoom2d', 'select2d', 'zoomIn2d', 'zoomOut2d', 'autoScale2d'],
            responsive: true,
            displayModeBar: false,
            displaylogo: false,
            scrollZoom: true
        });

        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                Plotly.relayout('graphchartsplotly', {
                    width: entry.contentRect.width,
                    height: entry.contentRect.height
                });
            }
        });

        resizeObserver.observe(graphDiv.parentElement);

        eventBus.$on('beforeDestroy', () => {
            resizeObserver.disconnect();
        });


        graphDiv.on('plotly_click', function (data) {
            eventBus.$emit('highlight-onroute-cell', data.points[0].data.name)
        });

    });
}

export function updatePlotlyData(id, flag, isClicked = false) {
    let graphsStore = useGraphsStore();

    if (graphsStore.getChartGraph && graphsStore.getChartGraph.traces) {

        if (!isClicked) {
            graphsStore.getChartGraph.traces.forEach(item => {
                if (item.name === id) {
                    item.line.color = flag ? 'rgba(74,148,255,0.5)' : 'rgba(244, 156, 74, 0.5)';
                }
            })
        } else {
            graphsStore.getChartGraph.traces.forEach(item => {
                if (item.name === id) {
                    if (item.line.color === 'rgba(74,148,255,0.5)')
                        item.line.color = 'rgba(74,148,255,0.10)';

                    if (item.line.color === 'rgba(244,156,74,0.5)')
                        item.line.color = 'rgba(244,156,74,0.10)';

                    if (item.line.color === 'rgba(83,231,115,0.5)')
                        item.line.color = 'rgba(83,231,115,0.10)';
                } else {
                    if (item.line.color === 'rgba(74,148,255,0.10)')
                        item.line.color = 'rgba(74,148,255,0.5)';

                    if (item.line.color === 'rgba(244,156,74,0.10)')
                        item.line.color = 'rgba(244,156,74,0.5)';

                    if (item.line.color === 'rgba(83,231,115,0.10)')
                        item.line.color = 'rgba(83,231,115,0.5)';

                    // item.line.hoverinfo = 'text';
                    // item.line.text = `Line ID: ${item.name}`;
                    // item.line.dash = 1

                    console.log("color", item.line)
                    console.log("color", item.line.color)
                }
            })
        }

        Plotly.react('graphchartsplotly', graphsStore.getChartGraph.traces, graphsStore.getChartGraph.layout, {
            modeBarButtonsToRemove: ['toImage', 'lasso2d', 'zoom2d', 'select2d', 'zoomIn2d', 'zoomOut2d', 'autoScale2d'],
            displayModeBar: true,
            displaylogo: false,
            scrollZoom: true
        });
    }
}

export function drawTide(coordinates) {
    dayjs.extend(utc);
    dayjs.extend(timezone);

    let date = dayjs().format("YYYY-MM-DD");

    loadTides(date, coordinates.lat, coordinates.lng).then(resp => {
        let tides = resp.heights;
        let tideTimezone = resp.timezone;

        let hours = [];
        let values = [];

        tides.forEach(tide => {
            const date = new Date(tide.date);
            const hour = date.getUTCHours();
            hours.push(hour);
            values.push(tide.height);
        });

        prepareWindows.call(this, 'tidesPlotly', 600, 200, false);

        const zeroPad = (num, places) => String(num).padStart(places, '0');

        // Define the trace for the bar chart
        const trace = {
            x: hours,
            y: values,
            type: 'bar',
            name: 'tide [m]',
            marker: {
                color: 'rgba(74,148,255,0.7)',
                line: {
                    color: 'rgb(74,148,255)',
                    width: 1
                }
            },
            hoverinfo: 'y+text',
            // text: hours.map(hour => `${zeroPad(hour, 2)}:00 UTC`)
        };

        // Define layout
        const layout = {
            title: 'Tide Heights',
            autosize: true,
            xaxis: {
                title: {
                    text: 'Time, UTC',
                    standoff: 10
                },
                tickvals: hours,
                ticktext: hours.map(hour => `${hour}`),
                showgrid: true,
                gridwidth: 1,
                gridcolor: 'rgb(233, 233, 233)'
            },
            yaxis: {
                title: 'Height (m)',
                zeroline: true,
                showgrid: true,
                gridwidth: 1,
                gridcolor: 'rgb(233, 233, 233)'
            },
            margin: {
                l: 50,
                r: 20,
                t: 40,
                b: 50
            },
            bargap: 0.1,
            hoverlabel: {
                bgcolor: 'white',
                font: { size: 12 }
            }
        };

        // Create the plot
        Plotly.newPlot('tidesPlotly', [trace], layout, {
            modeBarButtonsToRemove: ['toImage', 'lasso2d', 'select2d'],
            displayModeBar: false,
            displaylogo: false,
            responsive: true
        });

        // Add click event handler
        const graphDiv = document.getElementById('tidesPlotly');
        graphDiv.on('plotly_click', (data) => {
            const pointIndex = data.points[0].pointNumber;
            this.ukcSafeDepth = Number(Number(this.routesStore.getShipDraught) -
                Number(values[pointIndex])).toFixed(1);
            this.addNoGoLayer();
        });

        // Create a resize observer
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                Plotly.relayout('tidesPlotly', {
                    width: entry.contentRect.width,
                    height: entry.contentRect.height
                });
            }
        });

        resizeObserver.observe(graphDiv.parentElement);

        // Clean up when component is destroyed
        eventBus.$on('beforeDestroy', () => {
            resizeObserver.disconnect();
        });
    });
}
