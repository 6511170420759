import {defineStore} from 'pinia'

export const useGraphsStore = defineStore('graphs', {
    state: () => ({
        chartGraph: {
            traces: null,
            layout: null
        },
    }),
    getters: {
        getChartGraph: (state) => state.chartGraph,
    },
    actions: {
        clear() {
            this.chartGraph.traces = null
            this.chartGraph.layout = null
        },
        setChartGraph(traces, layout) {
            this.chartGraph.traces = traces
            this.chartGraph.layout = layout
        },
        persist: false
    }
})
